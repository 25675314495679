import { defineStore } from "pinia";
import { ref } from "vue";
import api from "@/api";

export const useProfileStore = defineStore("profile", {
  state: () => ({
    userData: {},
    addresses: []
  }),

  actions: {
    async fetchProfile() {
      try {
        const response = await api.get("/api/clients/me");
        this.userData = response.data || {};
      } catch (err) {
        console.error("Ошибка загрузки профиля:", err);
      }
    },

    async fetchAddresses() {
      try {
        const response = await api.get("/api/client/address");
        this.addresses = Array.isArray(response.data) ? response.data : [];
      } catch (err) {
        console.error("Ошибка загрузки адресов:", err);
      }
    }
  }
});
