<template>
  <div class="order-details-page">
    <!-- Хлебные крошки -->
    <div class="breadcrumbs">
      <router-link to="/" class="breadcrumb-link">Главная</router-link>
      <span class="breadcrumb-separator">›</span>
      <router-link to="/orders" class="breadcrumb-link">Мои заказы</router-link>
      <span class="breadcrumb-separator">›</span>
      <span class="breadcrumb-current">Заказ №{{ order?.numberorder }}</span>
    </div>

    <div class="order-header">
      <h1>Заказ №{{ order?.numberorder }}</h1>
    </div>

    <div v-if="isLoading" class="loader">
      <div class="spinner"></div>
      <span>Загрузка данных...</span>
    </div>

    <div v-if="error" class="error-message">
      <i class="error-icon">❌</i>
      <span>Ошибка загрузки заказа. Попробуйте позже.</span>
    </div>

    <!-- Основной контейнер с товарами и информацией -->
    <div v-if="order && !isLoading" class="order-container">
      <!-- Левая колонка для списка товаров (на десктопе) -->
      <div class="products-column">
        <!-- Блок с товарами (сворачиваемый на мобильных устройствах) -->
        <div class="collapse-section">
          <div 
            class="collapse-header" 
            @click="toggleProductList"
            :class="{'mobile-toggle': isMobile}"
          >
            <div class="collapse-title">
              <span>{{ order.orderItemList?.length || 0 }} товаров</span>
            </div>
            <div class="collapse-value">
              <span>{{ formatPrice(order.orderedTotalAmount) }}</span>
            </div>
            <div v-if="isMobile" class="collapse-icon">
              <span>{{ showProductList ? '▲' : '▼' }}</span>
            </div>
          </div>
          <div v-if="showProductList || !isMobile" class="collapse-content product-list">
            <div 
              v-for="item in order.orderItemList" 
              :key="item.productId" 
              class="product-item"
              @click="goToProductPage(item.productId)"
            >
              <div class="product-details">
                <span class="product-title">{{ item.productName }}</span>
                <div class="product-pricing">
                  <div class="price-per-unit">
                    <span class="regular-price">{{ formatPrice(item.orderedTotalPrice / item.quantity) }}/шт</span>
                  </div>
                  
                  <!-- Количество и итоговая цена в одной строке -->
                  <div class="quantity-total">
                    <span>
                      Заказано: {{ item.quantity }} шт
                      <span v-if="item.sentQuantity !== null" 
                            :class="getSentQuantityClass(item.quantity, item.sentQuantity)">
                        | Отправлено: {{ item.sentQuantity }} шт
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Правая колонка для информации о заказе -->
      <div class="order-info-column">
        <!-- Статус заказа -->
        <div class="collapse-section">
          <div class="collapse-header status-header">
            <div class="collapse-title">
              <span>Статус заказа:</span>
            </div>
            <div class="collapse-value">
              <span class="status-text" :class="getStatusClass(order.status?.name || order.status?.code)">
                {{ order.status?.name || "Неизвестно" }}
              </span>
            </div>
          </div>
        </div>

        <!-- Прогресс заказа -->
        <div class="order-progress">
          <!-- Оплачено -->
          <div class="progress-step">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              :fill="['ASSEMBLY'].includes(order.stage?.id) ? '#ff3b30' : '#A9A9A9'"
            >
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"/>
            </svg>
            <span>Оплата</span>
          </div>

          <!-- Собрано -->
          <div class="progress-step">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              :fill="['CONFIRM_PAYMENT'].includes(order.stage?.id) ? '#ff3b30' : '#A9A9A9'"
            >
              <path d="M21 16V8l-9-6-9 6v8l9 6 9-6zm-9 5l-7-4V9.3L12 5l7 4.3V17l-7 4zm1-11h-2v5h2v-5zm0 6h-2v2h2v-2z"/>
            </svg>
            <span>Сборка</span>
          </div>

          <!-- Доставка -->
          <div class="progress-step">
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              :fill="['SENDING', 'SENT'].includes(order.stage?.id) ? '#ff3b30' : '#A9A9A9'"
            >
              <path d="M20 8h-3V4H3v16h2c0 1.66 1.34 3 3 3s3-1.34 3-3h4c0 1.66 1.34 3 3 3s3-1.34 3-3h2v-6l-4-6zM8 20c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm10 0c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
            </svg>
            <span>Доставка</span>
          </div>
        </div>

        <!-- 1. Информация о заказе -->
        <div class="collapse-section">
          <div 
            class="collapse-header" 
            @click="isMobile ? toggleOrderInfo() : null"
            :class="{'mobile-toggle': isMobile}"
          >
            <div class="collapse-title">
              <span class="section-number">1.</span>
              <span>Информация о заказе</span>
            </div>
            <div v-if="isMobile" class="collapse-icon">
              <span>{{ showOrderInfo ? '▲' : '▼' }}</span>
            </div>
          </div>
          <div v-if="showOrderInfo || !isMobile" class="collapse-content">
            <div class="order-info">
              <div class="info-row">
                <span class="info-label">Дата заявки:</span>
                <span class="info-value">{{ formatDate(order.createdInfo.createdOn) }}</span>
              </div>
              <div class="info-row">
                <span class="info-label">Тип доставки:</span>
                <span class="info-value">{{ order.clientDeliveryType?.name || 'Не указан' }}</span>
              </div>
              <div v-if="order.clientAddress" class="info-row">
                <span class="info-label">Адрес:</span>
                <span class="info-value">
                  {{ getFullAddress(order.clientAddress) }}
                </span>
              </div>
              <div class="info-row">
                <span class="info-label">Телефон:</span>
                <span class="info-value">{{ order.client?.phoneNumber || 'Не указан' }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 2. Сумма заказа -->
        <div class="collapse-section">
          <div 
            class="collapse-header" 
            @click="isMobile ? toggleOrderAmount() : null"
            :class="{'mobile-toggle': isMobile}"
          >
            <div class="collapse-title">
              <span class="section-number">2.</span>
              <span>Сумма заказа</span>
            </div>
            <div class="collapse-value">
              <span>{{ formatPrice(order.finalTotalAmount) }}</span>
            </div>
            <div v-if="isMobile" class="collapse-icon">
              <span>{{ showOrderAmount ? '▲' : '▼' }}</span>
            </div>
          </div>
          <div v-if="showOrderAmount || !isMobile" class="collapse-content">
            <div class="order-amount-details">
              <div class="amount-row">
                <span class="amount-label">Сумма заявки:</span>
                <span class="amount-value">{{ formatPrice(order.orderedTotalAmount) }}</span>
              </div>
              <div class="amount-row">
                <span class="amount-label">Сумма до скидки:</span>
                <span class="amount-value">{{ formatPrice(order.beforeDiscountTotalAmount) }}</span>
              </div>
              <div class="amount-row">
                <span class="amount-label">Скидка:</span>
                <span class="amount-value">{{ order.discountPercentage || 0 }}%</span>
              </div>
              <div class="amount-row">
                <span class="amount-label">Сумма к возврату:</span>
                <span class="amount-value">{{ formatPrice(order.returnAmount) }}</span>
              </div>
              <div class="amount-row total-row">
                <span class="amount-label">Итого к оплате:</span>
                <span class="amount-value total-amount">{{ formatPrice(order.finalTotalAmount) }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Кнопка "Повторить заказ" -->
        <button class="repeat-order-btn" @click="repeatOrder">
          Повторить заказ
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import api from "@/api";
import { useCartStore } from "@/store/useCartStore";
import NoImage from "@/assets/icon.png";

const route = useRoute();
const router = useRouter();
const order = ref(null);
const isLoading = ref(true);
const error = ref(false);
const cartStore = useCartStore();
const noImage = NoImage;

// Состояние для мобильной версии
const isMobile = ref(false);
const showProductList = ref(true);
const showOrderInfo = ref(true);
const showOrderAmount = ref(true);

// Проверка устройства
const checkDeviceType = () => {
  isMobile.value = window.innerWidth < 768;
};

// Функции для переключения видимости секций (только для мобильной версии)
const toggleProductList = () => {
  if (isMobile.value) {
    showProductList.value = !showProductList.value;
  }
};

const toggleOrderInfo = () => {
  showOrderInfo.value = !showOrderInfo.value;
};

const toggleOrderAmount = () => {
  showOrderAmount.value = !showOrderAmount.value;
};

// Получение полного адреса
const getFullAddress = (clientAddress) => {
  if (!clientAddress || !clientAddress.address) return 'Адрес не указан';
  
  const addressData = clientAddress.address;
  const address = addressData.address || '';
  const city = addressData.city?.name || '';
  const country = addressData.country?.name || '';
  
  return [address, city, country].filter(Boolean).join(', ');
};

// Форматирование даты
const formatDate = (dateString) => {
  if (!dateString) return 'Не указана';
  
  const date = new Date(dateString);
  return date.toLocaleDateString('ru-RU', { 
    day: 'numeric', 
    month: 'long', 
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

// Форматирование цены
const formatPrice = (amount) => {
  if (!amount && amount !== 0) return "0 ₸";
  return `${amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₸`;
};

// Определение цвета статуса
const getStatusClass = (status) => {
  // Проверяем как отображаемые имена, так и внутренние коды статусов
  if (status === 'COMPLETED' || status === 'Завершенный') {
    return 'completed';
  } else if (status === 'DECLINED' || status === 'Отказано') {
    return 'declined';
  } else if (status === 'IN_WORK' || status === 'В работе') {
    return 'pending';
  } else {
    // Статус по умолчанию, если не определено иное
    return 'pending';
  }
};

// Определение класса для количества отправленных товаров
const getSentQuantityClass = (ordered, sent) => {
  if (sent < ordered) return "sent-warning";  // Красный, если отправлено меньше чем заказано
  if (sent > ordered) return "sent-success";  // Зеленый, если отправлено больше чем заказано
  return "sent-equal"; // Нейтральный цвет, если отправлено столько же
};

// Переход на страницу товара
const goToProductPage = (id) => {
  //console.log("Переход на страницу товара с ID:", id);
  router.push(`/product/${id}`);
};

// Загрузка заказа по ID
const fetchOrder = async () => {
  try {
    const response = await api.get(`/api/client/orders/${route.params.id}`);
    order.value = response.data;
    //console.log("✅ Данные заказа загружены:", order.value);
  } catch (err) {
   // console.error("❌ Ошибка загрузки заказа:", err);
    error.value = true;
  } finally {
    isLoading.value = false;
  }
};

// Функция "Повторить заказ"
const repeatOrder = () => {
  if (!order.value || !order.value.orderItemList) return;

  order.value.orderItemList.forEach((item) => {
    if (!item.productId) {
     // console.error("❌ Ошибка: отсутствует productId у товара", item);
      return;
    }

    const product = {
      id: item.productId,
      title: item.productName || "Неизвестный товар",
      price: item.orderedTotalPrice || 0,
      image: item.image || noImage,
      rating: 5,
      stock: item.stockQuantity || 0,
      quantity: item.quantity || 1,
    };

    //console.log("🛒 Добавляем в корзину товар:", product);
    cartStore.setProductToCart(product);
  });

  alert("✅ Товары добавлены в корзину!");
};

onMounted(() => {
  checkDeviceType();
  window.addEventListener('resize', checkDeviceType);
  fetchOrder();
});
</script>

<style scoped>
.order-details-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
}

/* Стили для хлебных крошек */
.breadcrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  background-color: transparent;
}

.breadcrumb-link {
  color: #666;
  text-decoration: none;
}

.breadcrumb-link:hover {
  color: #F15E24;
}

.breadcrumb-separator {
  margin: 0 8px;
  color: #666;
}

.breadcrumb-current {
  color: #333;
  font-weight: 500;
}

.order-header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.order-header h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: #333;
  text-align: left;
  position: relative;
}

.order-header h1:after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 50px;
  height: 3px;
  background-color: #F15E24;
  border-radius: 3px;
}

/* Основной контейнер с адаптивной версткой */
.order-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Стили для сворачиваемых секций */
.collapse-section {
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
}

.collapse-header {
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: #f5f5f5;
  font-weight: 500;
}

.mobile-toggle {
  cursor: pointer;
}

.collapse-title {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
}

.collapse-value {
  margin-right: 15px;
  font-weight: 500;
  color: #333;
  text-align: right;
}

.collapse-icon {
  width: 24px;
  text-align: center;
  color: #999;
  font-size: 12px;
}

.collapse-content {
  padding: 16px;
  border-top: 1px solid #eaeaea;
}

.section-number {
  color: #F15E24;
  font-weight: 600;
  margin-right: 5px;
}

/* Стили для статуса заказа */
.status-header {
  background-color: #f9f9f9;
}

.status-text {
  font-weight: 600;
  font-size: 16px;
}

.completed {
  color: #007b00;
}

.declined {
  color: red;
}

.pending {
  color: #ff9800;
}

/* Стили для количества отправленных товаров */
.sent-warning {
  color: red;
  font-weight: bold;
}

.sent-success {
  color: green;
  font-weight: bold;
}

.sent-equal {
  color: #333;
  font-weight: normal;
}

/* Стили для прогресса заказа */
.order-progress {
  display: flex;
  justify-content: space-between;
  background: #fff;
  padding: 12px;
  border-radius: 12px;
  margin-bottom: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.progress-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #666;
}

.progress-step svg {
  width: 28px;
  height: 28px;
  margin-bottom: 5px;
}

/* Стили для списка товаров */
.product-list {
  max-height: 500px;
  overflow-y: auto;
}

.product-item {
  display: flex;
  align-items: flex-start;
  padding: 15px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.product-item:hover {
  background-color: #f5f5f5;
}

.product-item:last-child {
  border-bottom: none;
}

.product-details {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.product-title {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 14px;
  color: #333;
  line-height: 1.3;
}

.product-pricing {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.price-per-unit {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
}

.regular-price {
  font-weight: 600;
  font-size: 16px;
  color: #333;
}

.quantity-total {
  margin-top: 5px;
  font-size: 14px;
  color: #555;
  background-color: #f9f9f9;
  padding: 4px 10px;
  border-radius: 4px;
}

/* Стили для информации о заказе */
.order-info, .order-amount-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.info-row, .amount-row {
  display: flex;
  align-items: center;
}

.info-label, .amount-label {
  width: 150px;
  font-weight: 500;
  color: #666;
}

.info-value, .amount-value {
  font-weight: 500;
  color: #333;
}

.total-row {
  font-size: 18px;
  margin-top: 5px;
  padding-top: 10px;
  border-top: 1px dashed #eee;
}

.total-amount {
  color: #F15E24;
  font-size: 20px;
  font-weight: 600;
}

/* Стили для кнопки "Повторить заказ" */
.repeat-order-btn {
  width: 100%;
  padding: 16px;
  background-color: #F15E24;
  color: white;
  border: none;
  border-radius: 10px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(241, 94, 36, 0.2);
  transition: all 0.2s;
}

.repeat-order-btn:hover {
  background-color: #E64A19;
}

/* Стили для лоадера */
.loader {
  padding: 25px;
  text-align: center;
  color: #666;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.spinner {
  border: 3px solid #f3f3f3;
  border-top: 3px solid #F15E24;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  padding: 15px;
  background-color: #FFEBEE;
  color: #D32F2F;
  border-radius: 8px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.error-icon {
  font-style: normal;
}

/* Адаптивные стили - десктопный вид */
@media (min-width: 768px) {
  .order-container {
    flex-direction: row;
    align-items: flex-start;
  }
  
  .products-column {
    width: 40%;
    margin-right: 20px;
  }
  
  .order-info-column {
    width: 60%;
  }
}

/* Адаптивные стили - мобильный вид */
@media (max-width: 767px) {
  .order-details-page {
    padding: 10px;
  }

  .breadcrumbs {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  
  .info-row, .amount-row {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 8px 0;
  }
  
  .info-label, .amount-label {
    width: 150px;
    text-align: left;
  }

  .info-value, .amount-value {
    flex: 1;
    text-align: right;
  }

  /* Улучшение отображения статуса заказа */
  .status-header {
    padding: 12px;
  }

  /* Отступы для заголовка */
  .order-header h1 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  /* Улучшаем внешний вид блоков "Информация о заказе" и "Сумма заказа" */
  .total-row {
    margin-top: 10px;
    padding-top: 12px;
  }
  
  .total-amount {
    color: #F15E24;
    font-weight: bold;
  }
}
</style>