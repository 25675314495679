<template>
  <div>
    <FailedHttpRequest
      :errorCode="error.errorCode"
      :errorMessage="error.message"
      :timeout="error.timeout"
      :serverIsDown="serverStatus.isDown"
      :serverErrorMessage="serverStatus.message"
      v-if="error?.isError && !isLoading"
    />
    <div class="product-page" v-if="!error?.isError || isLoading">
      <Loader v-if="isLoading" />
      <BaseCard v-if="product" class="product-page__card">
        <!-- Галерея изображений -->
        <div class="product-page__gallery">
          <ImageGallery 
            :images="productImages" 
            v-if="productImages.length > 0"
          />
        </div>

        <!-- Контент продукта -->
        <div class="product-page__card-content">
          <BaseHeading variant="h1" class="product-page__card-title">
            {{ product.name }}
          </BaseHeading>
          <BaseDivider />

          <!-- Артикул и категория -->
          <div class="product-page__info-rows">
            <div class="info-row">
              <strong>Артикул:</strong>
              <span>{{ product.vendorCode }}</span>
            </div>
            <div class="info-row">
              <strong>Категория:</strong>
              <span>
                <span v-for="(category, index) in product.categorySet" :key="index">
                  {{ category.name }}<span v-if="index < product.categorySet.length - 1">, </span>
                </span>
              </span>
            </div>
          </div>

          <!-- Остаток и резерв (только для оптовых клиентов) -->
          <div v-if="isWholesaleClient" class="product-page__info-rows">
            <div class="info-row">
              <strong>Остаток:</strong>
              <span>{{ product.remainedCount }} шт</span>
            </div>
            <div class="info-row">
              <strong style="color: red">Резерв:</strong>
              <span>{{ product.reservedCount }} шт</span>
            </div>
          </div>

          <!-- Цена и кнопки действий -->
          <div class="product-page__actions">
            <!-- Цены для оптовых клиентов -->
            <div v-if="isWholesaleClient" class="price-wrapper">
              <template v-if="product.sale_trade_price">
                <BaseHeading variant="h2" class="wholesale-price">₸ {{ formatPrice(product.sale_trade_price) }}</BaseHeading>
                <span class="old-price">₸ {{ formatPrice(product.tradePrice) }}</span>
                <span class="discount-badge">
                  -{{ Math.round(((product.tradePrice - product.sale_trade_price) / product.tradePrice) * 100) }}%
                </span>
              </template>
              <BaseHeading v-else variant="h2" class="wholesale-price">₸ {{ formatPrice(product.tradePrice) }}</BaseHeading>
            </div>
            
            <!-- Цены для розничных клиентов -->
            <div v-else class="price-wrapper">
              <template v-if="product.sale_retail_price">
                <BaseHeading variant="h2">₸ {{ formatPrice(product.sale_retail_price) }}</BaseHeading>
                <span class="old-price">₸ {{ formatPrice(product.retailPrice) }}</span>
                <span class="discount-badge">
                  -{{ Math.round(((product.retailPrice - product.sale_retail_price) / product.retailPrice) * 100) }}%
                </span>
              </template>
              <BaseHeading v-else variant="h2">₸ {{ formatPrice(product.retailPrice) }}</BaseHeading>
            </div>
            
            <!-- Кнопки действий -->
            <div class="action-buttons">
              <LikeIcon class="like-icon" :class="{ 'in-like': isLiked }" @click="toggleLike" />
              <CartIcon class="cart-icon" :class="{ 'in-cart': isInCart }" @click="addToCart" />
            </div>
          </div>

          <!-- Описание -->
          <div class="product-page__description">
            <BaseHeading variant="h3">Описание</BaseHeading>
            <p v-if="product.description">{{ product.description }}</p>
            <p v-else>Описание недоступно</p>
          </div>
        </div>
      </BaseCard>
      <div v-else class="loading-message">
        Товар не найден или загружается...
      </div>
    </div>
    <button class="floating-back-btn" @click="goBack">
      <span class="back-icon">⏪︎</span>
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import BaseHeading from "@/components/UI/BaseHeading.vue";
import CartIcon from "@/components/icons/CartIcon.vue";
import LikeIcon from "@/components/icons/LikeIcon.vue";
import BaseCard from "@/components/UI/BaseCard.vue";
import BaseDivider from "@/components/UI/BaseDivider.vue";
import Loader from "@/components/UI/Loader.vue";
import FailedHttpRequest from "@/components/FailedHttpRequest.vue";
import { useCartStore } from "@/store/useCartStore";
import { useLikeStore } from "@/store/useLikeStore";
import ImageGallery from "@/components/ImageGallery.vue";
import api from "@/api";

const route = useRoute();
const router = useRouter();
const cartStore = useCartStore();
const likeStore = useLikeStore();
const product = ref(null);
const isLoading = ref(true);
const error = ref(null);
const serverStatus = ref({ isDown: false, message: '' });

// Данные пользователя
const userData = ref(null);
const userLoading = ref(true);

// Проверка, является ли клиент оптовым
const isWholesaleClient = computed(() => {
  return userData.value && userData.value.cod_type_client === 'trade';
});

// Проверка, добавлен ли продукт в корзину
const isInCart = computed(() =>
  product.value ? cartStore.cart.some(item => item.id === product.value.id) : false
);

// Проверка, добавлен ли продукт в список "лайков"
const isLiked = computed(() =>
  product.value ? likeStore.likes.some(item => item.id === product.value.id) : false
);

// Получение актуальной цены товара в зависимости от типа клиента
const getProductPrice = computed(() => {
  if (!product.value) return 0;
  
  if (isWholesaleClient.value) {
    // Для оптового клиента
    return product.value.sale_trade_price || product.value.tradePrice || 0;
  } else {
    // Для розничного клиента
    return product.value.sale_retail_price || product.value.retailPrice || 0;
  }
});

// Добавляем функцию форматирования цены
const formatPrice = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

// Функция для переключения состояния лайка
const toggleLike = () => {
  if (product.value) {
    likeStore.toggleFavorite({
      id: product.value.id,
      title: product.value.name,
      retailPrice: product.value.retailPrice,
      RsalePrice: product.value.sale_retail_price,
      TradePrice: product.value.tradePrice,
      TsalePrice: product.value.sale_trade_price,
      image: product.value.imageSet?.find(img => img.isMain)?.fileInfo.link || "",
      rating: 5,
      stock: product.value.remainedCount
    });
  }
};

// Загрузка данных пользователя
const fetchUserData = async () => {
  try {
    const token = localStorage.getItem("authToken");
    
    if (!token) {
      userLoading.value = false;
      return;
    }
    
    const response = await api.get("/api/clients/me");
    userData.value = response.data || {};
    userLoading.value = false;
  } catch (err) {
    //console.error("❌ Ошибка загрузки данных пользователя:", err);
    userLoading.value = false;
  }
};

// Загрузка данных о товаре
const fetchProductDetails = async () => {
  try {
    isLoading.value = true;
    const id = route.params.id;
    const response = await api.get(`/api/client/products/${id}`);
    const data = response.data;
    product.value = {
      ...data,
      imageSet: data.imageSet || [],
      description: data.description || null,
    };
  } catch (err) {
    error.value = {
      isError: true,
      errorCode: err.response?.status || "Ошибка",
      message: err.response?.data?.message || "Не удалось загрузить данные о товаре.",
      timeout: 5000,
    };
  } finally {
    isLoading.value = false;
  }
};

// Добавление товара в корзину
const addToCart = () => {
  if (product.value) {
    const cartItem = {
      id: product.value.id,
      title: product.value.name,
      retailPrice: product.value.retailPrice,
      sale_retail_price: product.value.sale_retail_price,
      tradePrice: product.value.tradePrice,
      sale_trade_price: product.value.sale_trade_price,
      price: getProductPrice.value,
      image: product.value.imageSet?.find(img => img.isMain)?.fileInfo.link || "",
      quantity: 1,
      remainedCount: product.value.remainedCount,
      reservedCount: product.value.reservedCount
    };
    
    cartStore.setProductToCart(cartItem);
  }
};

// Получаем все изображения товара
const productImages = computed(() => {
  if (!product.value?.imageSet) return [];
  return product.value.imageSet.map(img => img.fileInfo.link);
});

// Функция для возврата на предыдущую страницу
const goBack = () => {
  const savedState = sessionStorage.getItem('catalogState');
  if (savedState) {
    const state = JSON.parse(savedState);
    const query = {};
    
    if (state.page !== undefined) query.page = state.page + 1;
    if (state.organizationId) query.organization = state.organizationId;
    if (state.categoryName && state.categoryName !== 'Все') query.category = state.categoryName;
    if (state.age) query.age = state.age;
    if (state.sort) query.sort = state.sort;
    if (state.search) query.search = state.search;
    
    router.push({
      path: '/',
      query
    }).catch(() => {
      router.go(-1);
    });
  } else {
    router.go(-1);
  }
};

onMounted(async () => {
  await fetchUserData();
  await fetchProductDetails();
  
  // Загружаем избранное из localStorage
  const savedLikes = JSON.parse(localStorage.getItem('likes') || '[]');
  likeStore.setLikesFromLS(savedLikes);
  
  // Сохраняем информацию о текущей странице при входе на страницу товара
  const referer = document.referrer;
  if (referer && referer.includes('/catalog')) {
    localStorage.setItem('previousPage', '/catalog');
    const pageMatch = referer.match(/[?&]page=(\d+)/);
    if (pageMatch) {
      localStorage.setItem('previousPageNumber', pageMatch[1]);
    }
  }
});
</script>

<style scoped>
.product-page {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

.product-page__card {
  background: #f5f5f5;
  border-radius: 12px;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 10px;
}

.product-page__gallery {
  position: relative;
  margin-bottom: 0;
  background: white;
  border-radius: 8px;
  padding: 10px;
  height: fit-content;
}

.product-page__card-content {
  padding: 20px;
  background: white;
  border-radius: 8px;
  height: fit-content;
}

.product-page__card-title {
  text-align: center;
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 20px;
}

.product-page__info-rows {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.info-row {
  display: flex;
  gap: 10px;
  font-size: 15px;
  line-height: 1.4;
}

.info-row strong {
  min-width: 90px;
  color: #666;
}

.info-row span {
  color: #333;
  flex: 1;
}

.product-page__actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  padding: 15px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  gap: 15px;
}

.price-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.price-wrapper :deep(h2) {
  margin: 0;
  font-size: 24px;
  line-height: 1;
}

.old-price {
  text-decoration: line-through;
  color: #a0a0a0;
  font-size: 16px;
}

.discount-badge {
  background: red;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 4px;
  font-weight: bold;
}

.wholesale-price {
  color: #3498db !important;
}

.product-page__description {
  margin-top: 25px;
}

.product-page__description h3 {
  margin-bottom: 15px;
}

.action-buttons {
  display: flex;
  gap: 15px;
  align-items: center;
  flex-shrink: 0;
}

.like-icon, .cart-icon {
  cursor: pointer;
  width: 32px;
  height: 32px;
  fill: gray;
  transition: all 0.3s ease;
}

.like-icon.in-like {
  fill: red;
}

.cart-icon.in-cart {
  fill: #FF8C00;
}

/* Десктопная версия */
@media screen and (min-width: 768px) {
  .product-page__card {
    grid-template-columns: minmax(400px, 1fr) 1fr;
    gap: 30px;
    padding: 30px;
    align-items: start;
  }

  .product-page__gallery {
    position: sticky;
    top: 20px;
  }

  .product-page__card-title {
    text-align: left;
    font-size: 28px;
  }

  .info-row {
    font-size: 16px;
  }

  .price-wrapper :deep(h2) {
    font-size: 28px;
  }

  .old-price {
    font-size: 18px;
  }
}

/* Мобильная версия */
@media screen and (max-width: 767px) {
  .product-page__actions {
    padding: 12px 0;
  }

  .price-wrapper {
    gap: 8px;
  }

  .price-wrapper :deep(h2) {
    font-size: 22px;
  }

  .old-price {
    font-size: 14px;
  }

  .discount-badge {
    font-size: 11px;
    padding: 2px 4px;
  }

  .back-button {
    display: block;
  }
  
  .product-page {
    padding-top: 52px;
  }
  
  .product-page__card {
    margin-top: 0;
  }
}

/* Стили для кнопки "Назад" */
.back-button {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background-color: white;
  color: #333;
  border: none;
  border-bottom: 1px solid #ddd;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  z-index: 1000;
  display: none;
}

.floating-back-btn {
  position: fixed;
  top: 15px;
  left: 15px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #F15E24;
  border: none;
  color: white;
  font-size: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  z-index: 1000;
}

.back-icon {
  line-height: 1;
}

.floating-back-btn:active {
  transform: scale(0.95);
  background: #f5f5f5;
}

@media screen and (max-width: 767px) {
  .floating-back-btn {
    display: flex;
  }
  
  .product-page {
    padding-top: 15px;
  }
  
  .product-page__card {
    margin-top: 0;
  }
}
</style>