import axios from "axios";

// Используем  URL локалке
// const BASE_URL = "https://zerdetoys.assistantbusiness.kz/api";
// const BASE_URL = "http://localhost:8000";

// Используем относительный URL вместо абсолютного на сервере
const BASE_URL = "/api";

// ✅ Создаем Axios-инстанс с настройками для правильной сериализации параметров
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  // Добавляем кастомный сериализатор параметров
  paramsSerializer: {
    serialize: (params) => {
      const searchParams = new URLSearchParams();
      
      // Вручную добавляем каждый параметр, обрабатывая кириллицу
      for (const key in params) {
        if (params[key] !== null && params[key] !== undefined) {
          searchParams.append(key, params[key]);
        }
      }
      
      // Логируем для отладки
      console.log("Serialized params:", searchParams.toString());
      
      return searchParams.toString();
    }
  }
});

// ✅ Функция получения токена из `localStorage`
const getToken = () => localStorage.getItem("authToken");

// ✅ Перехватчик запросов: добавляет токен в заголовок и логирует URL для отладки
api.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    
    // Логируем URL и параметры для отладки
    //console.log("API Request URL:", config.url);
    //console.log("API Request Params:", config.params);
    
    return config;
  },
  (error) => Promise.reject(error)
);

// Добавляем перехватчик ответов для отладки
api.interceptors.response.use(
  (response) => {
    //console.log("API Response:", response.status);
    return response;
  },
  (error) => {
    console.error("API Error:", error.message);
    if (error.response) {
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
    }
    return Promise.reject(error);
  }
);

// ✅ Функция сохранения токена
export const saveToken = (token) => {
  localStorage.setItem("authToken", token);
};

// ✅ Функция удаления токена (например, при выходе)
export const removeToken = () => {
  localStorage.removeItem("authToken");
};

export default api;