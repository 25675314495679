<template>
  <nav class="bottom-nav">
    <button @click="goToHomePage" class="bottom-nav__button">
      <img src="@/assets/home.png" alt="Главная" class="nav-icon" />
      <span>Главная</span>
    </button>
    
    <button @click="goToLikes" class="bottom-nav__button">
      <img src="@/assets/like.png" alt="Избранное" class="nav-icon" />
      <span>Избранное</span>
      <span v-if="likeCount > 0" class="badge">{{ likeCount }}</span>
    </button>
    
    <button @click="goToCart" class="bottom-nav__button">
      <img src="@/assets/buy.png" alt="Корзина" class="nav-icon" />
      <span>Корзина</span>
      <span v-if="cartCount > 0" class="badge">{{ cartCount }}</span>
    </button>

    <button @click="goToProfile" class="bottom-nav__button">
      <img src="@/assets/profile.png" alt="Профиль" class="nav-icon" />
      <span>Профиль</span>
    </button>

    <button @click="goToOrders" class="bottom-nav__button">
      <img src="@/assets/order.png" alt="Мои заказы" class="nav-icon" />
      <span>Заказы</span>
    </button>

  </nav>
</template>

<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useCartStore } from "@/store/useCartStore";
import { useLikeStore } from "@/store/useLikeStore"; // Добавлен импорт для избранного
import { storeToRefs } from "pinia";

const router = useRouter();
const cartStore = useCartStore();
const likeStore = useLikeStore(); // Добавлен store для избранного

const { cart } = storeToRefs(cartStore);
const { likes } = storeToRefs(likeStore); // Получаем избранные товары

const cartCount = computed(() => (cart?.value ? cart.value.length : 0));
const likeCount = computed(() => (likes?.value ? likes.value.length : 0)); // Счетчик для избранного

const goToHomePage = () => {
  router.push("/");
};

const goToOrders = () => {
  router.push("/orders");
};

const goToCart = () => {
  router.push("/cart");
};

const goToProfile = () => {
  router.push("/profile");
};

// Новая функция для перехода к избранному
const goToLikes = () => {
  router.push("/likes");
};
</script>

<style scoped>
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #F15E24; 
  border-top: 1px solid #ddd;
  padding: 8px 0;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.bottom-nav__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-size: 10px;
  color: #fff; 
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 5px 0;
  flex: 1;
}

.bottom-nav__button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.bottom-nav__button .nav-icon {
  width: 22px;
  height: 22px;
  filter: brightness(0) invert(1); /* Делает иконки белыми */
}

.bottom-nav__button span {
  font-size: 10px;
  font-weight: 500;
}

/* Стили для значков с количеством товаров */
.badge {
  position: absolute;
  top: 0;
  right: 30%;
  background: #FFFF;
  color: #F15E24;
  font-size: 10px;
  font-weight: bold;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Адаптивность для маленьких экранов */
/* Изменение стиля кнопки оформления заказа */
.cart__checkout {
  background: #F15E24;
  color: white;
  border: none;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cart__checkout:hover {
  background: #d64d14;
}

/* Исправленные стили для мобильных устройств */
@media (max-width: 768px) {
  /* Стиль для карточки товара в корзине */
  .cart__item {
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background: #f5f5f5;
    margin-bottom: 10px;
    gap: 15px;
  }
  
  /* Стиль для изображения товара */
  .cart__image-wrapper {
    flex: 0 0 90px;
    width: 90px;
    margin-bottom: 0;
  }
  
  .cart__img {
    max-height: 80px;
    width: 100%;
    object-fit: contain;
  }
  
  /* Стиль для информации о наличии */
  .cart__stock-info {
    font-size: 11px;
    margin-top: 3px;
    text-align: left;
  }
  
  /* Стиль для блока с информацией о товаре */
  .cart__details {
    flex: 1;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }
  
  /* Стиль для заголовка товара */
  .cart__title-text {
    font-size: 14px;
    margin-bottom: 5px;
    order: -1;
  }
  
  /* Стиль для блока с ценой */
  .cart__info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  /* Стиль для количества товара */
  .cart__quantity {
    align-self: flex-start;
    margin-top: 5px;
  }
  
  /* Стиль для кнопок управления количеством */
  .cart__quantity button {
    width: 24px;
    height: 24px;
  }
  
  .cart__quantity input {
    width: 35px;
    height: 24px;
  }
  
  /* Стиль для блока "нет в наличии" */
  .cart__out-of-stock {
    align-self: flex-start;
  }
  
  /* Стиль для цены */
  .cart__price {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  /* Стиль для цены за единицу */
  .cart__price-per-unit {
    font-size: 11px;
  }
  
  /* Стиль для кнопки удаления */
  .cart__item > .base-icon-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

</style>