<template>
  <div class="likes-page">
    <!-- Хлебные крошки -->
    <div class="breadcrumbs">
      <router-link to="/" class="breadcrumb-link">Главная</router-link>
      <span class="breadcrumb-separator">›</span>
      <span class="breadcrumb-current">Избранное</span>
    </div>

    <!-- Основной контент -->
    <div class="likes-container">
      <!-- Левая панель навигации (только для десктопа) -->
      <div class="profile-sidebar desktop-only">
        <div class="menu-item" :class="{ active: activeTab === 'profile' }" @click="goToPage('/profile')">
          <img src="@/assets/profile.png" class="menu-icon" alt="Профиль" />
          <span>Профиль</span>
        </div>
        
        <div class="menu-item" :class="{ active: activeTab === 'orders' }" @click="goToPage('/orders')">
          <img src="@/assets/order.png" class="menu-icon" alt="Мои заказы" />
          <span>Мои заказы</span>
        </div>
        
        <div class="menu-item" :class="{ active: activeTab === 'likes' }" @click="setActiveTab('likes')">
          <img src="@/assets/like.png" class="menu-icon" alt="Избранное" />
          <span>Избранное</span>
        </div>
        
        <div class="menu-item" :class="{ active: activeTab === 'cart' }" @click="goToPage('/cart')">
          <img src="@/assets/buy.png" class="menu-icon" alt="Корзина" />
          <span>Корзина</span>
        </div>
      </div>

      <!-- Основная область контента -->
      <div class="likes-content">
        <h1 class="likes-title">Избранное</h1>

        <!-- Если список избранного пуст -->
        <div v-if="likedProducts.length === 0" class="empty-likes">
          <img src="@/assets/icon.png" alt="Пусто" class="empty-icon" />
          <p class="empty-text">В избранном пока ничего нет</p>
          <p class="empty-subtext">Добавляйте товары в избранное, чтобы вернуться к ним позже</p>
          <button class="catalog-btn" @click="goToPage('/')">Перейти в каталог</button>
        </div>

        <!-- Список товаров в избранном -->
        <ul v-else class="cart__list">
          <li
            class="cart__item"            
            v-for="product in likedProducts"
            :key="product.id"
          >
            <div class="cart__image-wrapper">
              <img
                :src="product.image || NoImage"
                class="cart__img"
                :alt="product.title || 'Изображение товара'"
                @click="goToProductPage(product.id)"
              />
            </div>

            <div class="cart__details">
              <div class="cart__info">
                <p class="cart__title-text">{{ product.title }}</p>
              </div>

              <div class="cart__actions">
                <button class="add-to-cart-btn" @click="addToCart(product)">
                  В корзину
                </button>
              </div>
            </div>

            <button
              class="remove-from-likes-btn"
              @click="removeFromLikes(product.id)"
            >
              &times;
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useLikeStore } from '@/store/useLikeStore';
import { useCartStore } from '@/store/useCartStore';
import { storeToRefs } from 'pinia';
import NoImage from "@/assets/icon.png";

const router = useRouter();
const likeStore = useLikeStore();
const cartStore = useCartStore();
const activeTab = ref('likes');

// Получаем список избранных товаров из хранилища
const { likes } = storeToRefs(likeStore);
const likedProducts = computed(() => likes.value || []);

// Функция для установки активной вкладки
const setActiveTab = (tab) => {
  activeTab.value = tab;
};

// Функция для перехода на другую страницу
const goToPage = (path) => {
  router.push(path);
};

// Функция для перехода на страницу товара
const goToProductPage = (id) => {
  router.push(`/product/${id}`);
};

// Функция для удаления товара из избранного
const removeFromLikes = (id) => {
  likeStore.removeProductFromFavorites(id);
};

// Функция для добавления товара в корзину
const addToCart = (product) => {
  cartStore.setProductToCart({...product, quantity: 1});
  alert('Товар добавлен в корзину!');
};
</script>
<style scoped>
.likes-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
}

.breadcrumb-link {
  color: #666;
  text-decoration: none;
}

.breadcrumb-link:hover {
  color: #F15E24;
}

.breadcrumb-separator {
  margin: 0 8px;
  color: #666;
}

.breadcrumb-current {
  color: #333;
  font-weight: 500;
}

.likes-container {
  display: flex;
  gap: 30px;
}

.profile-sidebar {
  width: 250px;
  flex-shrink: 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.menu-item:hover {
  background-color: #f5f5f5;
}

.menu-item.active {
  background-color: #F15E24;
  color: white;
}

.menu-item.active .menu-icon {
  filter: brightness(0) invert(1);
}

.menu-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.likes-content {
  flex: 1;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.likes-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

/* Стили для пустого списка избранного */
.empty-likes {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  text-align: center;
}

.empty-icon {
  width: 200px;
  height: 80px;
  margin-bottom: 20px;
  opacity: 0.5;
}

.empty-text {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.empty-subtext {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  max-width: 300px;
}

.catalog-btn {
  background: #F15E24;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.catalog-btn:hover {
  background: #d64d14;
}

/* Новые стили для списка товаров как в корзине */
.cart__list {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
}

.cart__item {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.cart__image-wrapper {
  flex: 0 0 120px;
  position: relative;
}

.cart__img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
}

.cart__stock-info {
  font-size: 12px;
  color: #555;
  text-align: center;
  margin-top: 5px;
}

.cart__details {
  flex: 1;
  padding: 0 10px;
}

.cart__title-text {
  font-size: 14px;
  margin-bottom: 10px;
}

.cart__actions {
  display: flex;
  justify-content: center; /* Центрирование по горизонтали */
  align-items: center; /* Центрирование по вертикали */
  width: 100%; /* Растягивание на всю ширину */
}
.add-to-cart-btn {
  background: #F15E24;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

.remove-from-likes-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: #999;
  cursor: pointer;
}

/* Медиа-запросы для мобильной версии */
@media (max-width: 768px) {
  .likes-page {
    padding: 0;
    margin: 0;
    width: 100%;
    padding-bottom: 70px;
    box-sizing: border-box;
  }
  
  .breadcrumbs {
    padding: 0 10px;
    margin-bottom: 15px;
  }
  
  .likes-container {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
  
  .desktop-only {
    display: none;
  }
  
  .likes-content {
    width: 100%;
    margin: 0;
    padding: 10px;
    border-radius: 0;
    box-shadow: none;
  }

  .cart__item {
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 15px;
  }

  .cart__image-wrapper {
    flex: 0 0 90px;
    width: 90px;
  }

  .cart__img {
    max-height: 80px;
    width: 100%;
    object-fit: contain;
  }

  .cart__stock-info {
    font-size: 11px;
    margin-top: 3px;
    text-align: left;
  }

  .cart__details {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .cart__title-text {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .add-to-cart-btn {
    padding: 6px 10px;
    font-size: 12px;
  }

  .remove-from-likes-btn {
    top: 5px;
    right: 5px;
    font-size: 20px;
  }

  .empty-likes {
    padding: 30px 0;
  }
  
  .empty-icon {
    width: 200px;
    height: 60px;
  }
  
  .empty-text {
    font-size: 16px;
  }
  
  .empty-subtext {
    font-size: 13px;
    margin-bottom: 15px;
  }
  
  .catalog-btn {
    padding: 10px 16px;
    font-size: 14px;
  }
}
</style>