<template>
  <!-- Контейнер для поиска и каталога -->
  <div class="search-catalog-container">
    <!-- Каталог кнопка -->
    <button class="catalog-button" :class="{ 'catalog-button_open': isMenuOpen }" @click="toggleMenu">
      <span class="catalog-icon" v-if="!isMenuOpen">🔍︎</span>
      <span class="catalog-icon" v-else>✕</span>
      Каталог
    </button>

    <!-- Поле поиска -->
    <SearchBar class="search-bar" :modelValue="searchText" @update:modelValue="updateSearchText" @search="handleSearch" />
  </div>

  <!-- Организации -->
  <div class="organizations-container" v-if="hasOrganizations">
    <div class="organizations-list">
      <button 
        v-for="org in organizations" 
        :key="org.id"
        class="organization-item"
        :class="{ 'organization-item_active': selectedOrganizationId === org.id }"
        @click="handleOrganizationClick(org.id)"
      >
        <div class="organization-icon">
          <img 
            :src="org.id === 1 ? require('@/assets/toys-icon.png') : require('@/assets/home-icon.png')" 
            :alt="org.name"
          />
        </div>
        <span class="organization-name">{{ org.name }}</span>
      </button>
    </div>
  </div>

  <!-- 🔹 Сортировка -->
  <div class="catalog__sort">
    <button
      v-for="sortOption in sortOptions"
      :key="sortOption.value"
      @click="setSorting(sortOption.value)"
      :class="{ active: selectedSort === sortOption.value }"
    >
      <span class="sort-icon">{{ sortOption.icon }}</span> {{ sortOption.label }}
    </button>
  </div>

  <!-- Затемнение фона при открытом меню -->
  <div v-if="isMenuOpen" class="overlay" @click="toggleMenu"></div>

  <!-- Выпадающее меню каталога -->
  <div class="catalog-menu" v-if="isMenuOpen">
    <!-- Кнопка закрытия -->
    <button class="close-button" @click="toggleMenu">✕</button>
    
    <!-- Организации и их категории -->
    <div class="menu-section">
      <!-- Категории для выбранной организации -->
      <div class="categories-list" v-if="categories.length">
        <button 
          class="category-item"
          :class="{ 'category-item_active': selectedCategoryName === 'Все' }"
          @click="handleCategoryClick('Все')"
        >
          Все
        </button>
        <button 
          v-for="category in categories"
          :key="category.id"
          class="category-item"
          :class="{ 'category-item_active': selectedCategoryName === category.name }"
          @click="handleCategoryClick(category.name)"
        >
          {{ category.name }}
        </button>
      </div>

      <!-- Возраст (только для организации с ID 1) -->
      <div v-if="selectedOrganizationId === 1" class="age-section">
        <div class="age-title">Возраст</div>
        <div class="age-list">
          <button 
            class="age-item"
            :class="{ 'age-item_active': selectedAge === null }"
            @click="selectedAge = null; handleAgeChange()"
          >
            Все
          </button>
          <button 
            v-for="age in ageOptions" 
            :key="age.id"
            class="age-item"
            :class="{ 'age-item_active': selectedAge === age.id }"
            @click="selectedAge = age.id; handleAgeChange()"
          >
            {{ age.name }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="catalog">
    <!-- Ошибки запроса -->
    <FadeTransition>
      <FailedHttpRequest
        :errorCode="error.errorCode"
        :errorMessage="error.message"
        :timeout="error.timeout"
        :serverIsDown="serverStatus.isDown"
        :serverErrorMessage="serverStatus.message"
        v-if="error?.isError && !isLoading"
      />
    </FadeTransition>

    <!-- Лоадер -->
    <div class="catalog__loader">
      <FadeTransition>
        <Loader v-if="isLoading" />
      </FadeTransition>
    </div>

    <!-- Список товаров -->
    <div v-if="!isLoading && products.length === 0" class="error-container">
      <img :src="Error" alt="Ошибка загрузки" class="error-image" />

      <!-- Показываем разные сообщения в зависимости от наличия поискового запроса -->
      <p v-if="searchText && searchText.trim()" class="error-text">
        По запросу "<span class="search-query">{{ searchText }}</span>" ничего не найдено. 
        Попробуйте изменить запрос или посмотреть другие товары.
      </p>
      <p v-else class="error-text">
        Эльфы заняты ремонтом! Мы немного закроем двери, чтобы они закончили свою магию. Скоро вернемся!
      </p>
      
    </div>

    <div v-else class="product-list">
      <Card
        v-for="product in productList"
        :key="product.id"
        :id="product.id"
        :title="product.name"
        :retailPrice="product.retailPrice"
        :RsalePrice="product.sale_retail_price"
        :TradePrice="product.tradePrice"
        :TsalePrice="product.sale_trade_price"
        :image="getMainImage(product)"
        :rating="5"
        :newsupply="product.new_stock"
        :comeinsupply="product.comein_stock"
        :stock="product.remainedCount"
        @click="goToProductPage(product.id)"
      />
    </div>

    <!-- Пагинация -->
    <div class="pagination" v-if="!isLoading && totalPages > 1">
      <!-- Кнопка "Предыдущая" -->
      <button 
        @click="changePage(currentPage - 1)" 
        :disabled="currentPage === 0"
        class="pagination-nav"
      >
        &laquo;
      </button>
      
      <!-- Первая страница -->
      <button 
        v-if="showFirstPage" 
        @click="changePage(0)" 
        :class="{'active': currentPage === 0}"
      >
        1
      </button>
      
      <!-- Разделитель перед текущими страницами -->
      <span v-if="showFirstEllipsis" class="ellipsis">...</span>
      
      <!-- Страницы вокруг текущей -->
      <button 
        v-for="page in visiblePageNumbers" 
        :key="page" 
        @click="changePage(page)" 
        :class="{'active': page === currentPage}"
      >
        {{ page + 1 }}
      </button>
      
      <!-- Разделитель после текущих страниц -->
      <span v-if="showLastEllipsis" class="ellipsis">...</span>
      
      <!-- Последняя страница -->
      <button 
        v-if="showLastPage" 
        @click="changePage(totalPages - 1)" 
        :class="{'active': currentPage === totalPages - 1}"
      >
        {{ totalPages }}
      </button>
      
      <!-- Кнопка "Следующая" -->
      <button 
        @click="changePage(currentPage + 1)" 
        :disabled="currentPage === totalPages - 1"
        class="pagination-nav"
      >
        &raquo;
      </button>
    </div>
  </div>
</template>


<script setup>
import SearchBar from "@/components/SearchBar.vue"; 
import CategoriesTab from "../components/CategoryTabs.vue";
import FailedHttpRequest from "./FailedHttpRequest.vue";
import Loader from "./UI/Loader.vue";
import FadeTransition from "./UI/FadeTransition.vue";
import Card from "./Card.vue";
import { useRouter } from "vue-router";
import { ref, computed, onMounted, onUnmounted, nextTick } from "vue";
import { useProductStore } from "../store/useProductStore";
import { storeToRefs } from "pinia";
import Error from "@/assets/error.png";


const router = useRouter();
const productStore = useProductStore();
const { products, isLoading, error, currentPage, totalPages, ageOptions, organizations, selectedOrganizationId } = storeToRefs(productStore);
const productList = computed(() => products.value);
const categories = ref([]);
const searchText = ref("");
const serverStatus = ref({ isDown: false, message: '' });
const selectedAge = ref(null);

// Определяем, является ли устройство мобильным
const isMobile = ref(false);

// Максимальное количество страниц для отображения (динамически)
const maxVisiblePages = computed(() => {
  return isMobile.value ? 3 : 5;
});

// 🔹 Опции сортировки
const sortOptions = [
  { label: "Новый", value: "NEWEST", icon: "🆕" },
  { label: "Акция", value: "SALE", icon: "🎉" },
  { label: "Топ", value: "POPULAR", icon: "🔥" },
  { label: "Дешевый", value: "CHEAPEST", icon: "💰" },
  { label: "Дорогой", value: "EXPENSIVE", icon: "💎" },
];

const selectedSort = ref("NEWEST");

const setSorting = (sortOrder) => {
  selectedSort.value = sortOrder;
  productStore.fetchProducts({
    page: 0,
    categoryList: selectedCategoryName.value !== "Все" ? categories.value.find(cat => cat.name === selectedCategoryName.value)?.id : null,
    searchText: searchText.value,
    sortOrder: sortOrder,
    ageList: selectedAge.value,
  });
};

// Загружаем категории
const fetchCategories = async () => {
  try {
    await productStore.fetchCategories();
    categories.value = productStore.allCategories;
  } catch (error) {
   // console.error("Ошибка загрузки категорий:", error);
  }
};

// Фильтр по категории
const selectedCategoryName = ref("Все");

const handleCategoryClick = (categoryName) => {
  selectedCategoryName.value = categoryName;
  isMenuOpen.value = false; // Закрываем модалку
  document.body.style.overflow = ''; // Разблокируем скролл

  productStore.fetchProducts({
    page: 0,
    categoryList: categories.value.find(cat => cat.name === categoryName)?.id || null,
    searchText: searchText.value,
    sortOrder: selectedSort.value,
    ageList: selectedAge.value,
  });
};

// Обработка изменения возраста
const handleAgeChange = () => {
  isMenuOpen.value = false; // Закрываем модалку
  document.body.style.overflow = ''; // Разблокируем скролл
  
  productStore.fetchProducts({
    page: 0,
    categoryList: selectedCategoryName.value !== "Все" ? categories.value.find(cat => cat.name === selectedCategoryName.value)?.id : null,
    searchText: searchText.value,
    sortOrder: selectedSort.value,
    ageList: selectedAge.value,
  });
};

// Функция для проверки размера экрана
const checkScreenSize = () => {
  isMobile.value = window.innerWidth <= 400;
};

// Отображаемые номера страниц (вычисляемое свойство)
const visiblePageNumbers = computed(() => {
  const half = Math.floor(maxVisiblePages.value / 2);
  let start = Math.max(currentPage.value - half, 0);
  let end = Math.min(start + maxVisiblePages.value - 1, totalPages.value - 1);
  
  // Корректировка начала, если конец достиг предела
  if (end === totalPages.value - 1) {
    start = Math.max(end - maxVisiblePages.value + 1, 0);
  }
  
  // Создаем массив страниц для отображения
  return Array.from({length: end - start + 1}, (_, i) => start + i);
});

// Показывать ли первую страницу
const showFirstPage = computed(() => {
  return visiblePageNumbers.value[0] > 0;
});

// Показывать ли многоточие перед страницами
const showFirstEllipsis = computed(() => {
  return visiblePageNumbers.value[0] > 1;
});

// Показывать ли многоточие после страниц
const showLastEllipsis = computed(() => {
  return visiblePageNumbers.value[visiblePageNumbers.value.length - 1] < totalPages.value - 2;
});

// Показывать ли последнюю страницу
const showLastPage = computed(() => {
  return visiblePageNumbers.value[visiblePageNumbers.value.length - 1] < totalPages.value - 1;
});

// Функция смены страницы
const changePage = (page) => {
  // Проверка на валидность страницы
  if (page < 0 || page >= totalPages.value) return;
  
  productStore.fetchProducts({ 
    page: page,
    categoryList: selectedCategoryName.value !== "Все" 
      ? categories.value.find(cat => cat.name === selectedCategoryName.value)?.id 
      : null,
      searchText: searchText.value,
    sortOrder: selectedSort.value,
  });
};

// Добавляем функцию для сохранения состояния
const saveCurrentState = () => {
  const state = {
    page: currentPage.value,
    organizationId: selectedOrganizationId.value,
    categoryName: selectedCategoryName.value,
    age: selectedAge.value,
    sort: selectedSort.value,
    search: searchText.value
  };
  sessionStorage.setItem('catalogState', JSON.stringify(state));
};

// Функция для восстановления состояния
const restoreState = () => {
  const savedState = sessionStorage.getItem('catalogState');
  if (savedState) {
    const state = JSON.parse(savedState);
    currentPage.value = state.page;
    selectedOrganizationId.value = state.organizationId;
    selectedCategoryName.value = state.categoryName;
    selectedAge.value = state.age;
    selectedSort.value = state.sort;
    searchText.value = state.search;
    
    // Загружаем данные с сохраненными фильтрами
    productStore.fetchProducts({
      page: state.page,
      categoryList: state.categoryName !== "Все" 
        ? categories.value.find(cat => cat.name === state.categoryName)?.id 
        : null,
      searchText: state.search,
      sortOrder: state.sort,
      ageList: state.age
    });
  }
};

// Модифицируем функцию перехода на страницу товара
const goToProductPage = (productId) => {
  saveCurrentState();
  router.push(`/product/${productId}`);
};


// Обработчик поиска
const handleSearch = (value) => {
  productStore.fetchProducts({
    page: 0,
    categoryList: selectedCategoryName.value !== "Все" 
      ? categories.value.find(cat => cat.name === selectedCategoryName.value)?.id 
      : null,
    searchText: value, // Используем единый параметр searchText
    sortOrder: selectedSort.value,
  });
};

// Обработчик обновления текста поиска
const updateSearchText = (value) => {
  searchText.value = value;
};

const getMainImage = (product) => {
  const mainImage = product.imageSet?.find(image => image.isMain);
  return mainImage ? mainImage.fileInfo.link : "";
};

// Вычисляемое свойство для проверки наличия организаций
const hasOrganizations = computed(() => organizations.value && organizations.value.length > 0);

// Обработчик клика по организации
const handleOrganizationClick = async (orgId) => {
  if (orgId === selectedOrganizationId.value) return;
  
  selectedOrganizationId.value = orgId;
  productStore.setSelectedOrganization(orgId);
  
  try {
    // Сбрасываем выбранную категорию и возраст
    selectedCategoryName.value = "Все";
    selectedAge.value = null;
    
    // Сначала загружаем категории для новой организации
    const newCategories = await productStore.fetchCategories();
    categories.value = newCategories;
    
    // Затем загружаем продукты для новой организации
    await productStore.fetchProducts({ 
      page: 0,
      categoryList: null,
      searchText: searchText.value,
      sortOrder: selectedSort.value,
      ageList: null // Сбрасываем фильтр по возрасту
    });
  } catch (error) {
    console.error("Ошибка при смене организации:", error);
  }
};

// Добавляем новые реактивные переменные
const isMenuOpen = ref(false);
const isAgeOpen = ref(false);

// Функция для переключения меню
const toggleMenu = () => {
  isMenuOpen.value = !isMenuOpen.value;
  // Блокируем/разблокируем скролл
  if (isMenuOpen.value) {
    document.body.style.overflow = 'hidden';
  } else {
    document.body.style.overflow = '';
  }
};

onMounted(async () => {
  // Добавляем обработчик изменения размера окна
  window.addEventListener('resize', checkScreenSize);
  // Проверяем размер при загрузке
  checkScreenSize();
  
  try {
    // Загружаем данные пользователя
    await productStore.fetchUserData();
    
    // Сначала загружаем организации
    await productStore.fetchOrganizations();
    
    // После загрузки организаций загружаем категории
    if (hasOrganizations.value) {
      const cats = await productStore.fetchCategories();
      categories.value = cats;
      
      // Загружаем продукты только после получения категорий
      await productStore.fetchProducts({ page: 0 });
    }
    
    // Загружаем опции возраста параллельно
    productStore.fetchAgeOptions();
  } catch (error) {
    console.error("Ошибка при инициализации каталога:", error);
  }

  // Восстанавливаем состояние при монтировании
  restoreState();
});

onUnmounted(() => {
  // Удаляем обработчик при размонтировании компонента
  document.body.style.overflow = '';
  window.removeEventListener('resize', checkScreenSize);
});
</script>


<style scoped>
/* Используем общий шрифт */
body {
  font-family: "Nunito", sans-serif;
  color: #333;
}

/* Каталог */
.catalog {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Контейнер категорий */
.catalog__categories {
  width: 100%;
  position: relative;
  z-index: 100;
  background: #fff;
  position: relative;
  z-index: 1; /* Устанавливаем базовый z-index */
}

/* Контейнер списка товаров */
.product-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;
  padding: 16px;
  width: 100%;
  max-width: 1200px; 
  margin: 0 auto;
  box-sizing: border-box;
}

/* Для мобильных устройств */
@media (max-width: 768px) {
  .product-list {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 8px;
    margin: 0;
    width: 100%;
  }

  .catalog {
    padding: 0;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }

  .search-catalog-container {
    padding: 8px;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }
}

/* Для планшетов */
@media (min-width: 769px) and (max-width: 1023px) {
  .product-list {
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    padding: 12px;
  }
}

/* Для десктопов */
@media (min-width: 1024px) {
  .product-list {
    grid-template-columns: repeat(5, 1fr);
  }
}

/* Контейнер товара */
.product-list > * {
  background: white;
  border-radius: 10px;
  padding: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

/* Поле поиска */
.search-bar {
  flex: 1;
  margin: 0;
  min-width: 0; /* Позволяет полю поиска сжиматься */
}

/* Пагинация */
/* Добавьте эти стили в секцию <style> в Catalog.vue */

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.pagination button {
  min-width: 32px;
  height: 32px;
  background: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.pagination button:hover:not(:disabled) {
  background: #e0e0e0;
}

.pagination button.active {
  background: #F15E24;
  color: #ffffff;
  font-weight: bold;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination .pagination-nav {
  font-weight: bold;
}

.pagination .ellipsis {
  min-width: 32px;
  text-align: center;
}

/* Стили для мобильных устройств - компактная пагинация */
@media (max-width: 768px) {
  .pagination {
    gap: 2px;
    flex-wrap: nowrap; /* Запрет переноса на новую строку */
    overflow-x: auto; /* Добавляем горизонтальную прокрутку при необходимости */
    padding-bottom: 10px; /* Для места под полосой прокрутки */
    width: 100%;
    justify-content: center;
  }
  
  .pagination button {
    min-width: 28px;
    height: 28px;
    padding: 3px 6px;
    font-size: 12px;
  }
  
  .pagination .ellipsis {
    min-width: 20px;
    font-size: 12px;
  }
  
}

/* Стиль для пагинации как на картинке 3 (синий современный вид) */
.pagination-modern {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-modern button {
  min-width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  color: #333;
  font-size: 14px;
  margin: 0 2px;
  cursor: pointer;
  border-radius: 4px;
}

.pagination-modern button.active {
  background: #F15E24;
  color: white;
  font-weight: bold;
  box-shadow: 0 2px 5px rgba(33, 150, 243, 0.3);
}

.pagination-modern button:hover:not(.active):not(:disabled) {
  background: #f0f0f0;
}

/* Мобильные стили для современной пагинации */
@media (max-width: 768px) {
  .pagination-modern {
    gap: 0;
  }
  
  .pagination-modern button {
    min-width: 35px;
    height: 35px;
    font-size: 13px;
  }
}
/* 🔹 Сортировка */
.catalog__sort {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  font-size: 12px;
  gap: 5px;
  background: #fff;
  border-bottom: 1px solid #ddd;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  box-sizing: border-box;
}

.catalog__sort button {
  flex: 1;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #333;
  transition: 0.3s;
  min-width: max-content;
}

.catalog__sort button:hover {
  color: #ffa801;
}

.catalog__sort .active {
  font-weight: bold;
  color: #ffa801;
}


.catalog__age-filter {
  width: 100%;
  overflow: hidden;
  background: #fff;
  padding: 5px;
}

.age-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  display: flex;
  align-items: center;
  background-color: #fff;
  padding-bottom: 5px;
}

.age-container::-webkit-scrollbar {
  display: none;
}

.age-list {
  list-style: none;
  display: flex;
  gap: 5px;
  padding: 5px;
  width: 100%;
  justify-content: space-between;
}

.age-list__item {
  flex: 1;
  padding: 5px 15px;
  gap: 5px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  color: #666;
  cursor: pointer;
  transition: background 0.3s ease;
  text-align: center;
  white-space: nowrap;
}

.age-list__item:hover {
  background-color: #e8e8e8;
}

.age-list__item_active {
  background-color: #e8e8e8;
  color: #333;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}

.error-image {
  max-width: 300px;
  height: auto;
}

.error-text {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}


/* Добавьте эти стили в секцию <style scoped> в Catalog.vue */

.search-query {
  font-weight: bold;
  color: #F15E24;
}

.reset-search-button {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease;
}

.reset-search-button:hover {
  background-color: #F15E24;
  color: white;
}

.error-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  margin: 20px 0;
}

.error-text {
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
  max-width: 600px;
  line-height: 1.5;
}

.catalog__organizations {
  width: 100%;
  background: #fff;
  padding: 5px;
  margin-bottom: 5px;
}

.organizations-container {
  width: 100%;
  background: #fff;
  padding: 10px 16px;
  margin-bottom: 5px;
  overflow-x: auto;
  scrollbar-width: none;
}

.organizations-container::-webkit-scrollbar {
  display: none;
}

.organizations-list {
  display: flex;
  gap: 10px;
  padding: 5px;
  flex-wrap: nowrap;
}

.organization-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.organization-item_active {
  background-color: #F15E24;
  color: white;
}

.organization-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.organization-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.organization-name {
  font-size: 14px;
  font-weight: 600;
}

.organization-item:hover:not(.organization-item_active) {
  background-color: #e8e8e8;
}

/* Адаптивность для организаций */
@media (max-width: 768px) {
  .organizations-container {
    padding: 8px;
  }
  
  .organizations-list {
    gap: 8px;
  }
  
  .organization-item {
    padding: 6px 12px;
  }
  
  .organization-name {
    font-size: 13px;
  }
}

/* Обновленные стили для кнопки каталога */
.catalog-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #F15E24;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  z-index: 9998;
  white-space: nowrap;
  height: 40px;
  min-width: 120px;
}

.catalog-button_open {
  background: #808080;
}

.catalog-icon {
  font-size: 20px;
  transition: transform 0.3s ease;
}

/* Обновленные стили для выпадающего меню */
.catalog-menu {
  position: fixed;
  top: 60px; /* Отступ от верха */
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 1200px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 9999;
  max-height: calc(100vh - 80px);
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #F15E24 #f5f5f5;
}

.catalog-menu::-webkit-scrollbar {
  width: 8px;
}

.catalog-menu::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 4px;
}

.catalog-menu::-webkit-scrollbar-thumb {
  background: #F15E24;
  border-radius: 4px;
}

/* Стили для затемнения фона */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  overflow: hidden;
}

/* Обновляем стили для мобильных устройств */
@media (max-width: 768px) {
  .catalog-menu {
    position: fixed;
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    width: 92%;
    height: 100vh;
    margin: 0;
    border-radius: 0;
    transform: none;
    max-height: none;
    padding: 16px;
    background: white;
    overflow-y: auto;
    z-index: 10000;
    box-shadow: -4px 0 12px rgba(0, 0, 0, 0.1);
  }

  .menu-section {
    padding-top: 40px;
  }

  .close-button {
    position: fixed;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;
    background: #f5f5f5;
    z-index: 10001;
  }

  .organizations-list {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .categories-list {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .age-list {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .overlay {
    z-index: 9999;
  }
}

.menu-section {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Стили для категорий */
.categories-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;
}

.category-item {
  padding: 8px 16px;
  background: #f5f5f5;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.category-item_active {
  background: #F15E24;
  color: white;
}

/* Стили для секции возраста */
.age-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.age-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
}

.arrow-icon {
  transition: transform 0.3s ease;
}

.arrow-up {
  transform: rotate(180deg);
}

.age-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 0;
}

.age-item {
  padding: 8px 16px;
  background: #f5f5f5;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.age-item_active {
  background: #F15E24;
  color: white;
}

/* Адаптивность */
@media (max-width: 768px) {
  .catalog-menu {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    border-radius: 16px 16px 0 0;
    max-height: 80vh;
    overflow-y: auto;
    padding-bottom: 80px;
  }

  .catalog-button {
    position: relative;
    bottom: auto;
    left: auto;
    transform: none;
    z-index: 10000;
    box-shadow: none;
  }
}

/* Контейнер для поиска и каталога */
.search-catalog-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px 16px;
  background: white;
  position: relative;
}

/* Стили для кнопки закрытия */
.close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border: none;
  background: #f5f5f5;
  color: #333;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: all 0.3s ease;
  z-index: 10000;
}

.close-button:hover {
  background: #e0e0e0;
  transform: rotate(90deg);
}

@media (max-width: 768px) {
  .search-catalog-container {
    padding: 8px;
    gap: 8px;
  }

  .catalog-button {
    min-width: auto;
    padding: 8px 12px;
  }
}

@media (max-width: 768px) {
  .product-list > * {
    padding: 6px;
    border-radius: 8px;
    margin: 0;
  }

  /* Оптимизация сортировки для мобильных */
  .catalog__sort {
    padding: 8px;
    margin: 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    width: 100%;
    box-sizing: border-box;
  }

  .catalog__sort::-webkit-scrollbar {
    display: none;
  }

  .catalog__sort button {
    padding: 4px 8px;
    white-space: nowrap;
    font-size: 12px;
  }
}
</style>