<template>
  <div class="card">
    <!-- 🔹 Верхняя строка: скидка слева, лайк справа -->
    <div class="card__top-row">
      <div class="badges-container">
        <div v-if="isWholesaleClient && TsalePrice" class="discount-badge">
          -{{ Math.round(((TradePrice - TsalePrice) / TradePrice) * 100) }}%
        </div>
        <div v-else-if="!isWholesaleClient && RsalePrice" class="discount-badge">
          -{{ Math.round(((retailPrice - RsalePrice) / retailPrice) * 100) }}%
        </div>
        <div v-if="newsupply" class="new-badge">Новый</div>
        <div v-else-if="comeinsupply" class="supply-badge">Поступление</div>
      </div>
      <LikeIcon class="like-icon" :class="{ 'in-like': isInLike }" @click.stop="toggleLike" />
    </div>

    <!-- 🔹 Контейнер изображения -->
    <div class="card__image-container">
      <img :src="productImage" class="card__image" :alt="title" @error="handleImageError" />
    </div>

    <!-- 🔹 Название товара -->
    <p class="card__title">{{ title }}</p>

    <!-- 🔹 Рейтинг и остаток (остаток только для оптовых клиентов) -->
    <div class="card__info">
      <div class="card__rating">
        <span v-for="n in 5" :key="n" class="star" :class="{ 'filled-star': n <= rating }">★</span>
      </div>
      <p v-if="isWholesaleClient" class="card__stock" :class="{ 'low-stock': stock < 10 }">
        {{ stock < 10 ? "🚨 Успей" : `Остаток: ${stock} шт` }}
      </p>
    </div>

    <!-- 🔹 Цена и корзина на одной строке -->
    <div class="card__bottom">
      <div class="price-container">
        <!-- Цены для оптовых клиентов -->
        <template v-if="isWholesaleClient">
          <h3 v-if="TsalePrice" class="old-price">₸ {{ formatPrice(TradePrice) }}</h3>
          <h3 class="product-price wholesale-price">₸ {{ formatPrice(TsalePrice || TradePrice) }}</h3>
        </template>
        <!-- Цены для розничных клиентов -->
        <template v-else>
          <h3 v-if="RsalePrice" class="old-price">₸ {{ formatPrice(retailPrice) }}</h3>
          <h3 class="product-price">₸ {{ formatPrice(RsalePrice || retailPrice) }}</h3>
        </template>
      </div>
      <CartIcon class="cart-icon" :class="{ 'in-cart': isInCart }" @click.stop="toggleCart" />
    </div>
  </div>
</template>


<script setup>
import CartIcon from "./icons/CartIcon.vue";
import LikeIcon from "./icons/LikeIcon.vue";
import { useCartStore } from "@/store/useCartStore";
import { useLikeStore } from "@/store/useLikeStore";
import { useProductStore } from "@/store/useProductStore";
import { computed, onMounted, watch, ref } from "vue";
import NoImage from "@/assets/no-image.png";

const props = defineProps({
  image: { type: String, required: true },
  title: { type: String, required: true },
  retailPrice: { type: Number, required: true },
  RsalePrice: { type: Number, required: false },
  TradePrice: { type: Number, required: false },
  TsalePrice: { type: Number, required: false },
  rating: { type: Number, required: true },
  stock: { type: Number, required: true },
  id: { type: [Number, String], required: true },
  newsupply: { type: Boolean, default: false }, // новый товар
  comeinsupply: { type: Boolean, default: false }, // поступление
});

const cartStore = useCartStore();
const likeStore = useLikeStore();
const productStore = useProductStore();

// Используем геттер из store вместо локального вычисления
const isWholesaleClient = computed(() => productStore.isWholesaleClient);

const isInCart = computed(() => cartStore.cart.some((item) => item.id === props.id));
const isInLike = computed(() => likeStore.likes.some((item) => item.id === props.id));

// Вычисляемое свойство для изображения с fallback на no-image
const productImage = computed(() => {
  return props.image || NoImage;
});

onMounted(() => {
  // Загружаем избранное из localStorage
  const savedLikes = JSON.parse(localStorage.getItem('likes') || '[]');
  likeStore.setLikesFromLS(savedLikes);
});

// Следите за изменениями в лайках и сохраняйте их
watch(() => likeStore.likes, (newLikes) => {
  localStorage.setItem('likes', JSON.stringify(newLikes));
}, { deep: true });

// Добавляем обработчик ошибки загрузки изображения
const handleImageError = (e) => {
  e.target.src = NoImage;
};

// Добавляем функцию форматирования цены
const formatPrice = (price) => {
  return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
};

const toggleCart = () => {
  if (!isInCart.value) {
    // Создаем объект товара для корзины с учетом типа клиента
    const cartItem = {
      ...props,
      quantity: 1,
      // Если оптовый клиент, берем оптовые цены, иначе розничные
      price: isWholesaleClient.value ? (props.TsalePrice || props.TradePrice) : (props.RsalePrice || props.retailPrice)
    };
    
    cartStore.setProductToCart(cartItem);
  } else {
    cartStore.removeProductFromCart(props.id);
  }
};

const toggleLike = () => {
  likeStore.toggleFavorite(props);
};
</script>

<style scoped>
.card {
position: relative;
background: white;
border-radius: 10px;
padding: 8px;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
display: flex;
flex-direction: column;
align-items: center;
text-align: center;
overflow: hidden;
}

/* 🔹 Верхняя строка: Скидка и лайк */
.card__top-row {
position: absolute;
top: 8px;
left: 8px;
right: 8px;
display: flex;
justify-content: space-between;
align-items: flex-start;
z-index: 5; 
}

/* Контейнер для бейджей */
.badges-container {
display: flex;
flex-direction: column;
gap: 4px;
}

/* Скидка */
.discount-badge {
background: red;
color: white;
font-size: 12px;
padding: 4px 6px;
border-radius: 5px;
font-weight: bold;
}

/* Новый */
.new-badge {
background: #07da89;
color: white;
font-size: 12px;
padding: 4px 6px;
border-radius: 5px;
font-weight: bold;
}

/* Поступление */
.supply-badge {
background: #F15E24;
color: white;
font-size: 12px;
padding: 4px 6px;
border-radius: 5px;
font-weight: bold;
}

/* 🔹 Лайк */
.like-icon {
cursor: pointer;
width: 24px;
height: 24px;
fill: gray;
transition: fill 0.3s;
}

/* 🔹 Позиция сердечка (лайка) всегда справа сверху */
.like-icon {
position: absolute;
top: 8px;
right: 8px;
width: 24px;
height: 24px;
cursor: pointer;
fill: gray;
}

.like-icon.in-like {
fill: red;
}

/* 🔹 Контейнер изображения */
.card__image-container {
position: relative;
width: 100%;
height: 200px;
overflow: hidden;
border-radius: 8px;
}

/* 🔹 Изображение */
.card__image {
width: 100%;
height: 100%;
object-fit: contain;
}

/* 🔹 Название */
.card__title {
font-size: 14px;
font-weight: bold;
text-align: center;
margin: 4px 0;
height: 65px; /* фиксированная высота под 3 строки */
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 3; 
-webkit-box-orient: vertical;
}

/* 🔹 Рейтинг и остаток */
.card__info {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
}

/* Рейтинг */
.card__rating {
display: flex;
gap: 2px;
font-size: 14px;
color: #ccc;
}

.filled-star {
color: #ffcc00;
}

/* Остаток */
.card__stock {
font-size: 12px;
font-weight: 600;
color: #28a745;
}

.low-stock {
color: #FF8C00;
}

/* 🔹 Цена и корзина на одной строке */
.card__bottom {
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
margin-top: 8px;
padding: 0 5px;
}

.wholesale-price {
  color: #3498db !important; /* Синий цвет для оптовых клиентов */
}

/* Цены на одной строке */
.price-container {
display: flex;
align-items: center;
gap: 8px; /* добавляем расстояние между старой и новой ценой */
}

.old-price {
text-decoration: line-through;
font-size: 14px;
color: #a0a0a0;
}

.product-price {
font-size: 16px;
font-weight: bold;
color: #FF8C00;
}

/* Корзина */
.cart-icon {
cursor: pointer;
width: 28px;
height: 28px;
fill: gray;
transition: fill 0.3s;
}

.cart-icon.in-cart {
fill: #FF8C00;
}

</style>