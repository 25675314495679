import { defineStore } from "pinia";

export const usePendingOrderStore = defineStore("pendingOrder", {
  state: () => ({
    order: null,
    expiryTime: null,
  }),
  getters: {
    hasPendingOrder: (state) => !!state.order,
    remainingTime: (state) => {
      if (!state.expiryTime) return 0;
      return Math.max(0, Math.floor((state.expiryTime - Date.now()) / 1000 / 60)); // Минуты
    },
  },
  actions: {
    savePendingOrder(order) {
      this.order = order;
      this.expiryTime = Date.now() + 10 * 60 * 1000; // 10 минут
      localStorage.setItem("pendingOrder", JSON.stringify(order));
      localStorage.setItem("pendingOrderExpiry", this.expiryTime.toString());
      console.log("✅ Заказ сохранен в LocalStorage:", order);
    },
    loadPendingOrder() {
      const storedOrder = localStorage.getItem("pendingOrder");
      const storedExpiry = localStorage.getItem("pendingOrderExpiry");

      if (storedOrder && storedExpiry) {
        this.order = JSON.parse(storedOrder);
        this.expiryTime = parseInt(storedExpiry);
        console.log("🔄 Загружен незавершенный заказ:", this.order);
      } else {
        this.order = null;
        this.expiryTime = null;
      }
    },
    clearOrder() {
      this.order = null;
      this.expiryTime = null;
      localStorage.removeItem("pendingOrder");
      localStorage.removeItem("pendingOrderExpiry");
      console.log("🗑️ Незавершенный заказ удален");
    },
  },
});
