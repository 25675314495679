<template>
  <main class="main">
    <!-- 🔹 Модальное окно корзины -->
    <teleport to="body">
      <BaseModal :isModalOpen="isCartModalOpen" type="cart" title="Ваша корзина">
        <Cart v-if="isProductsInCart" @onClick="closeModal('cart')" />
        <p v-else>Ваша корзина пуста</p>
        <template #actions>
          <router-link to="/checkout" v-if="isProductsInCart">
            <BaseButton
              variant="contained"
              mode="success"
              @click="closeModal('cart')"
              >Заказать</BaseButton
            >
          </router-link>
        </template>
      </BaseModal>
    </teleport>

    <!-- 🔹 Модальное окно лайков -->
    <teleport to="body">
      <BaseModal :isModalOpen="isLikesModalOpen" type="likes" title="Вам понравилось">
        <Likes v-if="isLikes" @onClick="closeModal('likes')" />
        <p v-else>Пока нет добавленных товаров</p>
      </BaseModal>
    </teleport>

    <!-- 🔹 Модальное окно профиля -->
    <teleport to="body">
      <BaseModal :isModalOpen="isProfileModalOpen" type="profile" title="Ваши данные">
        <Profile v-if="userData" />
        <p v-else>Обновление профиля...</p>
      </BaseModal>
    </teleport>

    <!-- 🔹 Контент страницы -->
    <router-view v-slot="{ Component }">
      <FadeTransition>
        <component :is="Component" />
      </FadeTransition>
    </router-view>
  </main>
</template>

<script setup>
import { getFromLocalStorage, setToLocalStorage } from "@/utils/helpers";
import BaseModal from "./UI/BaseModal.vue";
import Cart from "./Cart.vue";
import BaseButton from "./UI/Buttons/BaseButton.vue";
import Likes from "./Likes.vue";
import Profile from "./Profile.vue"; // Подключаем модалку профиля
import FadeTransition from "./UI/FadeTransition.vue";
import { computed, onMounted, watch } from "@vue/runtime-core";
import { useProductStore } from "@/store/useProductStore";
import { storeToRefs } from "pinia";
import { useCartStore } from "@/store/useCartStore";
import { useLikeStore } from "@/store/useLikeStore";
import { useCommonStore } from "@/store/useCommonStore";
import { useProfileStore } from "@/store/useProfileStore"; // Подключаем профиль

const productStore = useProductStore();
const cartStore = useCartStore();
const likeStore = useLikeStore();
const commonStore = useCommonStore();
const profileStore = useProfileStore(); // Подключаем стор профиля
const { isProfileModalOpen } = storeToRefs(commonStore); 

const { products, isProducts } = storeToRefs(productStore);
const { isCartModalOpen, isLikesModalOpen } = storeToRefs(commonStore);
const { isLikes, likes } = storeToRefs(likeStore);
const { isProductsInCart, cart } = storeToRefs(cartStore);
const { userData } = storeToRefs(profileStore); // Данные профиля

const closeModal = (modal) => {
  commonStore.closeModal(modal);
};

watch(
  () => cart.value,
  () => {
    setToLocalStorage("cart", cart.value);
  },
  { deep: true }
);

watch(
  () => likes.value,
  () => {
    setToLocalStorage("likes", likes.value);
  },
  { deep: true }
);

onMounted(() => {
  const localStorageCart = getFromLocalStorage("cart");
  const localStorageLikes = getFromLocalStorage("likes");

  if (localStorageCart && !isProductsInCart.value) {
    cartStore.setProductsFromLStoCart(localStorageCart);
  }

  if (localStorageLikes && !isLikes.value) {
    likeStore.setLikesFromLS(localStorageLikes);
  }

  if (!isProducts.value) {
    productStore.fetchProducts();
  }

  if (!userData.value) {
    profileStore.fetchProfile(); // Загружаем данные профиля при монтировании
  }
});
</script>

<style scoped>
.main {
  padding: 20px 0 40px;
}
</style>
