<template>
  <div class="home-page">

    <!-- Слайдер новостей -->
    <section class="news-slider">
      <n-carousel autoplay :show-arrow="false" :interval="3000">
        <n-carousel-item v-for="(news, idx) in newsList" :key="news.id || idx">
          <div class="news-slide" @click="goToNewsDetail(news.id)">
            <img v-if="news.image?.link" :src="news.image.link" alt="Новость" class="news-image" />
          </div>
        </n-carousel-item>
      </n-carousel>
    </section>

    <!-- Каталог -->
    <Catalog />
  </div>
</template>

<script setup>
import { NCarousel, NCarouselItem } from "naive-ui";
import Catalog from "@/components/Catalog.vue";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import api from "@/api";

const router = useRouter();
const newsList = ref([]);

// Получение новостей из API
const fetchNews = async () => {
  try {
    const response = await api.get("/api/client/news");
    newsList.value = response.data.content;
  } catch (error) {
   // console.error("Ошибка при загрузке новостей:", error);
  }
};

// Переход на страницу подробностей новости
const goToNewsDetail = (id) => {
  router.push(`/news/${id}`);
};

// Загрузка новостей при монтировании
onMounted(() => {
  fetchNews();
});
</script>

<style scoped>

.home-page {
  width: 100%;
  max-width: 100%;
  padding: 0; 
  display: flex;
  flex-direction: column;
  align-items: center; 
}

/* Новости */
.news-slider {
  width: 100%;
  max-width: 100%;
}

.news-slide {
  width: 100%;
  position: relative;
  cursor: pointer;
}

/* Изображения новостей */
.news-image {
  width: 100%;
  height: auto;
  display: block;
}

/* Стили для веб-версии */
@media (min-width: 768px) {
  .news-slider {
    height: 50vh;
    overflow: hidden;
  }

  .news-slide {
    height: 50vh;
  }

  .news-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>