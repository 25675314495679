<template>
    <div class="order-confirmation-page">
        <div class="order-header">
            <h1>Оформление заказа</h1>
        </div>

        <!-- Основной контейнер с товарами и формой -->
        <div class="order-container">
            <!-- Левая колонка для списка товаров (на десктопе) -->
            <div class="products-column">
                <!-- Блок с товарами (сворачиваемый на мобильных устройствах) -->
                <div class="collapse-section">
                    <div 
                        class="collapse-header" 
                        @click="toggleProductList"
                        :class="{'mobile-toggle': isMobile}"
                    >
                        <div class="collapse-title">
                            <span>{{ cartItems.length }} товаров</span>
                        </div>
                        <div class="collapse-value">
                            <span>{{ formatPrice(totalAmount) }}</span>
                        </div>
                        <div v-if="isMobile" class="collapse-icon">
                            <span>{{ showProductList ? '▲' : '▼' }}</span>
                        </div>
                    </div>
                    <div v-if="showProductList || !isMobile" class="collapse-content product-list">
                        <div 
                            v-for="item in cartItems" 
                            :key="item.id" 
                            class="product-item"
                        >
                            <div class="product-image">
                                <img :src="item.image || NoImage" :alt="item.title || 'Изображение товара'" />
                            </div>
                            <div class="product-details">
                                <span class="product-title">{{ item.title }}</span>
                                <div class="product-pricing">
                                    <!-- Цена для ОПТОВОГО клиента -->
                                    <div v-if="isWholesaleClient" class="price-per-unit">
                                        <!-- Если есть скидка на оптовую цену -->
                                        <template v-if="isTradeSalePrice(item)">
                                            <span class="sale-price wholesale-price">{{ formatPrice(item.sale_trade_price).replace(' ₸', '') }} ₸/шт</span>
                                            <span class="original-price">{{ formatPrice(item.tradePrice).replace(' ₸', '') }} ₸/шт</span>
                                        </template>
                                        <!-- Если скидки на оптовую цену нет -->
                                        <template v-else>
                                            <span class="regular-price wholesale-price">{{ formatPrice(item.tradePrice).replace(' ₸', '') }} ₸/шт</span>
                                        </template>
                                    </div>
                                    
                                    <!-- Цена для РОЗНИЧНОГО клиента -->
                                    <div v-else class="price-per-unit">
                                        <!-- Если есть скидка на розничную цену -->
                                        <template v-if="isRetailSalePrice(item)">
                                            <span class="sale-price">{{ formatPrice(item.sale_retail_price).replace(' ₸', '') }} ₸/шт</span>
                                            <span class="original-price">{{ formatPrice(item.retailPrice).replace(' ₸', '') }} ₸/шт</span>
                                        </template>
                                        <!-- Если скидки на розничную цену нет -->
                                        <template v-else>
                                            <span class="regular-price">{{ formatPrice(item.retailPrice || item.price).replace(' ₸', '') }} ₸/шт</span>
                                        </template>
                                    </div>
                                    
                                    <!-- Количество и итоговая цена в одной строке -->
                                    <div class="quantity-total">
                                        <span>{{ item.quantity }} × {{ formatPrice(getProductPrice(item)).replace(' ₸', '') }} ₸ = {{ formatPrice(getProductPrice(item) * item.quantity) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <!-- Итого и экономия для мобильной версии -->
                <div class="mobile-summary">
                    <div class="summary-box">
                        <div v-if="hasEconomy" class="summary-row">
                            <span class="summary-label">Экономия</span>
                            <span class="economy-value">{{ formatPrice(economyAmount) }}</span>
                        </div>
                        <div class="summary-row total-row">
                            <span class="summary-label">Итого:</span>
                            <span class="total-amount">{{ formatPrice(totalAmount) }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Правая колонка для формы заказа -->
            <div class="order-form-column">
                <!-- Лоадер -->
                <div v-if="isLoading" class="loader">
                    <div class="spinner"></div>
                    <span>Загрузка данных...</span>
                </div>
                
                <!-- Сообщение об ошибке -->
                <div v-if="error" class="error-message">
                    <i class="error-icon">❌</i>
                    <span>Ошибка загрузки данных клиента</span>
                </div>

                <div v-if="!isLoading && userData" class="order-sections">
                    <!-- 1. Покупатель -->
                    <div class="collapse-section">
                        <div 
                            class="collapse-header" 
                            @click="isMobile ? toggleCustomerInfo() : null"
                            :class="{'mobile-toggle': isMobile}"
                        >
                            <div class="collapse-title">
                                <span class="section-number">1.</span>
                                <span>Покупатель</span>
                            </div>
                            <div class="collapse-value">
                                <span>{{ userData.phoneNumber }}</span>
                            </div>
                            <div v-if="isMobile" class="collapse-icon">
                                <span>{{ showCustomerInfo ? '▲' : '▼' }}</span>
                            </div>
                        </div>
                        <div v-if="showCustomerInfo || !isMobile" class="collapse-content">
                            <div class="customer-info">
                                <div class="info-row">
                                    <span class="info-label">Имя:</span>
                                    <span class="info-value">{{ userData.firstName }}</span>
                                </div>
                                <div class="info-row">
                                    <span class="info-label">Телефон:</span>
                                    <span class="info-value">{{ userData.phoneNumber }}</span>
                                </div>
                                <div class="info-row">
                                    <span class="info-label">WhatsApp:</span>
                                    <span v-if="userData.additionalPhoneNumber" class="info-value">
                                        {{ userData.additionalPhoneNumber }}
                                    </span>
                                    <button v-else class="add-btn" @click="openPhoneModal">
                                        <i class="add-icon">+</i> Добавить номер
                                    </button>
                                </div>
                                <!-- Кнопка изменить справа -->
                                <button class="edit-btn" @click="openProfileModal">
                                    <i class="edit-icon">✎</i> Изменить
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- 2. Способ получения -->
                    <div class="collapse-section">
                        <div 
                            class="collapse-header" 
                            @click="isMobile ? toggleDeliveryInfo() : null"
                            :class="{'mobile-toggle': isMobile}"
                        >
                            <div class="collapse-title">
                                <span class="section-number">2.</span>
                                <span>Способ получения г. Алматы</span>
                            </div>
                            <div class="collapse-value">
                                <span v-if="selectedDeliveryType && getSelectedDeliveryName()">
                                    {{ getSelectedDeliveryName() }}
                                </span>
                            </div>
                            <div v-if="isMobile" class="collapse-icon">
                                <span>{{ showDeliveryInfo ? '▲' : '▼' }}</span>
                            </div>
                        </div>
                        <div v-if="showDeliveryInfo || !isMobile" class="collapse-content">
                            <div class="delivery-options">
                                <!-- Десктопная версия - кнопки -->
                                <div v-if="!isMobile" class="delivery-type-selector">
                                    <div 
                                        :class="['delivery-option', { 'active': selectedDeliveryType === option.id }]" 
                                        v-for="option in deliveryOptions" 
                                        :key="option.id" 
                                        @click="selectDeliveryType(option)"
                                    >
                                        {{ option.name }}
                                    </div>
                                </div>
                                
                                <!-- Мобильная версия - выпадающий список -->
                                <div v-else class="delivery-select-container">
                                    <label class="delivery-label">Способ доставки:</label>
                                    <select v-model="selectedDeliveryType" @change="onDeliveryTypeChange" class="delivery-select">
                                        <option 
                                            v-for="option in deliveryOptions" 
                                            :key="option.id" 
                                            :value="option.id"
                                        >
                                            {{ option.name }}
                                        </option>
                                    </select>
                                </div>
                                
                                <div v-if="showAddressSelector" class="address-selector">
                                    <div v-if="addresses.length">
                                        <label class="address-label">Адрес доставки:</label>
                                        <select v-model="selectedAddress" class="address-select">
                                            <option 
                                                v-for="addr in addresses" 
                                                :key="addr.id" 
                                                :value="addr.id"
                                            >
                                                {{ addr.address?.address }}, 
                                                {{ addr.address?.city?.name }}, 
                                                {{ addr.address?.country?.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <button v-else class="add-address-btn" @click="openAddressModal">
                                        <i class="add-icon">+</i> Добавить адрес
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 3. Способ оплаты -->
                    <div class="collapse-section">
                        <div 
                            class="collapse-header" 
                            @click="isMobile ? togglePaymentInfo() : null"
                            :class="{'mobile-toggle': isMobile}"
                        >
                            <div class="collapse-title">
                                <span class="section-number">3.</span>
                                <span>Способ оплаты</span>
                            </div>
                            <div class="collapse-value">
                                <span>Kaspi</span>
                            </div>
                            <div v-if="isMobile" class="collapse-icon">
                                <span>{{ showPaymentInfo ? '▲' : '▼' }}</span>
                            </div>
                        </div>
                        <div v-if="showPaymentInfo || !isMobile" class="collapse-content">
                            <div class="payment-section">
                                <a :href="paymentLink" target="_blank" class="kaspi-payment-link">
                                    <img src="@/assets/kaspi-icon.png" alt="Kaspi" class="kaspi-icon" />
                                    <span>Оплата через Kaspi</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <!-- Итого и экономия для десктопной версии -->
                    <div class="desktop-summary">
                        <div class="summary-box">
                            <div v-if="hasEconomy" class="summary-row">
                                <span class="summary-label">Экономия</span>
                                <span class="economy-value">{{ formatPrice(economyAmount) }}</span>
                            </div>
                            <div class="summary-row total-row">
                                <span class="summary-label">Итого:</span>
                                <span class="total-amount">{{ formatPrice(totalAmount) }}</span>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Кнопка оформления заказа -->
                    <button 
                        class="confirm-order-btn" 
                        @click="confirmOrder"
                        :disabled="!canConfirmOrder"
                    >
                        Оформить заказ
                    </button>
                </div>
            </div>
        </div>

        <!-- Модальное окно для добавления адреса -->
        <div v-if="isAddressModalOpen" class="modal">
            <div class="modal-content">
                <h2>Добавить адрес</h2>
                
                <label>Адрес</label>
                <input v-model="newAddress.address" class="profile-input" />

                <label>Страна</label>
                <select v-model="newAddress.country.id" class="profile-input" @change="handleCountryChange">
                    <option v-for="country in availableCountries" :key="country.id" :value="country.id">
                        {{ country.name }}
                    </option>
                </select>

                <label>Город</label>
                <select class="profile-input" v-model="newAddress.city.id">
                    <option v-for="city in availableCities" :key="city.id" :value="city.id">
                        {{ city.name }}
                    </option>
                </select>

                <div class="modal-buttons">
                    <button @click="addNewAddress">Добавить</button>
                    <button @click="isAddressModalOpen = false">Отмена</button>
                </div>
            </div>
        </div>

        <!-- Модальное окно для редактирования профиля -->
        <div v-if="isProfileModalOpen" class="modal">
            <div class="modal-content">
                <h2>Редактирование профиля</h2>
                
                <label>Имя</label>
                <input v-model="editedProfile.firstName" class="profile-input" />
                
                <label>Email</label>
                <input v-model="editedProfile.email" class="profile-input" type="email" />
                
                <div v-if="editedProfile.additionalPhoneNumber" class="form-group">
                    <label>Дополнительный номер</label>
                    <input v-model="editedProfile.additionalPhoneNumber" class="profile-input" />
                </div>

                <div class="modal-buttons">
                    <button @click="updateProfile">Сохранить</button>
                    <button @click="isProfileModalOpen = false">Отмена</button>
                </div>
            </div>
        </div>

        <!-- Модальное окно для добавления номера телефона -->
        <div v-if="isPhoneModalOpen" class="modal">
            <div class="modal-content">
                <h2>Добавить номер WhatsApp</h2>
                
                <label>Номер телефона</label>
                <input v-model="additionalPhone" class="profile-input" placeholder="+7 (___) ___-__-__" />

                <div class="modal-buttons">
                    <button @click="addAdditionalPhone">Добавить</button>
                    <button @click="isPhoneModalOpen = false">Отмена</button>
                </div>
            </div>
        </div>

        <!-- Сообщение об успешном сохранении -->
        <div v-if="isSaved" class="success-notification">
            ✅ Данные успешно сохранены
        </div>
    </div>
</template>
<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useCartStore } from "@/store/useCartStore";
import { usePendingOrderStore } from "@/store/usePendingOrderStore";
import api from "@/api";
import NoImage from "@/assets/icon.png";

const router = useRouter();
const route = useRoute();
const cartStore = useCartStore();

const userData = ref(null);
const addresses = ref([]);
const selectedAddress = ref(null);
const isLoading = ref(true);
const error = ref(false);
const selectedDeliveryType = ref(null);
const deliveryOptions = ref([]);
const isMobile = ref(false);

// Состояние развернутости секций (для мобильной версии)
const showProductList = ref(true);
const showCustomerInfo = ref(true);
const showDeliveryInfo = ref(true);
const showPaymentInfo = ref(true);

// Параметры для работы с адресами и профилем
const isAddressModalOpen = ref(false);
const isProfileModalOpen = ref(false);
const isPhoneModalOpen = ref(false);
const newAddress = ref({ address: "", country: { id: 1 }, city: { id: 1 } });
const availableCountries = ref([
    { id: 1, name: "Казахстан" },
    { id: 2, name: "Другое" }
]);
const availableCities = ref([]);
const editedProfile = ref({
    firstName: "",
    email: "",
    additionalPhoneNumber: ""
});
const additionalPhone = ref("");
const isSaved = ref(false);

const paymentLink = "https://pay.kaspi.kz/pay/a089c3id";

const cartItems = ref([]);

// Проверка, является ли клиент оптовым
const isWholesaleClient = computed(() => {
  return userData.value && userData.value.cod_type_client === 'trade';
});

// Проверка устройства
const checkDeviceType = () => {
    isMobile.value = window.innerWidth < 768;
};

// Получить название выбранного способа доставки
const getSelectedDeliveryName = () => {
    const selected = deliveryOptions.value.find(opt => opt.id === selectedDeliveryType.value);
    return selected ? selected.name : '';
};

// Выбор типа доставки с учетом кода
const selectDeliveryType = (option) => {
    selectedDeliveryType.value = option.id;
};

// Обработчик изменения в выпадающем списке доставки
const onDeliveryTypeChange = () => {
    // Ничего дополнительного не нужно делать, selectedDeliveryType уже обновлен
    // Но этот метод может понадобиться, если нужно что-то сделать при изменении
};

// Функции для переключения видимости секций (только для мобильной версии)
const toggleProductList = () => {
    if (isMobile.value) {
        showProductList.value = !showProductList.value;
    }
};

const toggleCustomerInfo = () => {
    showCustomerInfo.value = !showCustomerInfo.value;
};

const toggleDeliveryInfo = () => {
    showDeliveryInfo.value = !showDeliveryInfo.value;
};

const togglePaymentInfo = () => {
    showPaymentInfo.value = !showPaymentInfo.value;
};

// Проверка наличия акционной розничной цены
const isRetailSalePrice = (item) => {
  return item.sale_retail_price && 
         Number(item.sale_retail_price) > 0 && 
         Number(item.sale_retail_price) < Number(item.retailPrice || item.price || 0);
};

// Проверка наличия акционной оптовой цены
const isTradeSalePrice = (item) => {
  return item.sale_trade_price && 
         Number(item.sale_trade_price) > 0 && 
         Number(item.sale_trade_price) < Number(item.tradePrice || 0);
};

// Получение актуальной цены товара в зависимости от типа клиента
const getProductPrice = (item) => {
  if (isWholesaleClient.value) {
    // Для оптового клиента
    if (isTradeSalePrice(item)) {
      return Number(item.sale_trade_price);
    }
    return Number(item.tradePrice || 0);
  } else {
    // Для розничного клиента
    if (isRetailSalePrice(item)) {
      return Number(item.sale_retail_price);
    }
    return Number(item.retailPrice || item.price || 0);
  }
};

// Обновленный расчет общей суммы
const totalAmount = computed(() => 
  cartItems.value.reduce((sum, item) => {
    return sum + getProductPrice(item) * item.quantity;
  }, 0).toFixed(0)
);

// Обновленный расчет экономии
const economyAmount = computed(() => {
  return cartItems.value.reduce((sum, item) => {
    if (isWholesaleClient.value) {
      // Для оптового клиента
      if (isTradeSalePrice(item)) {
        const savedPerItem = Number(item.tradePrice) - Number(item.sale_trade_price);
        return sum + (savedPerItem * item.quantity);
      }
    } else {
      // Для розничного клиента
      if (isRetailSalePrice(item)) {
        const savedPerItem = Number(item.retailPrice) - Number(item.sale_retail_price);
        return sum + (savedPerItem * item.quantity);
      }
    }
    return sum;
  }, 0).toFixed(0);
});

// Проверка, есть ли экономия
const hasEconomy = computed(() => Number(economyAmount.value) > 0);

// Отображать селектор адреса только если выбрана НЕ самовывоз
const showAddressSelector = computed(() => {
    // Найдем выбранный тип доставки
    const selectedOption = deliveryOptions.value.find(opt => opt.id === selectedDeliveryType.value);
    // Проверяем, что это не самовывоз (PICKUP)
    return selectedOption && selectedOption.code !== 'PICKUP';
});

const canConfirmOrder = computed(() => 
    (showAddressSelector.value ? selectedAddress.value : true) && 
    userData.value?.phoneNumber && 
    selectedDeliveryType.value && 
    cartItems.value.length > 0
);

// Функции для открытия модальных окон
const openProfileModal = () => {
    editedProfile.value = {
        firstName: userData.value.firstName || "",
        email: userData.value.email || "",
        additionalPhoneNumber: userData.value.additionalPhoneNumber || ""
    };
    isProfileModalOpen.value = true;
};

const openPhoneModal = () => {
    additionalPhone.value = "";
    isPhoneModalOpen.value = true;
};

// Функция открытия модального окна для добавления адреса
const openAddressModal = () => {
    isAddressModalOpen.value = true;
    fetchCities(); // Загружаем города при открытии модального окна
};

// Загрузка городов по стране
const fetchCities = async () => {
    try {
        if (newAddress.value.country.id !== 1) {
            availableCities.value = [];
            newAddress.value.city.id = 22; // Если выбрана другая страна, автоматически устанавливаем город "Другое"
            return;
        }
        
        const response = await api.get("/api/refs/city?countryId=1");
        availableCities.value = response.data;

        // Устанавливаем город по умолчанию после загрузки списка
        if (availableCities.value.length > 0) {
            newAddress.value.city.id = availableCities.value[0].id;
        }
    } catch (err) {
        //console.error("❌ Ошибка загрузки городов:", err);
    }
};

// Обработка выбора страны
const handleCountryChange = () => {
    fetchCities(); // Загружаем список городов при изменении страны
};

// Добавление нового адреса
const addNewAddress = async () => {
    try {
        const response = await api.post("/api/client/address", newAddress.value);
        
        // Обновляем список адресов после добавления
        await fetchAddresses();
        
        // Выбираем только что добавленный адрес
        if (response.data && response.data.id) {
            selectedAddress.value = response.data.id;
        }
        
        isAddressModalOpen.value = false;
        
        // Показываем сообщение об успешном сохранении
        isSaved.value = true;
        setTimeout(() => { isSaved.value = false; }, 3000);
    } catch (err) {
       // console.error("❌ Ошибка при добавлении адреса:", err);
        alert("❌ Ошибка при добавлении адреса");
    }
};

// Обновление профиля
const updateProfile = async () => {
    try {
        const payload = {
            ...userData.value,
            firstName: editedProfile.value.firstName,
            email: editedProfile.value.email,
            additionalPhoneNumber: editedProfile.value.additionalPhoneNumber
        };
        
        await api.put("/api/clients/me", payload);
        
        // Обновляем локальные данные
        userData.value.firstName = editedProfile.value.firstName;
        userData.value.email = editedProfile.value.email;
        userData.value.additionalPhoneNumber = editedProfile.value.additionalPhoneNumber;
        
        isProfileModalOpen.value = false;
        
        // Показываем сообщение об успешном сохранении
        isSaved.value = true;
        setTimeout(() => { isSaved.value = false; }, 3000);
    } catch (err) {
       // console.error("❌ Ошибка при обновлении профиля:", err);
        alert("❌ Ошибка при обновлении профиля");
    }
};

// Добавление дополнительного номера телефона
const addAdditionalPhone = async () => {
    try {
        const payload = {
            ...userData.value,
            additionalPhoneNumber: additionalPhone.value
        };
        
        await api.put("/api/clients/me", payload);
        
        // Обновляем локальные данные
        userData.value.additionalPhoneNumber = additionalPhone.value;
        
        isPhoneModalOpen.value = false;
        
        // Показываем сообщение об успешном сохранении
        isSaved.value = true;
        setTimeout(() => { isSaved.value = false; }, 3000);
    } catch (err) {
        //console.error("❌ Ошибка при добавлении номера телефона:", err);
       // alert("❌ Ошибка при добавлении номера телефона");
    }
};

// Загрузка данных клиента
const fetchClientData = async () => {
    try {
        const response = await api.get("/api/clients/me");
        userData.value = response.data;
        
        // Сохраняем данные о типе клиента для последующих расчетов
        //console.log("Тип клиента:", userData.value.cod_type_client);
    } catch (err) {
       // console.error("❌ Ошибка загрузки профиля:", err);
        error.value = true;
    } finally {
        isLoading.value = false;
    }
};

// Загрузка адресов
const fetchAddresses = async () => {
    try {
        const response = await api.get("/api/client/address");
        addresses.value = Array.isArray(response.data) ? response.data : [];
        selectedAddress.value = addresses.value.length 
            ? addresses.value.find(a => a.isMain)?.id || addresses.value[0].id 
            : null;
    } catch (err) {
       // console.error("❌ Ошибка загрузки адресов:", err);
    }
};

// ✅ Получение справочников доставки
const fetchReferences = async () => {
    try {
        const deliveryResponse = await api.get("/api/refs?type=ORDER_DELIVERY_TYPE");
        deliveryOptions.value = deliveryResponse.data;
        selectedDeliveryType.value = deliveryOptions.value.length ? deliveryOptions.value[0].id : null;
    } catch (err) {
       // console.error("❌ Ошибка загрузки справочников:", err);
        // Запасные значения на случай ошибки API
        deliveryOptions.value = [
            { id: 1, name: 'Доставка', code: 'DELIVERY', price: 0 },
            { id: 2, name: 'Самовывоз', code: 'PICKUP', price: 0 },
            { id: 3, name: 'Грузоперевозка', code: 'SHIPPING', price: 1500 }
        ];
        selectedDeliveryType.value = 1;
    }
};

// Загрузка товаров из URL или localStorage
const fetchCartItems = () => {
    const queryItems = route.query.items;
    
    if (queryItems) {
        try {
            const decodedItems = JSON.parse(decodeURIComponent(queryItems));
            
            // Проверяем, что данные имеют все необходимые свойства
            cartItems.value = decodedItems.map(item => ({
                ...item,
                // Если retailPrice отсутствует, но есть price, используем price в качестве retailPrice
                retailPrice: item.retailPrice || item.price || 0,
                // Сохраняем информацию о sale_retail_price, если она есть
                sale_retail_price: item.sale_retail_price || null,
                // Сохраняем информацию об оптовых ценах
                tradePrice: item.tradePrice || 0,
                sale_trade_price: item.sale_trade_price || null,
                // Гарантируем, что у нас есть свойство image
                image: item.image || NoImage
            }));
            
        } catch (e) {
            //console.error("❌ Ошибка при разборе товаров из запроса:", e);
            cartItems.value = [];
        }
    } else {
        //console.warn("⚠️ `query.items` пустой, пробуем загрузить из localStorage...");
        const storedOrder = localStorage.getItem("pendingOrder");
        if (storedOrder) {
            try {
                const decodedItems = JSON.parse(storedOrder);
                cartItems.value = decodedItems.map(item => ({
                    ...item,
                    retailPrice: item.retailPrice || item.price || 0,
                    sale_retail_price: item.sale_retail_price || null,
                    tradePrice: item.tradePrice || 0,
                    sale_trade_price: item.sale_trade_price || null,
                    image: item.image || NoImage
                }));
            } catch (e) {
               // console.error("❌ Ошибка при разборе товаров из localStorage:", e);
                cartItems.value = [];
            }
        } else {
            //console.warn("❌ В localStorage тоже нет товаров.");
            cartItems.value = [];
        }
    }
};

// Подтверждение заказа
const confirmOrder = async () => {
    const authToken = localStorage.getItem("authToken");
    if (!authToken) {
        alert("❌ Ошибка: Отсутствует токен авторизации!");
        return;
    }

    const payload = {
        additionalPhoneNumber: userData.value.additionalPhoneNumber,
        clientAddress: showAddressSelector.value ? { id: selectedAddress.value } : null,
        clientDeliveryType: { id: selectedDeliveryType.value },
        noProductCaseOption: { id: 13 }, // Фиксированное значение 13
        orderItemList: cartItems.value.map(item => ({ 
            productId: item.id, 
            quantity: item.quantity 
        })),
        totalAmount: totalAmount.value
    };

    try {
        const response = await api.post("/api/client/orders", payload, {
            headers: { Authorization: `Bearer ${authToken}` }
        });

        if (response.status === 200 || response.status === 201) {
            alert("✅ Ваш заказ сформирован!");
            localStorage.removeItem("pendingOrder");
            localStorage.removeItem("pendingOrderExpiry");
            router.push("/orders");
        } else {
            alert(`❌ Ошибка: ${response.status} - ${response.statusText}`);
        }
    } catch (error) {
        //console.error("❌ Ошибка при подтверждении заказа:", error);
        alert("❌ Ошибка при оформлении заказа!");
    }
};

// Добавляем функцию форматирования цены
const formatPrice = (price) => {
  if (!price && price !== 0) return "0 ₸";
  return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₸`;
};

// Инициализация при монтировании компонента
onMounted(async () => {
    checkDeviceType();
    window.addEventListener('resize', checkDeviceType);
    fetchCartItems();
    await fetchClientData();
    await fetchAddresses();
    await fetchReferences();
});
</script>

<style scoped>
.order-confirmation-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background-color: #f9f9f9;
}

.order-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.order-header h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    color: #333;
    text-align: left;
    position: relative;
}

.order-header h1:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 50px;
    height: 3px;
    background-color: #F15E24;
    border-radius: 3px;
}

/* Основной контейнер с адаптивной версткой */
.order-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Стили для сворачиваемых секций */
.collapse-section {
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    overflow: hidden;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
}

.collapse-header {
    display: flex;
    align-items: center;
    padding: 16px;
    background-color: #f5f5f5;
    font-weight: 500;
}

.mobile-toggle {
    cursor: pointer;
}

.collapse-title {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 500;
}

.collapse-value {
    margin-right: 15px;
    font-weight: 500;
    color: #333;
    text-align: right;
}

.collapse-icon {
    width: 24px;
    text-align: center;
    color: #999;
    font-size: 12px;
}

.collapse-content {
    padding: 16px;
    border-top: 1px solid #eaeaea;
}

.section-number {
    color: #F15E24;
    font-weight: 600;
    margin-right: 5px;
}

/* Стили для итого и экономии */
.summary-box {
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
}

.summary-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

.summary-row:not(:last-child) {
    border-bottom: 1px dashed #eee;
    padding-bottom: 12px;
    margin-bottom: 8px;
}

.summary-label {
    font-weight: 500;
    color: #555;
}

.economy-value {
    color: #4CAF50;
    font-weight: 600;
    font-size: 18px;
}

.total-row {
    font-size: 18px;
    font-weight: 600;
}

.total-amount {
    color: #F15E24;
    font-size: 20px;
}

/* Стили для списка товаров */
.product-list {
    max-height: 500px;
    overflow-y: auto;
}

.product-item {
    display: flex;
    align-items: flex-start;
    padding: 15px;
    border-bottom: 1px solid #f0f0f0;
}

.product-item:last-child {
    border-bottom: none;
}

.product-image {
    flex: 0 0 100px;
    height: 100px;
    margin-right: 15px;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f9f9f9;
    border: 1px solid #eee;
}

.product-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
}

.product-details {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.product-title {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 14px;
    color: #333;
    line-height: 1.3;
}

.product-pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.price-per-unit {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    flex-wrap: wrap;
}

/* Стиль для оптовых цен */
.wholesale-price {
    color: #3498db !important; /* Синий цвет для оптовых цен */
}

.sale-price {
    color: #F15E24;
    font-weight: bold;
    font-size: 16px;
}

.original-price {
    color: #777;
    text-decoration: line-through;
    font-size: 12px;
}

.regular-price {
    font-weight: 600;
    font-size: 16px;
    color: #333;
}

.quantity-total {
    margin-top: 5px;
    font-size: 14px;
    color: #555;
    background-color: #f9f9f9;
    padding: 4px 10px;
    border-radius: 4px;
}

/* Стили для информации о клиенте */
.customer-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.info-row {
    display: flex;
    align-items: center;
}

.info-label {
    width: 100px;
    font-weight: 500;
    color: #666;
}

.info-value {
    font-weight: 500;
    color: #333;
}

.edit-btn {
    align-self: flex-end;
    margin-top: 10px;
}

/* Стили для выбора способа доставки */
.delivery-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Стили для кнопок доставки (десктоп) */
.delivery-type-selector {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.delivery-option {
    padding: 12px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 14px;
    text-align: center;
}

.delivery-option.active {
    background-color: #F15E24;
    color: white;
    border-color: #F15E24;
    font-weight: 500;
}

/* Стили для выпадающего списка доставки (мобильный) */
.delivery-select-container {
    margin-bottom: 15px;
}

.delivery-label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #555;
}

.delivery-select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    color: #333;
    background-color: #f9f9f9;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
}

/* Стили для адреса доставки */
.address-selector {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px dashed #eee;
}

.address-label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
    color: #555;
}

.address-select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    color: #333;
    background-color: #f9f9f9;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 12px center;
    background-size: 16px;
}

/* Стили для способа оплаты Kaspi */
.kaspi-payment-link {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    text-decoration: none;
    color: #333;
    font-weight: 500;
    transition: all 0.2s;
}

.kaspi-payment-link:hover {
    background-color: #fff9f7;
    border-color: #F15E24;
}

.kaspi-icon {
    width: 36px;
    height: 36px;
    object-fit: contain;
}

/* Стили для кнопок */
.add-btn, 
.edit-btn, 
.add-address-btn {
    padding: 6px 12px;
    border: none;
    border-radius: 6px;
    background-color: #F15E24;
    color: white;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
    transition: background-color 0.2s;
}

.add-btn:hover, 
.edit-btn:hover, 
.add-address-btn:hover {
    background-color: #E64A19;
}

.edit-icon, 
.add-icon {
    font-style: normal;
    font-weight: bold;
}

.confirm-order-btn {
    width: 100%;
    padding: 16px;
    background-color: #F15E24;
    color: white;
    border: none;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 2px 8px rgba(241, 94, 36, 0.2);
    transition: all 0.2s;
}

.confirm-order-btn:hover {
    background-color: #E64A19;
}

.confirm-order-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
    box-shadow: none;
}

/* Стили для загрузчика */
.loader {
    padding: 25px;
    text-align: center;
    color: #666;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.spinner {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #F15E24;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.error-message {
    padding: 15px;
    background-color: #FFEBEE;
    color: #D32F2F;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.error-icon {
    font-style: normal;
}

/* Стили для модального окна */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
    margin-bottom: 15px;
    font-size: 18px;
    color: #333;
    font-weight: 600;
}

.modal-content label {
    display: block;
    margin: 10px 0 5px;
    font-weight: 500;
    color: #555;
}

.profile-input {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    background-color: white;
    margin-bottom: 10px;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 10px;
}

.modal-buttons button {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
}

.modal-buttons button:first-child {
    background: #F15E24;
    color: white;
}

.modal-buttons button:last-child {
    background: #eee;
    color: #333;
}

/* Уведомление об успешном сохранении */
.success-notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #4CAF50;
    color: white;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    animation: fadeInOut 3s;
}

@keyframes fadeInOut {
    0% { opacity: 0; transform: translateY(-20px); }
    10% { opacity: 1; transform: translateY(0); }
    90% { opacity: 1; }
    100% { opacity: 0; }
}

/* Адаптивные стили - десктопный вид */
@media (min-width: 768px) {
    .order-container {
        flex-direction: row;
        align-items: flex-start;
    }
    
    .products-column {
        width: 40%;
        margin-right: 20px;
    }
    
    .order-form-column {
        width: 60%;
    }
    
    .mobile-summary {
        display: none;
    }

    .desktop-summary {
        display: block;
    }
    
    .delivery-option {
        flex: 0 0 auto;
        min-width: 120px;
    }
}

/* Адаптивные стили - мобильный вид */
@media (max-width: 767px) {
    .order-confirmation-page {
        padding: 15px;
    }
    
    .desktop-summary {
        display: none;
    }
    
    .mobile-summary {
        display: block;
    }
    
    .modal-content {
        width: 95%;
        padding: 15px;
    }
}
</style>