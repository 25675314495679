<template>
    <div class="forgot-password-container">
      <img :src="Logo" alt="Логотип" class="logo" />
      
      <h2>Восстановление пароля</h2>
      <div class="steps-indicator">Шаг {{ step }} из 3</div>

      <!-- Шаг 1: Ввод телефона -->
      <div v-if="step === 1">
        <p class="description">Введите номер телефона, указанный при регистрации</p>
        <form @submit.prevent="requestResetCode">
          <div class="input-group">
            <label for="phone">Номер телефона</label>
            <vue-tel-input
              v-model="phoneNumber"
              v-bind="phoneOptions"
              @input="validatePhone"
              class="phone-input"
            />
            <p v-if="phoneError" class="error-message">❌ Неверный формат номера</p>
          </div>
          
          <button type="submit" :disabled="isLoading || phoneError">
            {{ isLoading ? "Отправка..." : "Получить код" }}
          </button>
        </form>
      </div>

      <!-- Шаг 2: Ввод кода подтверждения -->
      <div v-else-if="step === 2">
        <p class="description">Введите код подтверждения из SMS</p>
        <form @submit.prevent="verifyCode">
          <div class="input-group">
            <label for="code">Код из SMS</label>
            <input 
              type="text" 
              id="code" 
              v-model="verificationCode" 
              placeholder="Введите код" 
              required 
              maxlength="6"
              inputmode="numeric"
              pattern="[0-9]*"
            />
            <p v-if="countdown > 0" class="countdown">
              Повторная отправка через {{ countdown }} секунд
            </p>
            <p v-else class="resend" @click="resendCode">
              Отправить код повторно
            </p>
            <p class="telegram-support">
              Не приходит код? 
              <a href="https://t.me/tezal_support_bot" target="_blank" rel="noopener">
                Не получили SMS? Напишите нам в
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" style="display: inline-block; vertical-align: middle; margin-left: 4px;">
                  <path d="M22 2L11 13"></path>
                  <path d="M22 2L15 22L11 13L2 9L22 2Z"></path>
                </svg>
              </a>
            </p>
          </div>
          
          <button type="submit" :disabled="isLoading || !verificationCode">
            {{ isLoading ? "Проверка..." : "Подтвердить код" }}
          </button>
          
          <button type="button" class="back-button" @click="goBack">
            Назад
          </button>
        </form>
      </div>

      <!-- Шаг 3: Ввод нового пароля -->
      <div v-else-if="step === 3">
        <p class="description">Придумайте новый пароль</p>
        <form @submit.prevent="resetPassword">
          <div class="input-group password-group">
            <label for="password">Новый пароль</label>
            <input 
              :type="showPassword ? 'text' : 'password'" 
              id="password" 
              v-model="newPassword" 
              placeholder="Минимум 6 символов" 
              required 
              autocomplete="new-password"
            />
            <span class="toggle-password" @click="showPassword = !showPassword">
              <EyeOpenIcon v-if="showPassword" />
              <EyeClosedIcon v-else />
            </span>
          </div>
          
          <div class="input-group password-group">
            <label for="confirmPassword">Повторите пароль</label>
            <input 
              :type="showConfirmPassword ? 'text' : 'password'" 
              id="confirmPassword" 
              v-model="confirmPassword" 
              required 
              autocomplete="new-password"
            />
            <span class="toggle-password" @click="showConfirmPassword = !showConfirmPassword">
              <EyeOpenIcon v-if="showConfirmPassword" />
              <EyeClosedIcon v-else />
            </span>
          </div>
          
          <p v-if="passwordError" class="error-message">{{ passwordError }}</p>
          
          <button type="submit" :disabled="isLoading || !!passwordError">
            {{ isLoading ? "Сохранение..." : "Сохранить новый пароль" }}
          </button>
          
          <button type="button" class="back-button" @click="goBack">
            Назад
          </button>
        </form>
      </div>

      <p class="login-link">Вспомнили пароль? <router-link to="/login">Войти</router-link></p>
      
      <p v-if="errorMessage" class="error-message">
        <template v-if="showRegistrationLink">
          Аккаунт с таким номером телефона не найден. Пожалуйста, пройдите <router-link to="/register">регистрацию</router-link>.
        </template>
        <template v-else>
          {{ errorMessage }}
        </template>
      </p>
      <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
    </div>
</template>
  
<script setup>
import { ref, computed, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import api from "@/api";
import Logo from "@/assets/icon.png";
import EyeOpenIcon from "@/components/icons/EyeOpenIcon.vue";
import EyeClosedIcon from "@/components/icons/EyeClosedIcon.vue";
  
const router = useRouter();

// Состояние формы
const step = ref(1);
const phoneNumber = ref("");
const phoneError = ref(false);
const verificationCode = ref("");
const verificationToken = ref("");
const newPassword = ref("");
const confirmPassword = ref("");
const showPassword = ref(false);
const showConfirmPassword = ref(false);
const isLoading = ref(false);
const errorMessage = ref("");
const successMessage = ref("");
const countdown = ref(0);
const countdownInterval = ref(null);
const showRegistrationLink = ref(false);

// Опции для телефонного ввода
const phoneOptions = {
  mode: "international",
  autoFormat: true,
  placeholder: "Введите номер телефона",
  preferredCountries: ["ru", "kz", "kg"],
  onlyCountries: ["ru", "kz", "kg"],
  validCharactersOnly: true,
  maxLen: 16,
  inputOptions: {
    showDialCode: true,
  },
};

// Проверка совпадения паролей
const passwordError = computed(() => {
  if (!newPassword.value) {
    return "❌ Введите новый пароль";
  }
  
  if (newPassword.value.length < 8) {
    return "❌ Пароль должен содержать минимум 8 символов";
  }
  
  if (confirmPassword.value && newPassword.value !== confirmPassword.value) {
    return "❌ Пароли не совпадают";
  }
  
  if (!confirmPassword.value) {
    return "❌ Повторите пароль";
  }
  
  return "";
});

// Функция проверки номера телефона
const validatePhone = (phoneNumber) => {
  if (!phoneNumber) {
    phoneError.value = true;
    return;
  }

  // Приводим к строке, если передано число
  const phoneString = String(phoneNumber);

  // Очищаем номер от пробелов и скобок
  const cleanPhone = phoneString.replace(/\D/g, "");

  // Проверяем код страны и количество цифр
  if (cleanPhone.startsWith("7") && cleanPhone.length !== 11) {
    phoneError.value = true;
  } else if (cleanPhone.startsWith("996") && cleanPhone.length !== 12) {
    phoneError.value = true;
  } else {
    phoneError.value = false; // Всё в порядке
  }
};

// Запрос кода сброса пароля
const requestResetCode = async () => {
  if (phoneError.value || !phoneNumber.value) {
    errorMessage.value = "❌ Проверьте номер телефона";
    showRegistrationLink.value = false;
    return;
  }
  
  isLoading.value = true;
  errorMessage.value = "";
  showRegistrationLink.value = false;
  
  try {
    let formattedPhone = String(phoneNumber.value || "").replace(/\D/g, "");

    // Преобразуем номер, если начинается с 8 (для России/Казахстана)
    if (formattedPhone.startsWith("8") && formattedPhone.length === 11) {
      formattedPhone = "7" + formattedPhone.slice(1); // 8 → 7
    }
    
    await api.post("/auth/password/reset/request", {
      phone_number: formattedPhone
    });
    
    localStorage.setItem("resetPhoneNumber", formattedPhone);
    step.value = 2;
    startCountdown();
  } catch (error) {
    if (error.response?.data?.detail === "Телефон не привязан к аккаунту пользователя") {
      showRegistrationLink.value = true;
    } else {
      errorMessage.value = error.response?.data?.message || "❌ Ошибка отправки кода";
      showRegistrationLink.value = false;
    }
  } finally {
    isLoading.value = false;
  }
};

// Проверка кода подтверждения
const verifyCode = async () => {
  isLoading.value = true;
  errorMessage.value = "";
  
  try {
    const storedPhone = localStorage.getItem("resetPhoneNumber");
    
    const response = await api.post("/auth/password/reset/verify", {
      phone_number: storedPhone,
      verification_code: verificationCode.value
    });
    
    verificationToken.value = response.data.verification_token;
    step.value = 3;
  } catch (error) {
    errorMessage.value = error.response?.data?.message || "❌ Неверный код подтверждения";
  } finally {
    isLoading.value = false;
  }
};

// Установка нового пароля
const resetPassword = async () => {
  if (passwordError.value) return;
  
  isLoading.value = true;
  errorMessage.value = "";
  
  try {
    await api.post("/auth/password/reset/complete", {
      verification_token: verificationToken.value,
      new_password: newPassword.value
    });
    
    localStorage.removeItem("resetPhoneNumber");
    successMessage.value = "✅ Пароль успешно изменен!";
    
    setTimeout(() => {
      router.push("/login");
    }, 2000);
  } catch (error) {
    errorMessage.value = error.response?.data?.message || "❌ Ошибка сброса пароля";
  } finally {
    isLoading.value = false;
  }
};

// Управление таймером повторной отправки
const startCountdown = () => {
  countdown.value = 60;
  clearCountdownInterval();
  
  countdownInterval.value = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      clearCountdownInterval();
    }
  }, 1000);
};

const clearCountdownInterval = () => {
  if (countdownInterval.value) {
    clearInterval(countdownInterval.value);
    countdownInterval.value = null;
  }
};

// Повторная отправка кода
const resendCode = async () => {
  if (countdown.value > 0) return;
  
  isLoading.value = true;
  errorMessage.value = "";
  
  try {
    const storedPhone = localStorage.getItem("resetPhoneNumber");
    await requestResetCode();
  } catch (error) {
    errorMessage.value = "❌ Ошибка отправки кода";
  } finally {
    isLoading.value = false;
  }
};

// Возврат к предыдущему шагу
const goBack = () => {
  step.value--;
  errorMessage.value = "";
  
  if (step.value === 1) {
    clearCountdownInterval();
    localStorage.removeItem("resetPhoneNumber");
  }
};

// Очистка при размонтировании
onBeforeUnmount(() => {
  clearCountdownInterval();
});
</script>
  
<style scoped>
.forgot-password-container {
  max-width: 400px;
  width: 100%;
  margin: 40px auto;
  padding: 32px;
  background: #ffffff;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
}

.logo {
  width: 160px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.logo:hover {
  transform: translateY(-5px) scale(1.02);
  filter: drop-shadow(0 8px 12px rgba(241, 94, 36, 0.2));
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: translateY(-5px) scale(1.02);
  }
  50% {
    transform: translateY(-5px) scale(1.04);
  }
  100% {
    transform: translateY(-5px) scale(1.02);
  }
}

h2 {
  font-size: 28px;
  color: #222222;
  font-weight: 600;
  margin-bottom: 10px;
}

.steps-indicator {
  color: #666666;
  font-size: 14px;
  margin-bottom: 20px;
}

.description {
  font-size: 14px;
  color: #555555;
  margin-bottom: 25px;
  line-height: 1.4;
}

.input-group {
  text-align: left;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.input-group label {
  display: block;
  font-size: 14px;
  color: #333333;
  font-weight: 500;
  margin-bottom: 8px;
}

.input-group input {
  width: 100%;
  height: 48px;
  padding: 0 15px;
  font-size: 15px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background: #F8F8F8;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.input-group input:focus {
  outline: none;
  border-color: #F15E24;
  background: #ffffff;
  box-shadow: 0 0 0 3px rgba(241, 94, 36, 0.1);
}

/* Телефонный инпут */
:deep(.vue-tel-input) {
  width: 100% !important;
  height: 48px !important;
  border-radius: 8px !important;
  border: 1px solid #E0E0E0 !important;
  background: #F8F8F8 !important;
  box-sizing: border-box !important;
}

:deep(.vti__dropdown) {
  height: 46px !important;
  margin: 0 !important;
  padding: 0 0 0 8px !important;
  border-radius: 8px 0 0 8px !important;
  border-right: 1px solid #E0E0E0 !important;
  background: transparent !important;
}

:deep(.vti__input) {
  height: 46px !important;
  margin: 0 !important;
  padding: 0 15px !important;
  background: transparent !important;
  border-radius: 0 8px 8px 0 !important;
  font-size: 15px !important;
  color: #333333 !important;
  box-sizing: border-box !important;
}

/* Пароль */
.password-group input {
  padding-right: 45px;
}

.toggle-password {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 12px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.toggle-password:hover {
  opacity: 1;
}

.toggle-password :deep(svg) {
  width: 20px;
  height: 20px;
}

/* Таймер и повторная отправка */
.countdown {
  font-size: 13px;
  color: #666666;
  margin-top: 8px;
  text-align: center;
}

.resend {
  font-size: 13px;
  color: #F15E24;
  margin-top: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.resend:hover {
  color: #e04e16;
}

/* Кнопки */
button {
  width: 100%;
  height: 48px;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  background: #F15E24;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

button:hover {
  background: #e04e16;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(241, 94, 36, 0.2);
}

button:disabled {
  background: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.back-button {
  background: transparent;
  color: #333333;
  border: 1px solid #E0E0E0;
  margin-top: 12px;
}

.back-button:hover {
  background: #f5f5f5;
  transform: none;
  box-shadow: none;
}

/* Ссылки и сообщения */
.login-link {
  margin-top: 20px;
  font-size: 14px;
  color: #666666;
}

.login-link a {
  color: #F15E24;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.login-link a:hover {
  color: #e04e16;
}

.error-message {
  color: #e74c3c;
  font-size: 13px;
  margin-top: 6px;
  font-weight: 500;
}

.success-message {
  color: #2ecc71;
  font-size: 14px;
  margin-top: 10px;
  font-weight: 500;
}

/* Стили для ссылки на Telegram */
.telegram-link {
  margin-top: 12px;
  font-size: 13px;
  color: #666666;
  text-align: center;
}

.telegram-link a {
  color: #0088cc;
  text-decoration: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  transition: all 0.3s ease;
}

.telegram-link a:hover {
  color: #006699;
  text-decoration: underline;
}

.telegram-link svg {
  margin-left: 4px;
  transition: transform 0.3s ease;
}

.telegram-link a:hover svg {
  transform: translate(2px, -2px);
}

/* Адаптив */
@media (max-width: 480px) {
  .forgot-password-container {
    margin: 20px 16px;
    padding: 24px 16px;
    border-radius: 8px;
  }

  h2 {
    font-size: 24px;
  }

  button {
    height: 44px;
  }
}
</style>