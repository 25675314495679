<template>
    <ul class="profile-modal">
      <li class="profile-modal__item">
        <div class="profile-modal__info">
          <span class="profile-modal__title">{{ userData?.firstName || "Нет данных" }}</span>
          <div class="profile-modal__details">
            <p><strong>Телефон:</strong> {{ userData?.phoneNumber || "Нет данных" }}</p>
            <p><strong>Email:</strong> {{ userData?.email || "Не указан" }}</p>
          </div>
        </div>
      </li>
      <li class="profile-modal__item" v-for="addr in addresses" :key="addr.id">
        <div class="profile-modal__info">
          <span class="profile-modal__title">Адрес</span>
          <p>{{ addr.address?.address || "Адрес не указан" }}, 
             {{ addr.address?.city?.name || "Город не указан" }}, 
             {{ addr.address?.country?.name || "Страна не указана" }}</p>
        </div>
      </li>
    </ul>
  </template>
  
  <script setup>
  import { storeToRefs } from "pinia";
  import { useProfileStore } from "@/store/useProfileStore";
  import { onMounted } from "vue";
  
  const profileStore = useProfileStore();
  const { userData, addresses } = storeToRefs(profileStore);
  
  onMounted(() => {
    profileStore.fetchProfile();
    profileStore.fetchAddresses();
  });
  </script>

  
  <style scoped>
  .profile-modal {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
  }
  
  .profile-modal__item {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 20px 10px;
    border-bottom: 1px solid lightgray;
  }
  
  .profile-modal__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .profile-modal__title {
    text-align: left;
    font-weight: bold;
  }
  
  .profile-modal__details {
    font-size: 12px;
    color: #747474;
  }
  </style>