<template>
  <div class="search-bar">
    <input
      type="text"
      class="search-input"
      :value="modelValue" 
      @input="handleInput"
      placeholder="Я хочу найти..."
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { debounce } from 'lodash';

// Явно определяем входящее свойство modelValue
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  }
});

// Определяем исходящие события
const emit = defineEmits(['update:modelValue', 'search']);

// Создаем дебаунсированную функцию для поиска
const debouncedSearch = debounce((value) => {
  emit('search', value);
}, 300);

// Обработчик ввода
const handleInput = (event) => {
  const value = event.target.value;
  emit('update:modelValue', value);
  debouncedSearch(value);
};
</script>

<style scoped>
.search-bar {
  width: 100%;
  display: flex;
  padding: 10px;
}

.search-input {
  flex: 1;
  width: 100%;
  padding: 8px 16px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.search-input:hover {
  border-color: #F15E24;
}

.search-input:focus {
  outline: none;
  border-color: #F15E24;
  box-shadow: 0 0 0 2px rgba(241, 94, 36, 0.1);
}
</style>