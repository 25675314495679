import { defineStore } from 'pinia';

export const useLikeStore = defineStore('like', {
  state: () => ({
    likes: [],
  }),

  getters: {
    likedProduct: (state) => (id) => state.likes.some((item) => item.id === id),
    isLikes: (state) => state.likes.length > 0,
    totalLikes: (state) => state.likes.length,
  },

  actions: {
    addProductToFavorites(product) {
      if (!this.likes.some((item) => item.id === product.id)) {
        this.likes.push(product);
      }
    },

    removeProductFromFavorites(productId) {
      this.likes = this.likes.filter((item) => item.id !== productId);
    },

    toggleFavorite(product) {
      if (this.likedProduct(product.id)) {
        this.removeProductFromFavorites(product.id);
      } else {
        this.addProductToFavorites(product);
      }
    },

    // Загружает данные из LocalStorage при старте
    setLikesFromLS(products) {
      if (!products) return;
      this.likes = [...new Map(products.map((item) => [item.id, item])).values()]; // Убираем дубликаты
    },
  },
});
