<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="48"
    height="48"
    class="like-icon"
  >
    <path d="m24 41.95-2.05-1.85q-8.15-7.45-13.05-13T4 15.85q0-4.5 3.025-7.525Q10.05 5.3 14.5 5.3q2.55 0 5.05 1.225T24 10.55q2.2-2.8 4.55-4.025Q30.9 5.3 33.5 5.3q4.45 0 7.475 3.025Q44 11.35 44 15.85q0 5.7-4.9 11.25t-13.05 13Z"/>
  </svg>
</template>

<style scoped>
.like-icon {
  cursor: pointer;
  fill: gray;
  transition: fill 0.2s ease-in-out;
}

.like-icon:hover {
  fill: #FF8C00;
}
</style>