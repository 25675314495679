<template>
    <div class="likes-page">
        <!-- Хлебные крошки -->
        <div class="breadcrumbs">
            <router-link to="/" class="breadcrumb-link">Главная</router-link>
            <span class="breadcrumb-separator">›</span>
            <span class="breadcrumb-current">Мои заказы</span>
        </div>
  
        <!-- Основной контент -->
        <div class="likes-container">
            <!-- Левая панель навигации (только для десктопа) -->
            <div class="profile-sidebar desktop-only">
                <div class="menu-item" :class="{ active: activeTab === 'profile' }" @click="goToPage('/profile')">
                    <img src="@/assets/profile.png" class="menu-icon" alt="Профиль" />
                    <span>Профиль</span>
                </div>
                
                <div class="menu-item" :class="{ active: activeTab === 'orders' }" @click="setActiveTab('orders')">
                    <img src="@/assets/order.png" class="menu-icon" alt="Мои заказы" />
                    <span>Мои заказы</span>
                </div>
                
                <div class="menu-item" :class="{ active: activeTab === 'likes' }" @click="goToPage('/likes')">
                    <img src="@/assets/like.png" class="menu-icon" alt="Избранное" />
                    <span>Избранное</span>
                </div>
                
                <div class="menu-item" :class="{ active: activeTab === 'cart' }" @click="goToPage('/cart')">
                    <img src="@/assets/buy.png" class="menu-icon" alt="Корзина" />
                    <span>Корзина</span>
                </div>
            </div>
  
            <!-- Основная область контента -->
            <div class="likes-content">
                <h1 class="likes-title">Мои заказы</h1>

                <!-- Лоадер -->
                <div v-if="isLoading" class="loader">Загрузка...</div>

                <!-- Ошибка -->
                <div v-if="error" class="error-message">
                    ❌ Ошибка загрузки заказов. Попробуйте позже.
                </div>

                <!-- Список заказов -->
                <div v-if="orders.length > 0" class="orders-list">
                    <div 
                        v-for="order in orders" 
                        :key="order.id" 
                        class="order-card"
                        @click="goToOrder(order.id)"
                    >
                        <!-- Статус и сумма -->
                        <div class="order-header">
                            <span class="order-status" :class="getStatusClass(order.status?.code || order.status?.name)">
                                {{ order.status?.name }}
                            </span>
                            <span class="order-amount">
                                {{ order.status.name === 'В работе' ? 
                                    formatPrice(order.orderedTotalAmount || 0) : 
                                    formatPrice(order.totalAmount) }}
                            </span>
                        </div>

                        <!-- Дата и номер заказа -->
                        <div class="order-info">
                            <span class="order-date">{{ formatDate(order.createdInfo.createdOn) }}</span>
                            <span class="order-id">Заказ №{{ order.numberorder }}</span>
                        </div>
                    </div>
                </div>

                <!-- Если заказов нет -->
                <div v-else-if="!isLoading && !error" class="empty-message">
                    У вас пока нет заказов.
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router"; // Используем Vue Router
import api from "@/api"; // Подключаем API

const orders = ref([]);
const isLoading = ref(false);
const error = ref(false);
const router = useRouter(); // Инициализируем роутер
const activeTab = ref('orders');

// Функция для установки активной вкладки
const setActiveTab = (tab) => {
    activeTab.value = tab;
};

// Функция для перехода на другую страницу
const goToPage = (path) => {
    router.push(path);
};

// ✅ Проверяем авторизацию перед загрузкой заказов
const checkAuthAndFetchOrders = async () => {
    const token = localStorage.getItem("authToken"); // ✅ Проверяем токен
    if (!token) {
        router.push("/login"); // ✅ Перенаправляем на страницу входа
        return;
    }

    try {
        isLoading.value = true;
        const response = await api.get("/api/client/orders");
        orders.value = response.data.content;
        //console.log("📦 Полученные заказы:", orders.value);
    } catch (err) {
        //console.error("❌ Ошибка загрузки заказов:", err);
        error.value = true;
    } finally {
        isLoading.value = false;
    }
};

// ✅ Функция перехода на страницу заказа
const goToOrder = (orderId) => {
    router.push(`/order/${orderId}`);
};

// ✅ Форматируем дату
const formatDate = (dateString) => {
    const options = { 
        day: "2-digit", 
        month: "long", 
        year: "numeric",  // добавляем год
        weekday: "short", 
        hour: "2-digit", 
        minute: "2-digit" 
    };
    return new Date(dateString).toLocaleDateString("ru-RU", options);
};

// ✅ Форматируем цену (пример: 10 500 тг)
const formatPrice = (amount) => {
    return `${amount.toLocaleString("ru-RU")} тг`;
};

// ✅ Обновленная функция определения класса статуса заказа на основе кода статуса
const getStatusClass = (status) => {
    // Проверяем как на отображаемые имена, так и на внутренние коды статусов
    if (status === 'COMPLETED' || status === 'Завершенный') {
        return 'completed';
    } else if (status === 'DECLINED' || status === 'Отказано') {
        return 'declined';
    } else if (status === 'IN_WORK' || status === 'В работе') {
        return 'pending';
    } else {
        // Статус по умолчанию, если не определено иное
        return 'pending';
    }
};

// Загружаем заказы при входе на страницу
onMounted(checkAuthAndFetchOrders);
</script>

<style scoped>
.likes-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
}
  
.breadcrumbs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
    background-color: white;
}
  
.breadcrumb-link {
    color: #666;
    text-decoration: none;
}
  
.breadcrumb-link:hover {
    color: #F15E24;
}
  
.breadcrumb-separator {
    margin: 0 8px;
    color: #666;
}
  
.breadcrumb-current {
    color: #333;
    font-weight: 500;
}
  
.likes-container {
    display: flex;
    gap: 30px;
    background-color: white;
}
  
.profile-sidebar {
    width: 250px;
    flex-shrink: 0;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}
  
.menu-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    cursor: pointer;
    transition: background-color 0.2s;
}
  
.menu-item:hover {
    background-color: #f5f5f5;
}
  
.menu-item.active {
    background-color: #F15E24;
    color: white;
}
  
.menu-item.active .menu-icon {
    filter: brightness(0) invert(1);
}
  
.menu-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
  
.likes-content {
    flex: 1;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}
  
.likes-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
}

/* Заказы стили */
.loader {
    text-align: center;
    padding: 20px;
    color: #666;
    background-color: white;
}

.error-message {
    color: red;
    text-align: center;
    padding: 20px;
    background-color: white;
}

.empty-message {
    text-align: center;
    color: #888;
    padding: 30px;
    background-color: white;
}

.orders-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: white;
}

.order-card {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.order-card:hover {
    background: #eaeaea;
}

.order-header {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}

.order-amount {
    font-weight: bold;
}

.order-status {
    font-size: 14px;
}

.completed {
    color: #007b00;
}

.declined {
    color: red;
}

.pending {
    color: #ff9800;
}

.order-info {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    color: #666;
}

/* Адаптивные стили для мобильной версии */
@media (max-width: 768px) {
    /* Важно изменить стили для элемента .likes-content полностью */
    .likes-content {
        width: 100%;
        margin: 0;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        background-color: white !important;
        border: none;
        overflow: hidden;
    }
    
    /* Убираем любые рамки или фоны вокруг списка заказов */
    .orders-list {
        background-color: white !important;
        border: none;
        margin: 0;
        padding: 10px;
        width: 100%;
        box-sizing: border-box;
    }
    
    /* Стили для заголовка */
    .likes-title {
        font-size: 20px;
        margin: 0 0 15px 0;
        padding: 10px;
        background-color: white;
        text-align: left;
    }
    
    /* Стили для отдельных карточек заказов */
    .order-card {
        border-radius: 5px;
        margin-bottom: 8px;
        width: 100%;
        box-sizing: border-box;
    }
    
    /* Стиль для родительских контейнеров */
    .likes-page, .likes-container {
        background-color: white !important;
        border: none !important;
        outline: none !important;
        padding: 0;
        margin: 0;
        width: 100%;
        padding-bottom: 70px;
        box-sizing: border-box;
        overflow: hidden;
    }
    
    /* Убираем все возможные рамки и внешние стили */
    * {
        outline: none !important;
    }
    
    /* Хлебные крошки */
    .breadcrumbs {
        margin-bottom: 0;
        padding: 10px;
    }
    
    /* Скрываем боковое меню на мобильных */
    .desktop-only {
        display: none;
    }
}
</style>