<template>
  <div class="gallery">
    <!-- Основное изображение -->
    <div class="gallery__main" @click="openFullscreen">
      <img :src="images[currentIndex]" :alt="'Изображение ' + (currentIndex + 1)" />
      
      <!-- Мобильная пагинация -->
      <div class="gallery__dots mobile-only">
        <span 
          v-for="(_, index) in images" 
          :key="index"
          class="dot"
          :class="{ active: index === currentIndex }"
          @click.stop="setCurrentImage(index)"
        ></span>
      </div>
    </div>

    <!-- Список миниатюр (десктоп) -->
    <div class="gallery__thumbs desktop-only">
      <!-- Кнопка "Вверх" -->
      <button 
        class="thumb-nav prev"
        @click="prevThumbPage"
        v-show="thumbStartIndex > 0"
      >▲</button>

      <!-- Контейнер для миниатюр -->
      <div class="thumbs-container">
        <div 
          v-for="index in visibleThumbIndexes"
          :key="index"
          class="thumb"
          :class="{ active: index === currentIndex }"
          @click="setCurrentImage(index)"
        >
          <img :src="images[index]" :alt="'Миниатюра ' + (index + 1)" />
        </div>
      </div>

      <!-- Кнопка "Вниз" -->
      <button 
        class="thumb-nav next"
        @click="nextThumbPage"
        v-show="thumbStartIndex + visibleThumbCount < images.length"
      >▼</button>
    </div>

    <!-- Полноэкранный режим -->
    <teleport to="body">
      <div v-if="isFullscreen" class="fullscreen" @click="closeFullscreen">
        <div class="fullscreen__content" @click.stop>
          <img :src="images[currentIndex]" :alt="'Полноразмерное изображение'" />
          
          <!-- Кнопки навигации -->
          <button 
            class="nav-button prev" 
            @click.stop="prevImage"
            v-show="currentIndex > 0"
          >❮</button>
          <button 
            class="nav-button next" 
            @click.stop="nextImage"
            v-show="currentIndex < images.length - 1"
          >❯</button>
          
          <!-- Кнопка закрытия -->
          <button class="close-button" @click.stop="closeFullscreen">✕</button>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  images: {
    type: Array,
    required: true
  }
});

const currentIndex = ref(0);
const isFullscreen = ref(false);
const thumbStartIndex = ref(0);
const visibleThumbCount = 5; // Показываем только 3 миниатюры

// Вычисляем индексы видимых миниатюр
const visibleThumbIndexes = computed(() => {
  const indexes = [];
  for (let i = thumbStartIndex.value; i < Math.min(thumbStartIndex.value + visibleThumbCount, props.images.length); i++) {
    indexes.push(i);
  }
  return indexes;
});

// Навигация по миниатюрам
const prevThumbPage = () => {
  if (thumbStartIndex.value > 0) {
    thumbStartIndex.value = Math.max(0, thumbStartIndex.value - visibleThumbCount);
  }
};

const nextThumbPage = () => {
  if (thumbStartIndex.value + visibleThumbCount < props.images.length) {
    thumbStartIndex.value = Math.min(
      props.images.length - visibleThumbCount,
      thumbStartIndex.value + visibleThumbCount
    );
  }
};

// Обновляем видимые миниатюры при смене текущего изображения
const setCurrentImage = (index) => {
  currentIndex.value = index;
  // Если выбранное изображение не видно в текущем наборе миниатюр,
  // прокручиваем к нему
  if (index < thumbStartIndex.value || index >= thumbStartIndex.value + visibleThumbCount) {
    thumbStartIndex.value = Math.max(0, Math.min(
      props.images.length - visibleThumbCount,
      index - Math.floor(visibleThumbCount / 2)
    ));
  }
};

const prevImage = () => {
  if (currentIndex.value > 0) {
    currentIndex.value--;
  }
};

const nextImage = () => {
  if (currentIndex.value < props.images.length - 1) {
    currentIndex.value++;
  }
};

const openFullscreen = () => {
  isFullscreen.value = true;
  document.body.style.overflow = 'hidden';
};

const closeFullscreen = () => {
  isFullscreen.value = false;
  document.body.style.overflow = '';
};
</script>

<style scoped>
.gallery {
  display: flex;
  gap: 15px;
  width: 100%;
  position: relative;
}

.gallery__main {
  flex: 1;
  position: relative;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gallery__main img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  padding: 10px;
}

/* Стили для точек (мобильная версия) */
.gallery__dots {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
  padding: 8px 12px;
  border-radius: 20px;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: all 0.3s ease;
}

.dot.active {
  background: white;
  transform: scale(1.2);
}

/* Стили для миниатюр (десктоп версия) */
.gallery__thumbs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 70px;
  position: relative;
}

.thumbs-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 240px;
  overflow: visible;
}

.thumb {
  width: 100%;
  height: 70px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.3s ease;
  background: white;
  border: 1px solid #eee;
  padding: 3px;
}

.thumb.active {
  opacity: 1;
  border: 2px solid #F15E24;
  transform: scale(1.05);
}

.thumb img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Кнопки навигации по миниатюрам */
.thumb-nav {
  width: 100%;
  height: 24px;
  border: none;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
  color: #666;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.thumb-nav:hover {
  background: rgba(0, 0, 0, 0.2);
  color: #333;
}

/* Полноэкранный режим */
.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.fullscreen__content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen__content img {
  max-width: 90%;
  max-height: 90vh;
  object-fit: contain;
  display: block;
}

.nav-button {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 24px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.nav-button:hover {
  background: rgba(255, 255, 255, 0.3);
}

.prev {
  left: 20px;
}

.next {
  right: 20px;
}

.close-button {
  position: fixed;
  top: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.2);
  border: none;
  color: white;
  font-size: 28px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.3);
}

/* Адаптивные стили */
@media (max-width: 768px) {
  .gallery {
    flex-direction: column;
  }

  .desktop-only {
    display: none;
  }

  .gallery__main {
    margin-bottom: 0;
    min-height: 250px;
  }

  .gallery__main img {
    padding: 5px;
  }
}

@media (min-width: 769px) {
  .mobile-only {
    display: none;
  }

  .gallery {
    flex-direction: row-reverse;
  }
}
</style> 