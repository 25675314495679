<template>
  <section class="cart">
    <h3 class="cart__header">Ваша корзина</h3>
    
    <div class="cart__mobile">
      <div class="cart__grid">
        <label class="cart__title">Картинка</label>
        <label class="cart__title">Название</label>
        <label class="cart__title">Кол-во</label>
        <label class="cart__title">Цена</label>
        <label class="cart__title">Итого</label>
      </div>
    </div>

    <ul class="cart__list">
      <li class="cart__grid" v-for="product in cart" :key="product.id">
        <img
          :src="product.image || ''"
          class="cart__img"
          :alt="product.title || 'Product Image'"
          @click="goToProductPage(product.id)"
        />
        <p
          class="cart__item-title cart__item-text"
          @click="goToProductPage(product.id)"
        >
          {{ product.title || 'No title available' }}
        </p>

        <div class="cart__mobile">
          <span>Кол-во:</span>
          <QuantityBlock
            :quantity="product.quantity || 1"
            @increment="incrementQuantity(product.id)"
            @decrement="decrementQuantity(product.id)"
          ></QuantityBlock>
        </div>

        <div class="cart__mobile">
          <span>Цена:</span>
          <div class="cart__price-wrapper">
            <p class="cart__item-text cart__current-price">
              {{ formatPrice(getProductPrice(product)) }}
            </p>
            <p v-if="isSalePrice(product)" class="cart__item-text cart__old-price">
              {{ formatPrice(product.retailPrice) }}
            </p>
          </div>
        </div>

        <div class="cart__mobile">
          <span>Итого:</span>
          <p class="cart__item-text">
            {{ formatPrice(getProductPrice(product) * (product.quantity || 1)) }}
          </p>
        </div>

        <div class="cart__mobile-action">
          <BaseIconButton
            @click="removeProductFromCart(product.id)"
            variant="contained"
            iconHoverColor="#ef2525"
            iconColor="#74747474"
            opacity="1"
          >
            <DeleteIcon />
          </BaseIconButton>
        </div>
      </li>
    </ul>

    <!-- Блок с итогами и минимальной суммой заказа -->
    <div class="cart__footer">
      <div class="cart__totals">
        <div class="cart__totals-block">
          <span class="cart__totals-block-title">Итого кол-во:</span>
          <p>{{ totalProducts }} шт</p>
        </div>
        <div class="cart__totals-block">
          <span class="cart__totals-block-title">Итого цена: </span>
          <p>₸{{ totalPrice }}</p>
        </div>
        <p :class="minOrderClass">
          Минимальная сумма заказа: 80 000 тг
        </p>
      </div>
      
      <!-- Кнопки управления -->
      <div class="cart__actions">
        <button class="cart__continue-btn" @click="continueShopping">
          Продолжить покупки
        </button>
        <button class="cart__checkout-btn" @click="proceedToCheckout">
          Оформить сейчас
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import DeleteIcon from "./icons/DeleteIcon.vue";
import QuantityBlock from "./UI/QuantityBlock.vue";
import { computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useCartStore } from "@/store/useCartStore";
import { storeToRefs } from "pinia";
import { usePendingOrderStore } from "@/store/usePendingOrderStore"; // Добавляем импорт

const props = defineProps({
  onClose: {
    type: Function,
    default: () => {}
  }
});

const router = useRouter();
const cartStore = useCartStore();
const { cart } = storeToRefs(cartStore);
const pendingOrderStore = usePendingOrderStore(); // Добавляем хранилище

// Обновляем данные товаров при загрузке компонента
onMounted(() => {
  cartStore.fetchCartProducts();
  // Загружаем данные о незавершенных заказах
  pendingOrderStore.loadPendingOrder();
});

// Функция для определения, есть ли акционная цена
const isSalePrice = (product) => {
  return product.sale_retail_price && 
         product.sale_retail_price > 0 && 
         product.sale_retail_price < product.retailPrice;
};

// Функция для получения актуальной цены товара (акционной или обычной)
const getProductPrice = (product) => {
  if (isSalePrice(product)) {
    return product.sale_retail_price;
  }
  return product.retailPrice || 0;
};

// Добавляем функцию форматирования цены
const formatPrice = (price) => {
  if (!price && price !== 0) return "0 ₸";
  return `₸ ${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
};

// Расчет общего количества товаров в корзине
const totalProducts = computed(() =>
  cart.value.reduce((sum, product) => sum + (product.quantity || 0), 0)
);

// Расчет общей суммы корзины с учетом акционных цен
const totalPrice = computed(() =>
  cart.value
    .reduce((sum, product) => {
      const price = isSalePrice(product) ? product.sale_retail_price : (product.retailPrice || 0);
      return sum + price * (product.quantity || 1);
    }, 0)
    .toFixed(2)
);

// Проверка минимальной суммы заказа для стилизации
const minOrderClass = computed(() => {
  return parseFloat(totalPrice.value) < 80000 ? "cart__min-order--warning" : "cart__min-order";
});

const incrementQuantity = (id) => {
  cartStore.incrementQuantity(id);
};

const decrementQuantity = (id) => {
  cartStore.decrementQuantity(id);
};

const removeProductFromCart = (id) => {
  cartStore.removeProductFromCart(id);
};

const goToProductPage = (id) => {
  router.push(`/product/${id}`);
  props.onClose(); // Закрываем модальное окно
};

// Продолжить покупки - просто закрытие модального окна
const continueShopping = () => {
  props.onClose();
};

// Функция оформления заказа
const proceedToCheckout = () => {
  // Проверка авторизации
  const token = localStorage.getItem("authToken");
  
  // Если нет токена - перекидываем на страницу авторизации
  if (!token) {
    router.push("/login");
    props.onClose(); // Закрываем модальное окно
    return;
  }
  
  // Формируем список товаров с учетом остатков
  const validCartItems = cart.value
    .filter(product => product.remainedCount > 0)
    .map(product => ({
      id: product.id,
      title: product.title,
      // Используем актуальную цену (обычную или акционную)
      price: isSalePrice(product) ? product.sale_retail_price : product.retailPrice,
      quantity: Math.min(product.quantity, product.remainedCount)
    }));
  
  // Проверяем наличие доступных товаров
  if (validCartItems.length === 0) {
    alert("❌ В корзине нет доступных товаров для заказа!");
    return;
  }
  
  // Сохраняем заказ перед переходом
  pendingOrderStore.savePendingOrder(validCartItems);
  
  // Кодируем JSON для безопасной передачи
  const encodedItems = encodeURIComponent(JSON.stringify(validCartItems));
  
  // Очистка корзины перед оформлением заказа
  cartStore.clearCart();
  
  // Закрываем модальное окно
  props.onClose();
  
  // Переходим на страницу подтверждения заказа
  router.push(`/order-confirmation?items=${encodedItems}`);
};
</script>

<style scoped>
.cart {
  display: grid;
}

.cart__header {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.cart__title {
  display: none;
}

.cart__grid {
  display: grid;
  gap: 15px;
  border-bottom: 1px solid lightgray;
}

.cart__mobile {
  display: flex;
  gap: 20px;
  align-items: center;
}

.cart__mobile-action {
  justify-self: start;
  padding: 0 0 10px;
}

.cart__mobile > span {
  font-weight: 600;
}

.cart__list {
  display: grid;
  gap: 20px;
  padding: 0;
  list-style: none;
  max-height: 50vh; /* Ограничиваем высоту списка для модального окна */
  overflow-y: auto; /* Добавляем прокрутку */
}

.cart__img {
  display: none;
}

.cart__item-title {
  cursor: pointer;
  justify-self: start;
  text-align: left;
}

.cart__item-title:hover {
  transition: color 0.1s linear;
  color: #ed8939;
}

.cart__item-text {
  font-size: 14px;
}

/* Стили для цен */
.cart__price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cart__current-price {
  font-weight: bold;
  color: #F15E24; /* Оранжевый цвет для акционной цены */
}

.cart__old-price {
  text-decoration: line-through;
  color: #999;
  font-size: 12px;
}

/* Блок с итогами */
.cart__footer {
  margin-top: 20px;
  padding: 15px;
  border-top: 1px solid #eee;
}

.cart__totals {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
}

.cart__totals-block {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
  padding: 5px 0;
}

.cart__totals-block-title {
  display: block;
  font-weight: 600;
}

.cart__min-order {
  font-size: 14px;
  color: #666;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 15px;
}

.cart__min-order--warning {
  color: red;
  font-weight: bold;
}

.cart__actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.cart__continue-btn {
  padding: 12px 20px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  flex: 1;
}

.cart__checkout-btn {
  background: #F15E24;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  flex: 1;
}

.cart__checkout-btn:hover {
  background: #d64d14;
}

@media screen and (min-width: 768px) {
  .cart {
    display: block;
  }
  .cart__grid {
    max-width: 900px;
    margin: auto;
    align-items: center;
    justify-items: center;
    grid-template-columns: 1fr 4fr 1fr 1fr 1fr 1fr;
    grid-gap: 15px;
    color: #222;
    padding: 15px 0;
    border-bottom: 1px solid lightgray;
  }

  .cart__title {
    display: block;
    font-weight: 600;
  }

  .cart__img {
    cursor: pointer;
    display: block;
    width: 100%;
    height: 40px;
    object-fit: contain;
  }

  .cart__img:hover {
    opacity: 0.7;
    transition: opacity 0.2s linear;
  }

  .cart__mobile {
    display: contents;
  }

  .cart__mobile > span {
    display: none;
  }

  .cart__mobile-action {
    justify-self: center;
    padding: 0;
  }
}

/* Корректирует стили для мобильных устройств */
@media (max-width: 480px) {
  .cart__actions {
    flex-direction: column;
  }
  
  .cart__checkout-btn,
  .cart__continue-btn {
    width: 100%;
  }
}
</style>