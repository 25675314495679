<template>
    <div class="likes-page">
        <!-- Хлебные крошки -->
        <div class="breadcrumbs">
            <router-link to="/" class="breadcrumb-link">Главная</router-link>
            <span class="breadcrumb-separator">›</span>
            <span class="breadcrumb-current">Профиль</span>
        </div>
  
        <!-- Основной контент -->
        <div class="likes-container">
            <!-- Левая панель навигации (только для десктопа) -->
            <div class="profile-sidebar desktop-only">
                <div class="menu-item" :class="{ active: activeTab === 'profile' }" @click="setActiveTab('profile')">
                    <img src="@/assets/profile.png" class="menu-icon" alt="Профиль" />
                    <span>Профиль</span>
                </div>
                
                <div class="menu-item" :class="{ active: activeTab === 'orders' }" @click="goToPage('/orders')">
                    <img src="@/assets/order.png" class="menu-icon" alt="Мои заказы" />
                    <span>Мои заказы</span>
                </div>
                
                <div class="menu-item" :class="{ active: activeTab === 'likes' }" @click="goToPage('/likes')">
                    <img src="@/assets/like.png" class="menu-icon" alt="Избранное" />
                    <span>Избранное</span>
                </div>
                
                <div class="menu-item" :class="{ active: activeTab === 'cart' }" @click="goToPage('/cart')">
                    <img src="@/assets/buy.png" class="menu-icon" alt="Корзина" />
                    <span>Корзина</span>
                </div>
            </div>
  
            <!-- Основная область контента -->
            <div class="likes-content">
                <div class="profile-header">
                    <div class="title-with-indicator">
                        <h1 class="likes-title">Профиль</h1>
                        <div class="client-indicator retail" :class="{ 
                            'wholesale': userData && userData.cod_type_client === 'trade'
                        }"></div>
                    </div>
                    <!-- Кнопка выхода только на мобильных устройствах -->
                    <button class="logout-button mobile-only" @click="isLogoutModalOpen = true">
                        <img src="@/assets/logout-icon.svg" alt="Выйти" />
                    </button>
                </div>

                <div v-if="isLoading" class="loader">Загрузка...</div>
                <div v-if="error" class="error-message">❌ Ошибка загрузки профиля. Попробуйте позже.</div>
                <div v-if="isSaved" class="success-message">✅ Изменения сохранены.</div>

                <!-- 🔹 Основные данные -->
                <div v-if="userData" class="profile-card">
                    <h2 class="section-title">Данные</h2>
                    <table class="profile-table">
                        <tbody>
                            <tr>
                                <td class="profile-label">Основной номер</td>
                                <td class="profile-data">
                                    <input 
                                        v-model="userData.phoneNumber" 
                                        class="profile-input disabled-input" 
                                        disabled 
                                    />
                                </td>
                            </tr>
                            <tr v-for="(field, key) in editableFields" :key="key">
                                <td class="profile-label">{{ field.label }}</td>
                                <td class="profile-data">
                                    <input 
                                        v-if="field.type === 'text' || field.type === 'phone'" 
                                        v-model="editableUserData[key]" 
                                        class="profile-input"
                                        @input="debouncedUpdateProfile"
                                    />

                                    <input 
                                        v-else-if="field.type === 'date'" 
                                        type="date" 
                                        v-model="editableUserData[key]" 
                                        class="profile-input"
                                        @change="debouncedUpdateProfile"
                                    />

                                    <select v-else-if="key === 'gender'" v-model="editableUserData.gender.id" 
                                            class="profile-input" @change="debouncedUpdateProfile">
                                        <option v-for="option in genderOptions" :key="option.id" :value="option.id">
                                            {{ option.name }}
                                        </option>
                                    </select>

                                    <select v-else-if="key === 'activityType'" v-model="editableUserData.activityType.id"
                                            class="profile-input" @change="debouncedUpdateProfile">
                                        <option v-for="option in activityTypeOptions" :key="option.id" :value="option.id">
                                            {{ option.name }}
                                        </option>
                                    </select>

                                    <input 
                                        v-else-if="field.type === 'phone'" 
                                        v-model="editableUserData[key]" 
                                        class="profile-input"
                                        @input="formatPhoneNumber"
                                        @blur="debouncedUpdateProfile"
                                        placeholder="+7 (___) ___-__-__"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- 🔹 Блок с адресами -->
                <div v-if="addresses.length > 0" class="profile-card">
                    <h2 class="section-title">Адреса</h2>
                    <table class="profile-table">
                        <tbody>
                            <tr v-for="addr in addresses" :key="addr.id">
                                <td class="address-actions">
                                    <!-- ⭐ Кнопка "Сделать главным" -->
                                    <BaseIconButton
                                        @click="setMainAddress(addr.id)"
                                        variant="contained"
                                        iconHoverColor="#ef2525"
                                        iconColor="#74747474"
                                        opacity="1"
                                    >
                                        <MainAddressIcon :isMain="addr.isMain" />
                                    </BaseIconButton>

                                    <!-- 🏠 Адрес (Город, Страна) -->
                                    <span class="address-text">
                                        {{ addr.address?.address || "Адрес не указан" }}, 
                                        {{ addr.address?.city?.name || "Город не указан" }}, 
                                        {{ addr.address?.country?.name || "Страна не указана" }}
                                    </span>

                                    <!-- 🗑️ Кнопка удаления -->
                                    <BaseIconButton
                                        @click="deleteAddress(addr.id)"
                                        variant="contained"
                                        iconHoverColor="#ef2525"
                                        iconColor="#74747474"
                                        opacity="1"
                                    >
                                        <DeleteIcon />
                                    </BaseIconButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="add-button" @click="openNewAddressModal">+ Добавить адрес</button>
                </div>

                <!-- 🔹 Если нет адресов, показываем кнопку -->
                <div v-else class="empty-address">
                    <p>У вас пока нет адресов</p>
                    <button class="add-button" @click="openNewAddressModal">+ Добавить адрес</button>
                </div>

                <!-- 🔹 Модальное окно для добавления адреса -->
                <div v-if="isNewAddressModalOpen" class="modal">
                    <div class="modal-content">
                        <h2>Добавить адрес</h2>
                        
                        <label>Адрес</label>
                        <input v-model="newAddress.address" class="profile-input" />

                        <label>Страна</label>
                        <select v-model="newAddress.country.id" class="profile-input" @change="handleCountryChange">
                            <option v-for="country in availableCountries" :key="country.id" :value="country.id">
                                {{ country.name }}
                            </option>
                        </select>

                        <label>Город</label>
                        <select class="profile-input" v-model="newAddress.city.id" size="10">
                            <option v-for="city in availableCities" :key="city.id" :value="city.id">
                                {{ city.name }}
                            </option>
                        </select>

                        <div class="modal-buttons">
                            <button @click="addNewAddress">Добавить</button>
                            <button @click="isNewAddressModalOpen = false">Отмена</button>
                        </div>
                    </div>
                </div>
                
                <!-- 🔹 Модальное окно выхода -->
                <div v-if="isLogoutModalOpen" class="modal">
                    <div class="modal-content">
                        <h2>Выход из аккаунта</h2>
                        <p>Выберите действие:</p>
                        
                        <button class="logout-btn" @click="logout">Выйти</button>
                        <button class="delete-btn" @click="openConfirmDeleteModal">Удалить аккаунт</button>
                        <button class="cancel-btn" @click="isLogoutModalOpen = false">Отмена</button>
                    </div>
                </div>

                <!-- 🔹 Подтверждение удаления аккаунта -->
                <div v-if="isConfirmDeleteModalOpen" class="modal">
                    <div class="modal-content">
                        <h2>Вы уверены?</h2>
                        <p>⚠️ Все ваши данные будут удалены, и вам нужно будет зарегистрироваться заново.</p>
                        
                        <button class="delete-confirm-btn" @click="deleteProfile">Да, удалить</button>
                        <button class="cancel-btn" @click="isConfirmDeleteModalOpen = false">Отмена</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import api from "@/api";
import DeleteIcon from "../components/icons/DeleteIcon.vue";
import MainAddressIcon from "../components/icons/MainAddressIcon.vue";
import BaseIconButton from "../components/UI/Buttons/BaseIconButton.vue";
import { useRouter } from "vue-router"; 

const userData = ref(null);
const addresses = ref([]);
const availableCountries = ref([
    { id: 1, name: "Казахстан" },
    { id: 2, name: "Другое" }
]);
const availableCities = ref([]);
const newAddress = ref({ address: "", country: { id: 2 }, city: { id: 1 } });

const router = useRouter();
const isLoading = ref(true);
const isSaved = ref(false);
const error = ref(null);
const editableUserData = ref({});
const saveTimeout = ref(null);
const isLogoutModalOpen = ref(false);
const genderOptions = ref([]);
const activityTypeOptions = ref([]);
const isNewAddressModalOpen = ref(false);
const isConfirmDeleteModalOpen = ref(false);
const activeTab = ref('profile');

// ✅ Функция для установки активной вкладки
const setActiveTab = (tab) => {
    activeTab.value = tab;
};

// ✅ Функция для перехода на другую страницу
const goToPage = (path) => {
    router.push(path);
};

// ✅ Поля для редактирования
const editableFields = ref({
    firstName: { label: "Имя", type: "text", default: "" },
    birthDate: { label: "Дата рождения", type: "date", default: "" },
    gender: { label: "Пол", type: "select", default: { id: "", name: "" } },
    activityType: { label: "Тип деятельности", type: "select", default: { id: "", name: "" } },
    email: { label: "Email", type: "text", default: "" },
    additionalPhoneNumber: { label: "Доп. номер", type: "phone", default: "" }
});


// ✅ Проверка авторизации и загрузка данных
const checkAuthAndFetchProfile = async () => {
    const token = localStorage.getItem("authToken");
    if (!token) {
        router.push("/login");
        return;
    }

    await Promise.all([fetchProfile(), fetchAddresses(), fetchReferences()]);
    isLoading.value = false;
};


// ✅ Загрузка профиля
const fetchProfile = async () => {
    try {
        const response = await api.get("/api/clients/me");
        userData.value = response.data || {};
        editableUserData.value = {
            firstName: response.data.firstName || "",
            birthDate: response.data.birthDate || "",
            gender: response.data.gender || { id: "", name: "" },
            activityType: response.data.activityType || { id: "", name: "" },
            email: response.data.email || "",
            additionalPhoneNumber: response.data.additionalPhoneNumber || "",
            accountNumber: response.data.phoneNumber || ""
        };
    } catch (err) {
        error.value = "Не удалось загрузить данные.";
    }
};

const fetchReferences = async () => {
    try {
        const token = localStorage.getItem("authToken");
        if (!token) {
            console.error("⚠️ Ошибка: authToken отсутствует!");
            return;
        }

        const genderResponse = await api.get("/api/refs?type=GENDER", { headers: { Authorization: `Bearer ${token}` } });
        genderOptions.value = genderResponse.data || [];

        const activityResponse = await api.get("/api/refs?type=ACTIVITY_TYPE", { headers: { Authorization: `Bearer ${token}` } });
        activityTypeOptions.value = activityResponse.data || [];

    } catch (err) {
        console.error("❌ Ошибка загрузки справочников:", err);
    }
}

// ✅ Обновление профиля
const updateProfile = async () => {
    try {
        const payload = {
            id: userData.value?.id || 0,
            firstName: editableUserData.value.firstName || "",
            birthDate: editableUserData.value.birthDate || "",
            email: editableUserData.value.email || "",
            additionalPhoneNumber: editableUserData.value.additionalPhoneNumber || "",
            phoneNumber: userData.value.phoneNumber,
            accountNumber: userData.value.phoneNumber.replace(/\D/g, ""),
            gender: genderOptions.value.find(g => g.id === editableUserData.value.gender.id) || { id: "", name: "" },
            activityType: activityTypeOptions.value.find(a => a.id === editableUserData.value.activityType.id) || { id: "", name: "" }
        };

       // console.log("📤 Отправляемые данные:", JSON.stringify(payload, null, 2));
        await api.put("/api/clients/me", payload);
        
        isSaved.value = true;
        setTimeout(() => { isSaved.value = false; }, 3000);
    } catch (err) {
       // console.error("❌ Ошибка при обновлении профиля:", err);
    }
};


// ✅ Загрузка адресов
const fetchAddresses = async () => {
    try {
        const response = await api.get("/api/client/address");
        addresses.value = Array.isArray(response.data) ? response.data : [];
    } catch (err) {
        console.error("❌ Ошибка загрузки адресов:", err);
        addresses.value = []; // Предотвращает ошибки в рендеринге
    }
};



// ✅ Выход из аккаунта
const logout = async () => {
    try {
        await api.post("/auth/signout");
        localStorage.removeItem("authToken"); // ✅ Удаляем токен
        api.defaults.headers.common["Authorization"] = ""; // ✅ Убираем заголовок
        router.push("/login"); // ✅ Перенаправляем на страницу входа
    } catch (err) {
        console.error("❌ Ошибка выхода:", err);
    }
};

// ✅ Функция для открытия модального окна удаления
const openConfirmDeleteModal = () => {
    isLogoutModalOpen.value = false; // Закрываем предыдущее окно
    isConfirmDeleteModalOpen.value = true; // Открываем подтверждение удаления
};

// ✅ Удаление профиля
const deleteProfile = async () => {
    try {
        await api.delete("/auth/delete");
        localStorage.removeItem("authToken"); // ✅ Удаляем токен
        api.defaults.headers.common["Authorization"] = ""; // ✅ Убираем заголовок
        router.push("/login"); // ✅ Перенаправляем на страницу входа
    } catch (err) {
        console.error("❌ Ошибка удаления аккаунта:", err);
    }
};



// ✅ Загрузка городов по стране
const fetchCities = async () => {
    try {
        if (newAddress.value.country.id !== 1) {
            availableCities.value = [];
            newAddress.value.city.id = 22; // Если выбрана другая страна, автоматически устанавливаем город "Другое"
            return;
        }
        const response = await api.get(`/api/refs/city?countryId=1`);
        availableCities.value = response.data;

        // Устанавливаем город по умолчанию после загрузки списка
        if (availableCities.value.length > 0) {
            newAddress.value.city.id = availableCities.value[0].id;
        }
    } catch (err) {
        console.error("❌ Ошибка загрузки городов:", err);
    }
};

// ✅ Дебаунс-функция для вызова `updateProfile`
const debouncedUpdateProfile = () => {
    if (saveTimeout.value) clearTimeout(saveTimeout.value);
    saveTimeout.value = setTimeout(updateProfile, 1500);
};

// ✅ Обработка выбора страны
const handleCountryChange = () => {
    fetchCities(); // Загружаем список городов при изменении страны
};

// ✅ Удаление адреса
const deleteAddress = async (id) => {
    try {
        await api.delete(`/api/client/address/${id}`);
        addresses.value = addresses.value.filter(addr => addr.id !== id);
    } catch (err) {
        alert("❌ Ошибка при удалении адреса.");
    }
};

// ✅ Установка главного адреса
const setMainAddress = async (id) => {
    try {
        await api.put(`/api/client/address/main/${id}`);
    } catch (err) {
        alert("❌ Ошибка при установке главного адреса.");
    }
};

// ✅ Открытие модального окна
const openNewAddressModal = () => {
    isNewAddressModalOpen.value = true;
};

// ✅ Добавление нового адреса
const addNewAddress = async () => {
    try {
        const response = await api.post("/api/client/address", newAddress.value);
        addresses.value.push(response.data);
        isNewAddressModalOpen.value = false;
    } catch (err) {
        alert("❌ Ошибка при добавлении адреса.");
    }
};

// ✅ Следим за изменением страны пользователя
watch(() => userData.value?.country?.id, (newCountryId) => {
    if (newCountryId) {
        fetchCities(newCountryId);
    }
});

// ✅ Загрузка данных при запуске
onMounted(checkAuthAndFetchProfile);
</script>

<!-- Обновляем только тег <style> в компоненте Profilepage.vue -->
<style scoped>
.likes-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: white;
}
  
.breadcrumbs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
}
  
.breadcrumb-link {
    color: #666;
    text-decoration: none;
}
  
.breadcrumb-link:hover {
    color: #F15E24;
}
  
.breadcrumb-separator {
    margin: 0 8px;
    color: #666;
}
  
.breadcrumb-current {
    color: #333;
    font-weight: 500;
}
  
.likes-container {
    display: flex;
    gap: 30px;
    background-color: white;
}
  
.profile-sidebar {
    width: 250px;
    flex-shrink: 0;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}
  
.menu-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    cursor: pointer;
    transition: background-color 0.2s;
}
  
.menu-item:hover {
    background-color: #f5f5f5;
}
  
.menu-item.active {
    background-color: #F15E24;
    color: white;
}
  
.menu-item.active .menu-icon {
    filter: brightness(0) invert(1);
}
  
.menu-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
  
.likes-content {
    flex: 1;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}
  
.likes-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
}

/* Хедер профиля с заголовком и кнопкой выхода */
.profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Изначально скрываем кнопку выхода */
.logout-button {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
}

/* Показываем кнопку выхода только на мобильных */
.mobile-only {
    display: none;
}

.logout-button img {
    width: 24px;
    height: 24px;
}

/* Profile-specific styles */
.section-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
}

.profile-card {
    background: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.profile-table {
    width: 100%;
    border-collapse: collapse;
}

.profile-table td {
    padding: 10px 5px;
    border-bottom: 1px solid #eee;
}

.profile-label {
    font-weight: 500;
    color: #555;
    width: 30%;
}

.profile-input {
    width: 100%;
    padding: 8px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    background-color: white;
}

.disabled-input {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.address-actions {
    display: flex;
    align-items: center;
    width: 100%;
}

.address-text {
    flex-grow: 1;
    padding: 0 10px;
}

.add-button {
    background: #F15E24;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
}

.add-button:hover {
    background: #d64d14;
}

.empty-address {
    text-align: center;
    padding: 30px 0;
    color: #666;
    background-color: white;
}

/* Modal styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
}

.modal-content h2 {
    margin-bottom: 15px;
    font-size: 18px;
}

.modal-content label {
    display: block;
    margin: 10px 0 5px;
    font-weight: 500;
}

.modal-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 10px;
}

.modal-buttons button {
    flex: 1;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.modal-buttons button:first-child {
    background: #F15E24;
    color: white;
}

.modal-buttons button:last-child {
    background: #eee;
    color: #333;
}

/* Error, success messages */
.error-message {
    color: #e74c3c;
    margin-bottom: 15px;
    text-align: center;
}

.success-message {
    color: #2ecc71;
    margin-bottom: 15px;
    text-align: center;
}

/* Button styles */
.logout-btn, .delete-btn, .delete-confirm-btn, .cancel-btn {
    width: 100%;
    padding: 12px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
}

.logout-btn {
    background: #0A1F44;
    color: white;
}

.delete-btn, .delete-confirm-btn {
    background: #e74c3c;
    color: white;
}

.cancel-btn {
    background: #eee;
    color: #333;
}

/* Исправленные стили для мобильной версии */
@media (max-width: 768px) {
    /* Исправления основного контейнера */
    .likes-page {
        padding: 0; 
        margin: 0;
        width: 100%;
        padding-bottom: 70px;
        background-color: white; /* Убеждаемся, что фон белый */
        box-sizing: border-box;
        overflow: hidden; /* Предотвращаем выход за пределы экрана */
    }
    
    /* Исправления для хлебных крошек */
    .breadcrumbs {
        padding: 0 10px;
        margin-bottom: 10px;
        background-color: white;
    }
    
    /* Исправления для контейнера содержимого */
    .likes-container {
        flex-direction: column;
        width: 100%;
        gap: 0;
        background-color: white; /* Убеждаемся, что фон белый */
    }
    
    /* Исправления для основного блока контента */
    .likes-content {
        width: 100%; 
        margin: 0;
        padding: 0; /* Убираем отступы, чтобы карточки занимали весь экран */
        border-radius: 0;
        box-shadow: none;
        background-color: white;
    }
    
    /* Исправления для профильных карточек */
    .profile-card {
        margin: 0 0 1px 0; /* Минимальный отступ между карточками */
        box-shadow: none;
        border-radius: 0;
        padding: 10px;
        border-bottom: 1px solid #eee;
        background-color: white;
        width: 100%;
        box-sizing: border-box;
    }
    
    /* Заголовок профиля */
    .profile-header {
        margin-bottom: 10px;
        padding: 5px 10px;
        background-color: white;
    }
    
    /* Исправления для таблицы профиля */
    .profile-table {
        width: 100%;
        background-color: white;
    }
    
    /* Исправления для строк таблицы */
    .profile-table tr {
        background-color: white;
    }
    
    .profile-table td {
        display: block;
        width: 100%;
        box-sizing: border-box;
        background-color: white;
    }
    
    .profile-label {
        width: 100%;
        margin-bottom: 5px;
        padding-bottom: 0;
    }
    
    .profile-data {
        padding-top: 5px;
    }
    
    /* Стили для полей */
    .profile-input {
        width: 100%;
        background-color: white; /* Поля должны быть белыми */
        box-sizing: border-box;
    }
    
    /* Исправление для отключенных полей */
    .disabled-input {
        background-color: #f5f5f5;
    }
    
    /* Заголовки секций */
    .section-title {
        padding: 0 10px;
        margin-top: 0;
        margin-bottom: 10px;
    }
    
    /* Дополнительное исправление для адресов */
    .empty-address {
        background-color: white;
        padding: 20px 10px;
        margin: 0;
    }
    
    .desktop-only {
        display: none; /* Скрываем боковое меню на мобильных */
    }
    
    .mobile-only {
        display: block;
    }
    
    /* Модальные окна на мобильных */
    .modal-content {
        width: 95%;
        max-height: 80vh;
        overflow-y: auto;
    }
    
    /* Адаптация для адресов */
    .address-actions {
        flex-wrap: wrap;
        gap: 5px;
    }
    
    .address-text {
        width: 100%;
        padding: 5px 0;
        order: 1;
    }
    
    .add-button {
        width: 100%;
        margin-top: 10px;
    }
}


/* Стили для заголовка с индикатором */
.title-with-indicator {
    display: flex;
    align-items: center; /* Выравнивание по центру по вертикали */
    gap: 10px;
}

.likes-title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin: 0; /* Убираем отступы у заголовка */
    display: inline-flex; /* Улучшаем выравнивание */
    align-items: center;
}

/* Стили для индикатора типа клиента */
.client-indicator {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid #ddd;
    background-color: #F15E24; /* По умолчанию оранжевый (розничный) */
    vertical-align: middle; /* Дополнительное выравнивание по вертикали */
    position: relative; /* Для возможного позиционирования */
    top: 0; /* Убедимся, что нет смещения */
}

.client-indicator.wholesale {
    background-color: #3498db; /* Синий для оптовых */
}
</style>