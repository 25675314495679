<template>
    <div class="register-container">
      <img :src="Logo" alt="Логотип" class="logo" />
      
      <h2>Проверка номера</h2>
      <p class="step-text">Шаг 2 из 3</p>
  
      <p class="sms-info">Введите код из SMS, отправленный на номер <br><strong>{{ maskedPhone }}</strong></p>
  
      <form @submit.prevent="verifyCode">
        <!-- Поле ввода кода -->
        <div class="input-group">
          <label for="code">Код из SMS</label>
          <div class="code-input-container">
            <input 
              type="text" 
              v-model="verificationCode" 
              class="code-input" 
              placeholder="Введите код" 
              maxlength="6"
              inputmode="numeric"
              pattern="[0-9]*"
              ref="codeInput"
              required
            />
          </div>
          <p v-if="countdown > 0" class="countdown">
            Повторная отправка через {{ countdown }} секунд
          </p>
          <p v-else class="resend" @click="resendCode">
            Отправить код повторно
          </p>
        </div>
  
        <!-- Добавляем ссылку на Telegram -->
        <p class="telegram-support">
          Не получили SMS? Напишите нам в 
          <a href="https://t.me/tezal_support_bot" target="_blank" rel="noopener noreferrer">Telegram</a>
        </p>
  
        <button type="submit" :disabled="isLoading || verificationCode.length < 4">
          {{ isLoading ? "Проверка..." : "Подтвердить" }}
        </button>
      </form>
  
      <button class="back-button" @click="goBack">
        Назад
      </button>
  
      <!-- Сообщение об ошибке -->
      <div v-if="errorMessage" class="error-block">
        <span class="error-icon">✖</span>
        {{ errorMessage }}
      </div>
    </div>
</template>
  
<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import api from "@/api";
import Logo from "@/assets/icon.png";
  
const router = useRouter();
  
const verificationCode = ref("");
const isLoading = ref(false);
const errorMessage = ref("");
const countdown = ref(60); // 60 секунд для повторной отправки
const countdownInterval = ref(null);
const codeInput = ref(null);
const phone = ref("");
const verificationToken = ref(""); // Новое поле для хранения токена верификации

// Маскируем номер телефона для отображения
const maskedPhone = computed(() => {
  if (!phone.value) return "";
  
  // Маскируем средние цифры номера
  const prefix = phone.value.substring(0, 4);
  const suffix = phone.value.substring(phone.value.length - 2);
  const maskedPart = "*".repeat(phone.value.length - 6);
  
  return `${prefix}${maskedPart}${suffix}`;
});

// Получаем номер телефона из localStorage
onMounted(() => {
  phone.value = localStorage.getItem("registrationPhone") || "";
  
  // Если номер телефона не найден, перенаправляем на первый шаг
  if (!phone.value) {
    router.push("/register-phone");
    return;
  }
  
  // Устанавливаем фокус на поле ввода кода
  if (codeInput.value) {
    codeInput.value.focus();
  }
  
  // Запускаем обратный отсчет
  startCountdown();
});

// Очищаем интервал при уходе со страницы
onBeforeUnmount(() => {
  clearCountdownInterval();
});

// Запуск обратного отсчета
const startCountdown = () => {
  countdown.value = 60;
  clearCountdownInterval();
  
  countdownInterval.value = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value--;
    } else {
      clearCountdownInterval();
    }
  }, 1000);
};

// Очистка интервала
const clearCountdownInterval = () => {
  if (countdownInterval.value) {
    clearInterval(countdownInterval.value);
    countdownInterval.value = null;
  }
};

// Повторная отправка кода
const resendCode = async () => {
  if (countdown.value > 0) return;
  
  isLoading.value = true;
  errorMessage.value = "";
  
  try {
    // Отправляем запрос на повторную отправку кода
    await api.post("/auth/register/resend-code", {
      phone_number: phone.value
    });
    
    // Запускаем обратный отсчет заново
    startCountdown();
  } catch (error) {
    //console.error("Ошибка повторной отправки кода:", error);
    
    // Получаем детальную информацию об ошибке из ответа API
    if (error.response && error.response.data && error.response.data.detail) {
      errorMessage.value = error.response.data.detail;
    } else {
      errorMessage.value = "Не удалось отправить код повторно. Попробуйте позже.";
    }
  } finally {
    isLoading.value = false;
  }
};

// Проверка кода верификации
const verifyCode = async () => {
  isLoading.value = true;
  errorMessage.value = "";
  
  try {
    // Проверяем SMS-код через новый эндпоинт
    const response = await api.post("/auth/verify-code", {
      phone_number: phone.value,
      verification_code: verificationCode.value
    });
    
    // Сохраняем полученный токен верификации
    verificationToken.value = response.data.verification_token;
    
    // Сохраняем токен в localStorage для использования на следующем шаге
    localStorage.setItem("verificationToken", verificationToken.value);
    
    // Переходим на третий шаг (заполнение данных)
    router.push("/register-complete");
  } catch (error) {
    //.error("Ошибка проверки кода:", error);
    
    // Получаем детальную информацию об ошибке из ответа API
    if (error.response && error.response.data && error.response.data.detail) {
      errorMessage.value = error.response.data.detail;
    } else if (error.response?.data?.type === "INVALID_VERIFICATION_CODE") {
      errorMessage.value = "Неверный код подтверждения. Проверьте код и попробуйте снова.";
    } else if (error.response?.data?.type === "VERIFICATION_CODE_EXPIRED") {
      errorMessage.value = "Код подтверждения истек. Пожалуйста, запросите новый код.";
    } else {
      errorMessage.value = "Неверный код подтверждения. Проверьте код и попробуйте снова.";
    }
  } finally {
    isLoading.value = false;
  }
};

// Возврат к предыдущему шагу
const goBack = () => {
  router.push("/register-phone");
};
</script>
  
<style scoped>
.register-container {
  max-width: 350px;
  margin: 20px auto;
  text-align: center;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 100px;
  margin-bottom: 10px;
}

h2 {
  margin-bottom: 10px;
}

.step-text {
  color: #666;
  margin-bottom: 20px;
  font-size: 14px;
}

.sms-info {
  font-size: 14px;
  color: #333;
  margin: 15px 0;
  line-height: 1.5;
}

.input-group {
  text-align: left;
  margin-bottom: 15px;
  position: relative;
}

.input-group label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.code-input-container {
  display: flex;
  justify-content: center;
  margin: 5px 0;
}

.code-input {
  width: 100%;
  font-size: 24px;
  padding: 10px;
  text-align: center;
  letter-spacing: 5px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.countdown {
  font-size: 13px;
  color: #666;
  text-align: center;
  margin-top: 10px;
}

.resend {
  font-size: 13px;
  color: #F15E24;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  text-decoration: underline;
}

button {
  width: 100%;
  padding: 12px;
  background: #F15E24;
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: 500;
}

button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.back-button {
  background: transparent;
  color: #333;
  border: 1px solid #ddd;
  margin-top: 10px;
}

/* Стилизованный блок для ошибок */
.error-block {
  background-color: #ffebee;
  color: #e53935;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: left;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 1.4;
}

.error-icon {
  color: #e53935;
  margin-right: 10px;
  font-weight: bold;
}

.telegram-support {
  font-size: 13px;
  color: #666;
  margin: 10px 0;
  text-align: center;
}

.telegram-support a {
  color: #F15E24;
  text-decoration: underline;
}

@media (max-width: 380px) {
  .register-container {
    width: 90%;
    padding: 15px;
  }
}
</style>