import { createRouter, createWebHistory } from 'vue-router';
import CheckoutPage from '../pages/CheckoutPage.vue';
import CheckoutSuccessPage from '../pages/CheckoutSuccessPage.vue';
import HomePage from '../pages/HomePage.vue';
import NewsDetail from '../pages/NewsDetail.vue';
import FilterPage from '../pages/FilterPage.vue';
import ProductPage from '../pages/ProductPage.vue';
import NotFoundPage from '../pages/NotFoundPage.vue';

import Likepage from '@/pages/Likepage.vue';
import CartPage from "../pages/Cart.vue";
import Profilepage from '@/pages/Profilepage.vue'; 

import LoginPage from '@/pages/LoginPage.vue';
import RegisterPage from '@/pages/RegisterPage.vue';
import RegisterVerifyPage from '@/pages/RegisterVerifyPage.vue';
import RegisterCompletePage from '@/pages/RegisterCompletePage.vue';
import ForgotPasswordPage from '@/pages/ForgotPasswordPage.vue';

import Orders from '@/pages/Orders.vue';
import OrderDetails from "@/pages/OrderDetails.vue";
import OrderConfirmation from '@/pages/OrderConfirmation.vue';



const routes = [
    {
        path: '/',
        component: HomePage,
    },
    {
        path: '/login',
        component: LoginPage,
    },
    {
        path: '/register',
        component: RegisterPage,
    },
    {
        path: '/register-verify',
        component: RegisterVerifyPage,
    },
    {
        path: '/register-complete',
        component: RegisterCompletePage,
    },
    {
        path: '/forgot-password',
        component: ForgotPasswordPage,
    },
    {   path: '/news/:id',
        component: NewsDetail
    },
    {   path: '/filter', 
        component: FilterPage 
    },
    {
        path: '/product/:id',
        component: ProductPage,
    },
    {
        path: '/checkout',
        component: CheckoutPage,
    },
    {
        path: '/checkout/success',
        component: CheckoutSuccessPage,
    },
    {
        path: '/:notFound(.*)',
        component: NotFoundPage,
    },
    {
        path: "/likes",
        name: "Likepage",
        component: Likepage,
    },
    {
        path: "/cart",
        name: "Cart",
        component: CartPage,
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profilepage,
    },
    {
        path: "/orders",
        name: "Orders",
        component: Orders,
    },
    {
        path: '/order/:id',
        name: "OrderDetails",
        component: OrderDetails,
    },
    {
        path: '/order-confirmation',
        name: "OrderConfirmation",
        component: OrderConfirmation,
    }
    
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;