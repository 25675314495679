<template>
    <div class="filter-page">
      <h1>Фильтр товаров</h1>
      <CategoryTabs :items="categories" @onCategoryClick="handleCategoryClick" />
    </div>
  </template>
  
  <script setup>
  import CategoryTabs from '@/components/CategoryTabs.vue';
  import { ref, onMounted } from 'vue';
  import { useProductStore } from '../store/useProductStore';
  
  const productStore = useProductStore();
  const categories = ref([]);
  
  const handleCategoryClick = (category) => {
   // console.log(`Вы выбрали категорию: ${category}`);
  };
  
  onMounted(async () => {
    await productStore.fetchCategories();
    categories.value = productStore.allCategories;
  });
  </script>
  
  <style scoped>
  .filter-page {
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  </style>
  