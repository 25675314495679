<template>
    <div class="register-container">
      <img :src="Logo" alt="Логотип" class="logo" />
      
      <h2>Завершение регистрации</h2>
      <p class="step-text">Шаг 3 из 3</p>
  
      <form @submit.prevent="completeRegistration">
        <!-- Имя -->
        <div class="input-group">
          <label for="firstName">Имя</label>
          <input type="text" id="firstName" v-model="userData.firstName" required />
        </div>
  
        <!-- Email -->
        <div class="input-group">
          <label for="email">Email (необязательно)</label>
          <input type="email" id="email" v-model="userData.email" />
        </div>
  
        <!-- Пароль -->
        <div class="input-group password-group">
          <label for="password">Пароль</label>
          <input 
            :type="showPassword ? 'text' : 'password'" 
            id="password" 
            v-model="userData.password" 
            required 
            placeholder="Минимум 6 символов"
          />
          <span class="toggle-password" @click="showPassword = !showPassword">
            {{ showPassword ? "👁️" : "🙈" }}
          </span>
        </div>
  
        <!-- Повторите пароль -->
        <div class="input-group password-group">
          <label for="confirmPassword">Повторите пароль</label>
          <input 
            :type="showConfirmPassword ? 'text' : 'password'" 
            id="confirmPassword" 
            v-model="confirmPassword" 
            required 
          />
          <span class="toggle-password" @click="showConfirmPassword = !showConfirmPassword">
            {{ showConfirmPassword ? "👁️" : "🙈" }}
          </span>
        </div>
  
        <p v-if="passwordError" class="error-message">{{ passwordError }}</p>
  
        <!-- Соглашение с условиями -->
        <div class="checkbox-group">
          <input type="checkbox" id="terms" v-model="agreeToTerms" required />
          <label for="terms">Я согласен с <a href="#" @click.prevent="showTerms">условиями использования</a></label>
        </div>
  
        <button type="submit" :disabled="isLoading || !formIsValid">
          {{ isLoading ? "Создание аккаунта..." : "Завершить регистрацию" }}
        </button>
      </form>
  
      <button class="back-button" @click="goBack">
        Назад
      </button>
  
      <!-- Успешное сообщение -->
      <div v-if="successMessage" class="success-block">
        <span class="success-icon">✓</span>
        {{ successMessage }}
      </div>

      <!-- Сообщение об ошибке -->
      <div v-if="errorMessage" class="error-block">
        <span class="error-icon">✖</span>
        {{ errorMessage }}
      </div>
    </div>
</template>
  
<script setup>
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import api from "@/api";
import Logo from "@/assets/icon.png";
  
const router = useRouter();
  
const userData = ref({
  firstName: "",
  email: "",
  password: "",
  username: "",
  verificationToken: ""
});

const confirmPassword = ref("");
const showPassword = ref(false);
const showConfirmPassword = ref(false);
const isLoading = ref(false);
const errorMessage = ref("");
const successMessage = ref("");
const agreeToTerms = ref(false);

// Проверка паролей
const passwordError = computed(() => {
  if (!userData.value.password) return "";
  
  if (userData.value.password.length < 6) {
    return "❌ Пароль должен содержать минимум 6 символов";
  }
  
  if (confirmPassword.value && userData.value.password !== confirmPassword.value) {
    return "❌ Пароли не совпадают";
  }
  
  return "";
});

// Проверка валидности формы
const formIsValid = computed(() => {
  return (
    userData.value.firstName.trim() !== "" &&
    userData.value.password.length >= 6 &&
    userData.value.password === confirmPassword.value &&
    agreeToTerms.value
  );
});

// Получаем данные из предыдущих шагов
onMounted(() => {
  // Получаем номер телефона и токен верификации из localStorage
  const phoneNumber = localStorage.getItem("registrationPhone") || "";
  userData.value.verificationToken = localStorage.getItem("verificationToken") || "";
  
  // Используем номер телефона как имя пользователя
  userData.value.username = phoneNumber;
  
  // Если данные не найдены, перенаправляем на первый шаг
  if (!phoneNumber || !userData.value.verificationToken) {
    router.push("/register-phone");
  }
});

// Показать условия использования
const showTerms = () => {
  alert("Условия использования сервиса. Текст будет добавлен позже.");
};

// Завершение регистрации
const completeRegistration = async () => {
  isLoading.value = true;
  errorMessage.value = "";
  successMessage.value = "";
  
  try {
    // Запрос на завершение регистрации с использованием токена верификации
    await api.post("/auth/register/complete", {
      verification_token: userData.value.verificationToken,
      username: userData.value.username,
      password: userData.value.password,
      name: userData.value.firstName,
      private_first_name: userData.value.firstName,
      email: userData.value.email || undefined // Если email пустой, не отправляем
    });
    
    // Очищаем временные данные регистрации
    localStorage.removeItem("registrationPhone");
    localStorage.removeItem("verificationToken");
    
    // Показываем сообщение об успехе
    successMessage.value = "Регистрация успешно завершена! Перенаправляем на страницу входа...";
    
    // Через 2 секунды перенаправляем на страницу входа
    setTimeout(() => {
      router.push("/login");
    }, 2000);
  } catch (error) {
    //console.error("Ошибка завершения регистрации:", error);
    
    // Получаем детальную информацию об ошибке из ответа API
    if (error.response && error.response.data && error.response.data.detail) {
      errorMessage.value = error.response.data.detail;
    } else if (error.response?.data?.type === "VERIFICATION_TOKEN_EXPIRED") {
      errorMessage.value = "Время сессии истекло. Пожалуйста, начните регистрацию заново.";
      setTimeout(() => {
        router.push("/register-phone");
      }, 2000);
    } else {
      errorMessage.value = "Ошибка при регистрации. Пожалуйста, попробуйте снова.";
    }
  } finally {
    isLoading.value = false;
  }
};

// Возврат к предыдущему шагу
const goBack = () => {
  router.push("/register-verify");
};
</script>
  
<style scoped>
.register-container {
  max-width: 350px;
  margin: 20px auto;
  text-align: center;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 100px;
  margin-bottom: 10px;
}

h2 {
  margin-bottom: 10px;
}

.step-text {
  color: #666;
  margin-bottom: 20px;
  font-size: 14px;
}

.input-group {
  text-align: left;
  margin-bottom: 15px;
  position: relative;
}

.input-group label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

.input-group input[type="text"],
.input-group input[type="email"],
.input-group input[type="password"] {
  width: 100%;
  padding: 10px;
  font-size: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Стиль для группы с паролем */
.password-group {
  position: relative;
}

/* Кнопка отображения пароля */
.toggle-password {
  position: absolute;
  right: 10px;
  top: 35px;
  cursor: pointer;
  font-size: 18px;
}

/* Чекбокс с соглашением */
.checkbox-group {
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 15px;
}

.checkbox-group input[type="checkbox"] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.checkbox-group label {
  font-size: 13px;
}

.checkbox-group a {
  color: #F15E24;
  text-decoration: underline;
}

button {
  width: 100%;
  padding: 12px;
  background: #F15E24;
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: 500;
}

button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.back-button {
  background: transparent;
  color: #333;
  border: 1px solid #ddd;
  margin-top: 10px;
}

/* Стилизованный блок для ошибок */
.error-block {
  background-color: #ffebee;
  color: #e53935;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: left;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 1.4;
}

.error-icon {
  color: #e53935;
  margin-right: 10px;
  font-weight: bold;
}

/* Стилизованный блок для успешных сообщений */
.success-block {
  background-color: #e8f5e9;
  color: #4caf50;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: left;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 1.4;
}

.success-icon {
  color: #4caf50;
  margin-right: 10px;
  font-weight: bold;
}

@media (max-width: 380px) {
  .register-container {
    width: 90%;
    padding: 15px;
  }
}
</style>