import { defineStore } from "pinia";
import api from "../api";
import axios from 'axios';

export const useProductStore = defineStore("products", {
  state: () => ({
    products: [],
    categories: [],
    organizations: [],
    selectedOrganizationId: 1,
    ageOptions: [],
    currentPage: 0,
    totalPages: 1,
    hasMoreProducts: true,
    isLoading: false,
    error: {
      isError: false,
      message: null,
      errorCode: null,
    },
    selectedCategoryId: null,
    sortOrder: "NEWEST",
    userData: null,
    userLoading: true,
  }),

  getters: {
    isProducts: (state) => state.products.length > 0,
    allCategories: (state) => state.categories,
    allOrganizations: (state) => state.organizations,
    product: (state) => (id) => state.products.find((item) => item.id === +id),
    isWholesaleClient: (state) => state.userData && state.userData.cod_type_client === 'trade',
  },

  actions: {
    clearUserData() {
      this.userData = null;
      this.userLoading = false;
    },

    async fetchUserData() {
      try {
        const token = localStorage.getItem("authToken");
        
        if (!token) {
          this.clearUserData();
          return;
        }

        try {
          const tokenData = JSON.parse(atob(token.split('.')[1]));
          if (tokenData.exp * 1000 < Date.now()) {
            this.clearUserData();
            localStorage.removeItem("authToken");
            return;
          }
        } catch (e) {
          this.clearUserData();
          localStorage.removeItem("authToken");
          return;
        }
        
        const response = await api.get("/api/clients/me");
        this.userData = response.data || {};
        this.userLoading = false;
      } catch (err) {
        if (err.response?.status === 401) {
          localStorage.removeItem("authToken");
          this.clearUserData();
        } else {
          this.userLoading = false;
        }
      }
    },

    async fetchProducts({
      page = 0,
      size = 20,
      categoryList = null,
      searchText = null,
      sortOrder = null,
      ageList = null,
    } = {}) {
      try {
        this.isLoading = true;
        
        const params = new URLSearchParams();
        params.append("page", page);
        params.append("size", size);
        params.append("sortOrder", sortOrder || this.sortOrder);
        params.append("organizationId", this.selectedOrganizationId);
        if (ageList) params.append("ageList", ageList);
        if (categoryList) params.append("categoryList", categoryList);
        if (searchText && searchText.trim()) params.append("searchText", searchText.trim());
        
        const url = `api/client/products?${params.toString()}`;
        
        const response = await api.get(url);
        
        this.products = response.data.content || [];
        this.currentPage = response.data.pageable.pageNumber;
        this.totalPages = response.data.totalPages;
        this.hasMoreProducts = this.currentPage + 1 < this.totalPages;
      } catch (error) {
        this.error = {
          isError: true,
          message: error.message || "Ошибка загрузки товаров",
          errorCode: error.response?.status || null,
        };
      } finally {
        this.isLoading = false;
      }
    },

    async fetchAgeOptions() {
      try {
        const response = await api.get("/api/refs?type=AGE_LIST");
        this.ageOptions = response.data || [];
      } catch (error) {
        console.error("Ошибка загрузки списка возрастов:", error);
      }
    },

    async fetchOrganizations() {
      try {
        const response = await api.get("/api/client/organizations");
        this.organizations = response.data || [];
        if (this.organizations.length > 0 && !this.selectedOrganizationId) {
          this.selectedOrganizationId = this.organizations[0].id;
        }
      } catch (error) {
        console.error("Ошибка при загрузке организаций:", error);
        this.error = {
          isError: true,
          message: "Ошибка при загрузке организаций",
          errorCode: error.response?.status
        };
      }
    },

    async fetchCategories() {
      try {
        if (!this.selectedOrganizationId && this.organizations.length > 0) {
          this.selectedOrganizationId = this.organizations[0].id;
        }
        
        if (!this.selectedOrganizationId) {
          console.error("Не выбрана организация");
          return [];
        }

        const response = await api.get(`/api/client/products/filter/info?organizationId=${this.selectedOrganizationId}`);
        this.categories = response.data.categoryList || [];
        return this.categories;
      } catch (error) {
        console.error("Ошибка при загрузке категорий:", error);
        this.error = {
          isError: true,
          message: "Ошибка при загрузке категорий",
          errorCode: error.response?.status
        };
        return [];
      }
    },

    setSelectedCategory(categoryId) {
      this.selectedCategoryId = categoryId;
    },

    setSelectedOrganization(orgId) {
      this.selectedOrganizationId = orgId;
    },

    setSortOrder(sortOrder) {
      this.sortOrder = sortOrder;
      this.fetchProducts({ page: 0 });
    },
  },
});
