<template>
  <div class="likes-page">
    <!-- Хлебные крошки -->
    <div class="breadcrumbs">
      <router-link to="/" class="breadcrumb-link">Главная</router-link>
      <span class="breadcrumb-separator">›</span>
      <span class="breadcrumb-current">Корзина</span>
    </div>
    
    <!-- Основной контент -->
    <div class="likes-container">
      <!-- Левая панель навигации (только для десктопа) -->
      <div class="profile-sidebar desktop-only">
        <div class="menu-item" :class="{ active: activeTab === 'profile' }" @click="goToPage('/profile')">
          <img src="@/assets/profile.png" class="menu-icon" alt="Профиль" />
          <span>Профиль</span>
        </div>
        
        <div class="menu-item" :class="{ active: activeTab === 'orders' }" @click="goToPage('/orders')">
          <img src="@/assets/order.png" class="menu-icon" alt="Мои заказы" />
          <span>Мои заказы</span>
        </div>
        
        <div class="menu-item" :class="{ active: activeTab === 'likes' }" @click="goToPage('/likes')">
          <img src="@/assets/like.png" class="menu-icon" alt="Избранное" />
          <span>Избранное</span>
        </div>
        
        <div class="menu-item" :class="{ active: activeTab === 'cart' }" @click="setActiveTab('cart')">
          <img src="@/assets/buy.png" class="menu-icon" alt="Корзина" />
          <span>Корзина</span>
        </div>
      </div>

      <!-- Основная область контента -->
      <div class="likes-content">
        <h1 class="likes-title">Корзина</h1>
        
        <!-- 🔹 Уведомление о незавершенном заказе -->
        <div v-if="pendingOrderStore.hasPendingOrder" class="cart__pending-order">
          <p>У вас есть незавершенный заказ, пожалуйста, подтвердите его.</p>
          <p>Время подтверждения: <strong>{{ pendingOrderStore.remainingTime }} мин</strong></p>
          <router-link to="/order-confirmation" class="cart__pending-link">Перейти к подтверждению</router-link>
        </div>
        
        <div v-if="isLoading" class="loader">Загрузка...</div>

        <!-- Если корзина пуста -->
        <div v-if="cart.length === 0 && !isLoading" class="empty-cart">
          <img src="@/assets/icon.png" alt="Пусто" class="empty-icon" />
          <p class="empty-text">Ваша корзина пока пуста</p>
          <p class="empty-subtext">Добавьте товары в корзину, чтобы оформить заказ</p>
          <button class="catalog-btn" @click="goToPage('/')">Перейти в каталог</button>
        </div>

        <!-- Список товаров в корзине -->
        <ul v-else-if="cart.length > 0" class="cart__list">
          <li
            class="cart__item"            
            v-for="product in cart"
            :key="product.id"
          >
            <div class="cart__image-wrapper">
              <img
                :src="product.image || NoImage"
                class="cart__img"
                :alt="product.title || 'Изображение товара'"
                @click="goToProductPage(product.id)"
              />
              <!-- Показываем остатки только для оптовых клиентов -->
              <div v-if="isWholesaleClient" class="cart__stock-info">
                <p :class="{ 'cart__reserve--empty': product.reservedCount > 0 }">
                  В резерве: {{ product.reservedCount || 0 }} шт
                </p>
                <p>В наличии: {{ product.remainedCount || 0 }} шт</p>
              </div>
            </div>

            <div class="cart__details">
              <div class="cart__info">
                <!-- Итоговая цена за все единицы товара -->
                <p class="cart__price" :class="{ 'wholesale-price': isWholesaleClient }">
                  {{ formatPrice(getProductPrice(product) * product.quantity) }}
                </p>
                
                <!-- Цены для оптовых клиентов -->
                <div class="cart__price-container" v-if="isWholesaleClient">
                  <!-- Если есть скидка на оптовую цену -->
                  <template v-if="isTradeSalePrice(product)">
                    <p class="cart__price-per-unit cart__sale-price wholesale-price">{{ formatPrice(product.sale_trade_price) }}/шт</p>
                    <p class="cart__price-per-unit cart__old-price">{{ formatPrice(product.tradePrice) }}/шт</p>
                  </template>
                  <!-- Если скидки на оптовую цену нет -->
                  <p v-else class="cart__price-per-unit wholesale-price">{{ formatPrice(product.tradePrice) }}/шт</p>
                </div>
                
                <!-- Цены для розничных клиентов -->
                <div class="cart__price-container" v-else>
                  <!-- Если есть скидка на розничную цену -->
                  <template v-if="isRetailSalePrice(product)">
                    <p class="cart__price-per-unit cart__sale-price">{{ formatPrice(product.sale_retail_price) }}/шт</p>
                    <p class="cart__price-per-unit cart__old-price">{{ formatPrice(product.retailPrice) }}/шт</p>
                  </template>
                  <!-- Если скидки на розничную цену нет -->
                  <p v-else class="cart__price-per-unit">{{ formatPrice(product.retailPrice) }}/шт</p>
                </div>
                
                <p class="cart__title-text">{{ product.title }}</p>
              </div>

              <div v-if="product.remainedCount" class="cart__quantity">
                <button @click="decrementQuantity(product)">-</button>
                <input type="number" v-model="product.quantity" min="1" :max="product.remainedCount" />
                <button @click="incrementQuantity(product)">+</button>
              </div>

              <div v-else class="cart__out-of-stock">Нет в наличии</div>
            </div>

            <BaseIconButton
              @click="removeProductFromCart(product.id)"
              variant="contained"
              iconHoverColor="#ef2525"
              iconColor="#74747474"
              opacity="1"
            >
              <DeleteIcon />
            </BaseIconButton>
          </li>
        </ul>

        <!-- Итоговая информация о заказе (фиксированная внизу) -->
        <div v-if="cart.length > 0" class="cart__summary">
          <p class="cart__summary-text">
            В корзине: <strong>{{ totalProducts }} товар</strong> | Всего: <strong>{{ formatPrice(totalPrice) }}</strong>
          </p>
          <!-- Разные сообщения в зависимости от типа клиента -->
          <p v-if="isWholesaleClient" :class="minOrderClass">
            Минимальная сумма заказа: 50 000 тг
          </p>
          <p v-else :class="{ 'cart__min-order--warning': totalPrice < 10000, 'cart__free-delivery': totalPrice >= 10000 }">
            При заказе от 10 000 тенге, доставка бесплатная
          </p>
          <button class="cart__checkout" @click="proceedToCheckout">Оформить сейчас</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DeleteIcon from "../components/icons/DeleteIcon.vue";
import BaseIconButton from "../components/UI/Buttons/BaseIconButton.vue";
import { computed, onMounted, ref } from "vue";
import { useCartStore } from "@/store/useCartStore";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import NoImage from "@/assets/icon.png";
import { usePendingOrderStore } from "@/store/usePendingOrderStore";
import api from "@/api";

const cartStore = useCartStore();
const { cart } = storeToRefs(cartStore);
const isLoading = ref(true);
const router = useRouter();
const pendingOrderStore = usePendingOrderStore();
const remainingTime = ref(0);
const activeTab = ref('cart');
const userData = ref(null);
const userLoading = ref(true);

// Проверка, является ли клиент оптовым
const isWholesaleClient = computed(() => {
  return userData.value && userData.value.cod_type_client === 'trade';
});

// Функция для установки активной вкладки
const setActiveTab = (tab) => {
    activeTab.value = tab;
};

// Функция для перехода на другую страницу
const goToPage = (path) => {
    router.push(path);
};

// Функция для определения, есть ли акционная розничная цена
const isRetailSalePrice = (product) => {
  return product.sale_retail_price && 
         product.sale_retail_price > 0 && 
         product.sale_retail_price < product.retailPrice;
};

// Функция для определения, есть ли акционная оптовая цена
const isTradeSalePrice = (product) => {
  return product.sale_trade_price && 
         product.sale_trade_price > 0 && 
         product.sale_trade_price < product.tradePrice;
};

// Функция для получения актуальной цены товара в зависимости от типа клиента
const getProductPrice = (product) => {
  if (isWholesaleClient.value) {
    // Для оптового клиента
    if (isTradeSalePrice(product)) {
      return product.sale_trade_price;
    }
    return product.tradePrice || 0;
  } else {
    // Для розничного клиента
    if (isRetailSalePrice(product)) {
      return product.sale_retail_price;
    }
    return product.retailPrice || 0;
  }
};

const updateRemainingTime = () => {
  if (pendingOrderStore.expiryTime) {
    remainingTime.value = Math.max(0, Math.floor((pendingOrderStore.expiryTime - Date.now()) / 1000 / 60));
    if (remainingTime.value === 0) pendingOrderStore.clearOrder();
  }
};

const goToOrderConfirmation = () => {
  router.push("/order-confirmation");
};

// Получение данных пользователя
const fetchUserData = async () => {
  try {
    const token = localStorage.getItem("authToken");
    
    if (!token) {
      userLoading.value = false;
      return;
    }
    
    const response = await api.get("/api/clients/me");
    userData.value = response.data || {};
    //console.log("Тип клиента:", userData.value.cod_type_client);
    
    // Сохраняем данные пользователя для использования в store
    localStorage.setItem('userData', JSON.stringify(userData.value));
    
    userLoading.value = false;
  } catch (err) {
    //console.error("❌ Ошибка загрузки данных пользователя:", err);
    userLoading.value = false;
  }
};

// ✅ Загружаем данные корзины при монтировании
onMounted(() => {
  pendingOrderStore.loadPendingOrder(); 
  updateRemainingTime();
  setInterval(updateRemainingTime, 1000);
  
  Promise.all([
    cartStore.fetchCartProducts(),
    fetchUserData()
  ]).then(() => {
    isLoading.value = false;
  });
});

// Расчет общего количества товаров в корзине
const totalProducts = computed(() => cart.value.reduce((sum, product) => sum + (product.quantity || 1), 0));

// Расчет общей суммы корзины с учетом акционных цен и типа клиента
const totalPrice = computed(() => 
  cart.value.reduce((sum, product) => {
    const price = getProductPrice(product);
    return sum + price * (product.quantity || 1);
  }, 0).toFixed(2)
);

const incrementQuantity = (product) => {
  if (product.quantity < product.remainedCount) {
    cartStore.incrementQuantity(product.id);
  }
};

const decrementQuantity = (product) => {
  if (product.quantity > 1) {
    cartStore.decrementQuantity(product.id);
  }
};

const removeProductFromCart = (id) => {
  cartStore.removeProductFromCart(id);
};

const goToProductPage = (id) => {
  router.push(`/product/${id}`);
};

const minOrderClass = computed(() => {
  return totalPrice.value < 50000 ? "cart__min-order--warning" : "cart__min-order";
});

const proceedToCheckout = () => {
    const token = localStorage.getItem("authToken"); // Получаем токен

    // Если нет токена - перекидываем на страницу авторизации
    if (!token) {
        router.push("/login");
        return;
    }

    const validCartItems = cart.value
        .filter(product => product.remainedCount > 0)
        .map(product => ({
            id: product.id,
            title: product.title,
            // Копируем оба типа цен - и розничные, и оптовые
            retailPrice: product.retailPrice,
            sale_retail_price: product.sale_retail_price,
            tradePrice: product.tradePrice,
            sale_trade_price: product.sale_trade_price,
            // Сохраняем рассчитанную цену в зависимости от типа клиента
            price: getProductPrice(product),
            // Сохраняем тип клиента для последующей обработки
            clientType: isWholesaleClient.value ? 'trade' : 'retail',
            // Копируем изображение
            image: product.image || NoImage,
            // Копируем количество товаров
            quantity: Math.min(product.quantity, product.remainedCount),
            // Копируем оставшуюся информацию
            remainedCount: product.remainedCount,
            reservedCount: product.reservedCount
        }));

    if (validCartItems.length === 0) {
        alert("❌ В корзине нет доступных товаров для заказа!");
        return;
    }

    // Сохраняем заказ перед переходом
    pendingOrderStore.savePendingOrder(validCartItems);

    // Кодируем JSON для безопасной передачи
    const encodedItems = encodeURIComponent(JSON.stringify(validCartItems));

    // Очистка корзины перед оформлением заказа
    cartStore.clearCart();

    router.push(`/order-confirmation?items=${encodedItems}`);
};

// Форматируем цену
const formatPrice = (price) => {
  if (!price && price !== 0) return "0 тг";
  return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} тг`;
};

</script>
<style scoped>
.likes-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
}

.breadcrumbs {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 14px;
  background-color: white;
}

.breadcrumb-link {
  color: #666;
  text-decoration: none;
}

.breadcrumb-link:hover {
  color: #F15E24;
}

.breadcrumb-separator {
  margin: 0 8px;
  color: #666;
}

.breadcrumb-current {
  color: #333;
  font-weight: 500;
}

.likes-container {
  display: flex;
  gap: 30px;
  background-color: white;
}

.profile-sidebar {
  width: 250px;
  flex-shrink: 0;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.menu-item:hover {
  background-color: #f5f5f5;
}

.menu-item.active {
  background-color: #F15E24;
  color: white;
}

.menu-item.active .menu-icon {
  filter: brightness(0) invert(1);
}

.menu-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.likes-content {
  flex: 1;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  position: relative;
  padding-bottom: 100px; /* Место для плавающего итога */
}

.likes-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
}

/* Пустая корзина */
.empty-cart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  text-align: center;
  background-color: white;
}

.empty-icon {
  width: 200px;
  height: 80px;
  margin-bottom: 20px;
  opacity: 0.5;
}

.empty-text {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.empty-subtext {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  max-width: 300px;
}

.catalog-btn {
  background: #F15E24;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.catalog-btn:hover {
  background: #d64d14;
}

/* Лоадер */
.loader {
  text-align: center;
  padding: 20px;
  color: #666;
  font-size: 16px;
  background-color: white;
}

/* Стили корзины */
.cart__list {
  list-style: none;
  padding: 0;
  margin-bottom: 80px; /* Отступ для итоговой информации */
  background-color: white;
}

.cart__item {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.cart__image-wrapper {
  flex: 0 0 120px;
  position: relative;
}

.cart__img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  cursor: pointer;
}

.cart__stock-info {
  font-size: 12px;
  color: #555;
  text-align: center;
  margin-top: 5px;
}

.cart__reserve--empty {
  color: red;
}

.cart__details {
  flex: 1;
  padding: 0 10px;
}

.cart__info {
  margin-bottom: 5px;
}

.cart__price {
  font-size: 18px;
  font-weight: bold;
}

/* Стили для оптовых цен */
.wholesale-price {
  color: #3498db !important; /* Синий цвет для оптовых цен */
}

/* Стили для контейнера цен */
.cart__price-container {
  display: flex;
  flex-direction: column;
}

/* Стили для акционной цены */
.cart__sale-price {
  color: #F15E24;
  font-weight: bold;
}

/* Стили для старой цены */
.cart__old-price {
  text-decoration: line-through;
  color: #777;
  font-size: 11px;
}

.cart__price-per-unit {
  font-size: 12px;
  color: #777;
}

.cart__title-text {
  font-size: 14px;
}

.cart__quantity {
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
}

.cart__quantity button {
  background: #F15E24;
  color: white;
  border: none;
  width: 25px;
  height: 25px;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.cart__quantity input {
  width: 40px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 3px;
}

.cart__out-of-stock {
  color: red;
  font-size: 14px;
  text-align: center;
}

/* Уведомление о незавершенном заказе */
.cart__pending-order {
  background: #fffbcc;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 15px;
  border: 1px solid #f0e68c;
}

.cart__pending-link {
  color: #F15E24;
  text-decoration: none;
  font-weight: bold;
}

.cart__pending-link:hover {
  text-decoration: underline;
}

/* Итоговая информация */
.cart__summary {
  position: fixed;
  bottom: 65px; /* Оставляем место для BottomNav */
  left: 0;
  right: 0;
  max-width: 1000px;
  margin: 0 auto;
  padding: 15px;
  background: white;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 900;
  border-radius: 10px 10px 0 0;
}

.cart__summary-text {
  font-size: 16px;
  margin-bottom: 5px;
}

.cart__min-order {
  font-size: 14px;
  color: #666;
  margin-bottom: 10px;
}

.cart__min-order--warning {
  color: red;
  font-weight: bold;
}

.cart__free-delivery {
  color: #4CAF50;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
}

/* Изменение стиля кнопки оформления заказа */
.cart__checkout {
  background: #F15E24;
  color: white;
  border: none;
  padding: 12px;
  width: 100%;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cart__checkout:hover {
  background: #d64d14;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #F15E24; 
  border-top: 1px solid #ddd;
  padding: 8px 0;
  z-index: 1000;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.bottom-nav__button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-size: 10px;
  color: #fff; 
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 5px 0;
  flex: 1;
}

.bottom-nav__button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.bottom-nav__button .nav-icon {
  width: 22px;
  height: 22px;
  filter: brightness(0) invert(1); /* Делает иконки белыми */
}

.bottom-nav__button span {
  font-size: 10px;
  font-weight: 500;
}

/* Стили для значков с количеством товаров */
.badge {
  position: absolute;
  top: 0;
  right: 30%;
  background: #FFFF;
  color: #F15E24;
  font-size: 10px;
  font-weight: bold;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Адаптивные стили для мобильной версии */
@media (max-width: 768px) {
  /* Важные исправления основного контейнера */
  .likes-page {
    padding: 0;
    margin: 0;
    width: 100%;
    padding-bottom: 120px; /* Увеличенный отступ для BottomNav и итога */
    background-color: white !important;
    border: none !important;
    outline: none !important;
    box-sizing: border-box;
    overflow: hidden;
  }
  
  /* Исправления для хлебных крошек */
  .breadcrumbs {
    padding: 10px;
    margin-bottom: 0;
    background-color: white;
  }
  
  /* Исправления для контейнера содержимого */
  .likes-container {
    flex-direction: column;
    width: 100%;
    gap: 0;
    background-color: white !important;
    border: none !important;
    outline: none !important;
  }
  
  /* Исправления для основного блока контента */
  .likes-content {
    width: 100%;
    margin: 0;
    padding: 10px;
    border-radius: 0;
    box-shadow: none;
    background-color: white !important;
  }
  
  /* Скрываем боковое меню на мобильных */
  .desktop-only {
    display: none;
  }
  
  /* Стиль заголовка */
  .likes-title {
    font-size: 20px;
    margin: 0 0 15px 0;
    padding: 5px;
  }
  
  /* Стиль для карточки товара в корзине */
  .cart__item {
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background: #f5f5f5;
    margin-bottom: 10px;
    gap: 15px;
  }
  
  /* Стиль для изображения товара */
  .cart__image-wrapper {
    flex: 0 0 90px;
    width: 90px;
    margin-bottom: 0;
  }
  
  .cart__img {
    max-height: 80px;
    width: 100%;
    object-fit: contain;
  }
  
  /* Стиль для информации о наличии */
  .cart__stock-info {
    font-size: 11px;
    margin-top: 3px;
    text-align: left;
  }
  
  /* Стиль для блока с информацией о товаре */
  .cart__details {
    flex: 1;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
  }
  
  /* Стиль для заголовка товара */
  .cart__title-text {
    font-size: 14px;
    margin-bottom: 5px;
    order: -1;
  }
  
  /* Стиль для блока с ценой */
  .cart__info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  /* Стиль для количества товара */
  .cart__quantity {
    align-self: flex-start;
    margin-top: 5px;
  }
  
  /* Стиль для кнопок управления количеством */
  .cart__quantity button {
    width: 24px;
    height: 24px;
  }
  
  .cart__quantity input {
    width: 35px;
    height: 24px;
  }
  
  /* Стиль для блока "нет в наличии" */
  .cart__out-of-stock {
    align-self: flex-start;
  }
  
  /* Стиль для цены */
  .cart__price {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  /* Стиль для цены за единицу */
  .cart__price-per-unit {
    font-size: 11px;
  }
  
  /* Стиль для кнопки удаления */
  .cart__item > .base-icon-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  
  /* Исправления для пустой корзины */
  .empty-cart {
    padding: 30px 0;
  }
  
  .empty-icon {
    width: 200px;
    height: 60px;
  }
  
  .empty-text {
    font-size: 16px;
  }
  
  .empty-subtext {
    font-size: 13px;
    margin-bottom: 15px;
  }
  
  .catalog-btn {
    padding: 10px 16px;
    font-size: 14px;
  }
  
  /* Убираем все возможные рамки и внешние стили */
  * {
    outline: none !important;
  }
  
  /* Исправления для итоговой информации */
  .cart__summary {
    border-radius: 0;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 60px; /* Оставляем место для BottomNav */
    box-sizing: border-box;
  }
}
</style>