export const INPUT_FIELDS = [
    {
        placeholder: "Full name",
        label: "Full name",
        type: "text",
        name: "name",
        required: true,
    },
    {
        placeholder: "Your cell phone",
        label: "Phone",
        type: "text",
        name: "phone",
        required: true,
    },
    {
        placeholder: "Your e-mail",
        label: "E-mail",
        type: "text",
        name: "email",
        required: false,
    },
    {
        placeholder: "Delivery city and country",
        label: "Delivery region",
        type: "text",
        name: "location",
        required: false,
    },
    {
        placeholder: "Delivery address",
        label: "Delivery address",
        type: "text",
        name: "address",
        required: false,
    },
    {
        placeholder: "Comment",
        label: "Comment",
        type: "text",
        name: "comment",
        required: false,
    },
];