<template>
  <div v-if="news" class="news-detail">
    <!-- Картинка на всю ширину -->
    <img
      v-if="mainImage"
      :src="mainImage"
      alt="Новость"
      class="news-image"
    />

    <!-- Контейнер с текстом -->
    <div class="news-content">
      <h1 class="news-title">{{ news.title }}</h1>
      <p class="news-description">{{ news.description }}</p>
    </div>

    <!-- Дата создания вынесена за основной контейнер -->
    <p class="news-date">{{ formattedDate }}</p>
  </div>

  <div v-else class="loading-message">
    Загрузка новости...
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useRoute } from "vue-router";
import api from "@/api";

const route = useRoute();
const news = ref(null);
const newsId = route.params.id;

// Функция для получения данных о новости
const fetchNewsDetails = async () => {
  try {
    const response = await api.get(`/api/client/news/${newsId}`);
    news.value = response.data;
    //console.log("📰 Загруженная новость:", news.value);
  } catch (error) {
    //console.error("❌ Ошибка при загрузке новости:", error);
  }
};

// ✅ Форматируем дату
const formattedDate = computed(() => {
  if (!news.value || !news.value.createdInfo) return "Неизвестно";
  return new Date(news.value.createdInfo.createdOn).toLocaleDateString("ru-RU", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
});

// ✅ Получаем ссылку на главное изображение
const mainImage = computed(() => {
  if (!news.value?.imageSet || news.value.imageSet.length === 0) return null;
  const mainImg = news.value.imageSet.find((img) => img.isMain);
  return mainImg ? mainImg.fileInfo.link : null;
});

// Вызываем функцию при монтировании компонента
onMounted(() => {
  if (newsId) {
    fetchNewsDetails();
  }
});
</script>

<style scoped>
/* Контейнер новости */
.news-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 20px;
  font-family: "Nunito", sans-serif;
  background-color: #fff;
}

/* Картинка на всю ширину */
.news-image {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
  border-radius: 0;
}

/* Контейнер текста */
.news-content {
  background: #f5f5f5;
  padding: 16px;
  border-radius: 8px;
  width: 100%;
}

/* Заголовок новости (ниже картинки) */
.news-title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 12px;
}

/* Описание новости */
.news-description {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}

/* Дата создания вынесена за контейнер, вправо */
.news-date {
  font-size: 14px;
  color: #888;
  text-align: right;
  width: 100%;
  margin-top: -10px;
  padding-right: 10px;
}

/* Лоадер */
.loading-message {
  text-align: center;
  font-size: 18px;
  color: #555;
}
</style>
