import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router/router';

import { create, NCarousel, NCarouselItem } from 'naive-ui';

const naive = create({
  components: [NCarousel, NCarouselItem],
});

const app = createApp(App);

const pinia = createPinia();
app.use(router);
app.use(pinia);
app.use(naive);
app.mount('#app');
