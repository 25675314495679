<template>
  <div class="categories-wrapper">
    <div :class="['categories-container', { 'categories-container_desktop': !isMobile }]">
      <ul :class="['categories-list', { 'categories-list_desktop': !isMobile }]">
        <li
          v-for="category in allCategories"
          :key="category.id"
          :class="['categories-list__item', { 'categories-list__item_active': isActiveTab === category.name }]"
          @click="setActiveTab(category.name)"
        >
          {{ category.name.toUpperCase() }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";

const emit = defineEmits(["onCategoryClick"]);
const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
});

const allCategories = computed(() => [
  { id: 0, name: "Все" },
  ...props.items,
]);

let isActiveTab = ref("Все");
const isMobile = ref(window.innerWidth <= 768);

const setActiveTab = (categoryName) => {
  isActiveTab.value = categoryName;
  emit("onCategoryClick", categoryName);
};

onMounted(() => {
  window.addEventListener("resize", () => {
    isMobile.value = window.innerWidth <= 768;
  });
});
</script>

<style scoped>
.categories-wrapper {
  width: 100%;
  overflow: hidden;
  background: #fff;
  padding: 2px;
}

/* ✅ Для мобильных - горизонтальный скролл */
.categories-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
  display: flex;
  align-items: center;
  background-color: #fff;
  max-width: 100vw;
}
.categories-container::-webkit-scrollbar {
  display: none;
}

/* ✅ Для десктопов - перенос на новую строку */
.categories-container_desktop {
  overflow-x: visible;
  flex-wrap: wrap;
  justify-content: center;
}
/* Список категорий */
.categories-list {
  list-style: none;
  display: flex;
  gap: 5px;
  padding: 5px;
}

/* ✅ Для десктопов - перенос категорий на следующую строку */
.categories-list_desktop {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
/* Элементы списка */
.categories-list__item {
  flex: 0 0 auto;
  padding: 5px;
  gap: 5px;
  background-color: #ececec;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: #333;
  text-align: center;
  transition: background 0.3s ease;
}
/* Активный элемент */
.categories-list__item_active {
  background-color: #F15E24;
  color: #ffffff;
}
</style>
