<template>
  <div class="register-container">
    <img :src="Logo" alt="Логотип" class="logo" />
    
    <h2>Регистрация</h2>
    <p class="step-text">Шаг 1 из 3</p>

    <form @submit.prevent="requestVerificationCode">
      <!-- Телефон -->
      <div class="input-group">
        <label for="phone">Номер телефона</label>
        <vue-tel-input
          v-model="phoneNumber"
          v-bind="phoneOptions"
          @input="validatePhone"
          class="phone-input"
        />
      </div>
      
      <p class="sms-info">Мы отправим SMS-код для подтверждения номера телефона</p>

      <button type="submit" :disabled="isLoading || phoneError">
        {{ isLoading ? "Отправка..." : "Получить SMS-код" }}
      </button>
    </form>

    <p class="login-link">Уже есть аккаунт? <router-link to="/login">Войти</router-link></p>

    <!-- Ошибка с красным фоном и иконкой -->
    <div v-if="errorMessage" class="error-block">
      <span class="error-icon">✖</span>
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import api from "@/api";
import Logo from "@/assets/icon.png";

const router = useRouter();

const phoneNumber = ref("");
const phoneError = ref(false);
const isLoading = ref(false);
const errorMessage = ref("");

// Опции для телефонного ввода
const phoneOptions = {
  mode: "international",
  autoFormat: true,
  placeholder: "Введите номер телефона",
  preferredCountries: ["ru", "kz", "kg"],
  onlyCountries: ["ru", "kz", "kg"],
  validCharactersOnly: true,
  inputOptions: {
    showDialCode: true,
  },
};

// Функция проверки номера телефона
const validatePhone = (phoneNumber) => {
  if (!phoneNumber) {
    phoneError.value = true;
    return;
  }

  // Приводим к строке, если передано число
  const phoneString = String(phoneNumber);

  // Очищаем номер от пробелов и скобок
  const cleanPhone = phoneString.replace(/\D/g, ""); // Убираем всё, кроме цифр

  //console.log("Проверяемый номер:", cleanPhone);

  // Проверяем код страны и количество цифр
  if (cleanPhone.startsWith("7") && cleanPhone.length !== 11) {
    phoneError.value = true;
  } else if (cleanPhone.startsWith("996") && cleanPhone.length !== 12) {
    phoneError.value = true;
  } else {
    phoneError.value = false; // Всё в порядке
  }
};

// Запрос кода верификации
const requestVerificationCode = async () => {
  // Сбрасываем сообщение об ошибке
  errorMessage.value = "";
  
  // Проверяем номер телефона
  if (phoneError.value || !phoneNumber.value) {
    errorMessage.value = "Ошибка отправки SMS. Проверьте номер телефона и попробуйте снова.";
    return;
  }
  
  isLoading.value = true;

  try {
    let formattedPhone = String(phoneNumber.value || "").replace(/\D/g, "");

    // Преобразуем номер, если начинается с 8 (для России/Казахстана)
    if (formattedPhone.startsWith("8") && formattedPhone.length === 11) {
      formattedPhone = "7" + formattedPhone.slice(1); // 8 → 7
    }

    // Запрос на API для отправки SMS
    await api.post("/auth/register/start", {
      phone_number: formattedPhone
    });

    // Сохраняем номер телефона и переходим к следующему шагу
    localStorage.setItem("registrationPhone", formattedPhone);
    
    // Переход на страницу ввода СМС-кода
    router.push("/register-verify");
  } catch (error) {
    //console.error("Ошибка запроса SMS:", error);
    
    // Получаем детальную информацию об ошибке из ответа API
    if (error.response && error.response.data && error.response.data.detail) {
      // Используем точный текст ошибки из API
      errorMessage.value = error.response.data.detail;
    } else {
      // Запасной вариант, если поле detail отсутствует
      errorMessage.value = "Ошибка отправки SMS. Проверьте номер телефона и попробуйте снова.";
    }
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>
.register-container {
  max-width: 350px;
  margin: 20px auto;
  text-align: center;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 100px;
  margin-bottom: 10px;
}

h2 {
  margin-bottom: 10px;
}

.step-text {
  color: #666;
  margin-bottom: 20px;
  font-size: 14px;
}

.sms-info {
  font-size: 13px;
  color: #555;
  margin: 10px 0;
  text-align: center;
}

.input-group {
  text-align: left;
  margin-bottom: 15px;
  position: relative;
}

.input-group label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}

/* Стили для Vue Tel Input */
:deep(.vue-tel-input) {
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px;
}

:deep(.vue-tel-input:focus-within) {
  border-color: #0A1F44;
}

:deep(.vti__dropdown) {
  border-radius: 5px 0 0 5px;
  border: none;
}

:deep(.vti__input) {
  border: none;
  font-size: 16px;
  padding: 10px;
  width: 100%;
}

button {
  width: 100%;
  padding: 12px;
  background: #F15E24;
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: 500;
}

button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

/* Стилизованный блок для ошибок, как на скриншоте */
.error-block {
  background-color: #ffebee;
  color: #e53935;
  padding: 15px;
  border-radius: 5px;
  margin-top: 20px;
  text-align: left;
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 1.4;
}

.error-icon {
  color: #e53935;
  margin-right: 10px;
  font-weight: bold;
}

.login-link {
  margin-top: 20px;
  font-size: 14px;
}

@media (max-width: 380px) {
  .register-container {
    width: 90%;
    padding: 15px;
  }
}
</style>