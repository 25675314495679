<template>
  <header class="header">
    <router-link
      to="/"
      :class="[ 
        'header__logo',
        isHomepage ? 'header__logo-disabled' : 'header__logo_active',
      ]"
      disabled="true"
    >
      <img src="@/assets/icon.png" class="header__logo" />
    </router-link>

    <div class="header__right">
      <!-- WhatsApp кнопка -->
      <a 
        href="https://wa.me/77026505050?text=Интересует%20оптовая%20закупка" 
        target="_blank"
        class="whatsapp-button"
      >
        <div class="whatsapp-icon">
          <img src="@/assets/whatsapp.png" alt="WhatsApp" />
        </div>
        <span class="whatsapp-text">
          {{ !isAuthenticated ? 'связаться с менеджером' : (isWholesaleClient ? 'связаться с менеджером' : 'стать оптовым клиентом от 50 000 ₸') }}
        </span>
      </a>

      <ul>
        <!-- Если пользователь не авторизован -->
        <template v-if="!isAuthenticated">
          <!-- Значок с лайками -->
          <li>
            <button class="header-button" @click="goToPage('/likes')">
              <div class="header__icon-container">
                <img src="@/assets/like.png" class="header__icon" alt="Лайки" />
              </div>
              <span v-if="totalLikes" class="header-badge">{{ totalLikes }}</span>
            </button>
          </li>

          <!-- Значок корзины - переход на страницу корзины -->
          <li>
            <button class="header-button" @click="goToPage('/cart')">
              <div class="header__icon-container">
                <img src="@/assets/buy.png" class="header__icon" alt="Корзина" />
              </div>
              <span v-if="cartQuantity" class="header-badge">{{ cartQuantity }}</span>
            </button>
          </li>

          <!-- Значок профиля -->
          <li>
            <button class="header-button" @click="goToPage('/login')">
              <div class="header__icon-container">
                <img src="@/assets/profile.png" class="header__icon" alt="Профиль" />
              </div>
            </button>
          </li>
        </template>
        
        <!-- Показываем элементы только если пользователь авторизован -->
        <template v-else>
          <!-- Значок с лайками -->
          <li>
            <button class="header-button" @click="goToPage('/likes')">
              <div class="header__icon-container">
                <img src="@/assets/like.png" class="header__icon" alt="Лайки" />
              </div>
              <span v-if="totalLikes" class="header-badge">{{ totalLikes }}</span>
            </button>
          </li>

          <!-- Значок корзины -->
          <li>
            <button class="header-button" @click="goToPage('/cart')">
              <div class="header__icon-container">
                <img src="@/assets/buy.png" class="header__icon" alt="Корзина" />
              </div>
              <span v-if="cartQuantity" class="header-badge">{{ cartQuantity }}</span>
            </button>
          </li>

          <!-- Значок профиля -->
          <li>
            <button class="header-button" @click="goToPage('/profile')">
              <div class="header__icon-container" :class="{ 'wholesale-profile': isWholesaleClient }">
                <img src="@/assets/profile.png" class="header__icon" alt="Профиль" />
              </div>
            </button>
          </li>

          <!-- Значок заказов -->
          <li>
            <button class="header-button" @click="goToPage('/orders')">
              <div class="header__icon-container">
                <img src="@/assets/order.png" class="header__icon" alt="Мои заказы" />
              </div>
            </button>
          </li>

          <!-- Кнопка выхода - только для десктопа -->
          <li class="desktop-only">
            <button class="header-button logout-button" @click="confirmLogout">
              <div class="header__icon-container logout-container">
                <img src="@/assets/logout-icon.svg" class="header__icon" alt="Выйти" />
              </div>
            </button>
          </li>
        </template>
      </ul>
    </div>

    <!-- Модальное окно подтверждения выхода -->
    <div v-if="isLogoutModalOpen" class="logout-modal">
      <div class="logout-modal-content">
        <h3>Выход из аккаунта</h3>
        <p>Вы действительно хотите выйти?</p>
        <div class="logout-modal-buttons">
          <button class="confirm-btn" @click="logout">Да, выйти</button>
          <button class="cancel-btn" @click="isLogoutModalOpen = false">Отмена</button>
        </div>
      </div>
    </div>
  </header>
</template>

<script setup>
import { useCartStore } from "@/store/useCartStore";
import { useLikeStore } from "@/store/useLikeStore";
import { computed, watchEffect, ref, onMounted, defineEmits, defineProps } from "vue";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import api from "@/api";
import { useProductStore } from "@/store/useProductStore";

// Добавляем props для получения статуса авторизации
const props = defineProps({
  isAuthenticated: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['logout']);

const route = useRoute();
const router = useRouter();

const cartStore = useCartStore();
const likeStore = useLikeStore();
const productStore = useProductStore();

const { totalLikes } = storeToRefs(likeStore);
const isLogoutModalOpen = ref(false);

// Подсчет количества товаров в корзине
const cartQuantity = computed(() =>
  cartStore.cart.reduce((sum, product) => sum + product.quantity, 0)
);

// Логируем количество товаров в корзине для отладки
watchEffect(() => {
//  console.log("Текущее количество товаров в корзине:", cartQuantity.value);
});

const isHomepage = computed(() => route.fullPath === "/");

// Функция для перехода на указанную страницу
const goToPage = (path) => {
  if (!props.isAuthenticated && path !== '/login') {
    router.push('/login');
    return;
  }
  router.push(path);
};

// Функция для открытия модального окна подтверждения выхода
const confirmLogout = () => {
  isLogoutModalOpen.value = true;
};

// Функция для выхода из аккаунта
const logout = async () => {
  try {
    await api.post("/auth/signout");
    localStorage.removeItem("authToken"); // Удаляем токен
    api.defaults.headers.common["Authorization"] = ""; // Убираем заголовок
    productStore.clearUserData(); // Очищаем данные пользователя
    emit('logout'); // Уведомляем родительский компонент о выходе
    router.push("/"); // Перенаправляем на главную страницу
  } catch (err) {
    //console.error("❌ Ошибка выхода:", err);
    // Даже если запрос не удался, все равно очищаем локальные данные
    localStorage.removeItem("authToken");
    api.defaults.headers.common["Authorization"] = "";
    productStore.clearUserData();
    emit('logout');
    router.push("/");
  } finally {
    isLogoutModalOpen.value = false; // Закрываем модальное окно
  }
};

// Функция для эмита события клика на кнопку авторизации
const emitLoginClick = () => {
  emit('login-click');
};


// Добавляем реактивную переменную для отслеживания типа клиента
const isWholesaleClient = ref(false);

// Функция для проверки типа клиента
const checkClientType = async () => {
  if (!props.isAuthenticated) return;
  
  try {
    const response = await api.get("/api/clients/me");
    isWholesaleClient.value = response.data && response.data.cod_type_client === 'trade';
  } catch (err) {
    //console.error("❌ Ошибка при получении данных клиента:", err);
    isWholesaleClient.value = false; // По умолчанию считаем розничным
  }
};

// Вызываем функцию при монтировании компонента
onMounted(() => {
  checkClientType();
});

// Также добавляем слежение за изменением статуса авторизации
watchEffect(() => {
  if (props.isAuthenticated) {
    checkClientType();
  } else {
    isWholesaleClient.value = false;
  }
});
</script>

<style scoped>
.header {
  display: grid;
  align-items: center;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  margin-bottom: 5px;
  position: relative;
  z-index: 998;
}

.header__logo {
  width: 110px;
  justify-self: start;
  color: lightgray;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.header__logo:hover {
  transform: translateY(-3px) scale(1.02);
  filter: drop-shadow(0 8px 12px rgba(241, 94, 36, 0.2));
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: translateY(-3px) scale(1.02);
  }
  50% {
    transform: translateY(-3px) scale(1.04);
  }
  100% {
    transform: translateY(-3px) scale(1.02);
  }
}

.header__right {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-self: end;
}

.whatsapp-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background: white;
  border-radius: 20px;
  text-decoration: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.whatsapp-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.whatsapp-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-icon img {
  width: 100%;
  height: 100%;
}

.whatsapp-text {
  color: #333;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

/* Адаптив для мобильных устройств */
@media (max-width: 768px) {
  .header__right {
    gap: 10px;
  }

  .whatsapp-text {
    display: none;
  }

  .whatsapp-button {
    padding: 8px;
  }
}

ul {
  display: flex;
  gap: 14px;
  justify-self: end;
  list-style: none;
  padding: 0;
  align-items: center;
}

.header__logo-disabled {
  pointer-events: none;
  opacity: 0.7;
}

.header__logo_active {
  color: #ffa801;
}

.header__logo_active:hover {
  opacity: 0.6;
  transition: opacity 0.3s ease-in;
}

/* Новая кнопка вместо BaseButtonWithBadge */
.header-button {
  position: relative;
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.header-button:hover {
  transform: translateY(-2px);
}

/* Стиль для круглого фона иконок */
.header__icon-container {
  width: 36px;
  height: 36px;
  background-color: #F15E24;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-button:hover .header__icon-container {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

/* Специальный стиль для кнопки выхода */
.logout-container {
  background-color: #e74c3c;
}

.header-button:hover .logout-container {
  background-color: #c0392b;
}

/* Специальный стиль для кнопки входа */
.login-container {
  background-color: #27ae60;
}

.header-button:hover .login-container {
  background-color: #219a52;
}

/* Специальный стиль для иконки профиля оптового клиента */
.wholesale-profile {
  background-color: #3498db;
}

.header-button:hover .wholesale-profile {
  background-color: #2980b9;
}

/* Стиль для иконок */
.header__icon {
  width: 24px;
  height: 24px;
  display: block;
  filter: brightness(0) invert(1);
  transition: all 0.3s ease;
}

.header-button:hover .header__icon {
  transform: scale(1.1);
}

/* Стиль для бейджа (счетчика) */
.header-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 600;
  color: #222222;
  position: absolute;
  top: -7px;
  right: -7px;
  border: 2px solid white;
  background: #ffa801;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-button:hover .header-badge {
  transform: scale(1.1);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
}

/* Модальное окно выхода */
.logout-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.logout-modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 300px;
  text-align: center;
}

.logout-modal-content h3 {
  margin-top: 0;
  font-size: 18px;
}

.logout-modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 10px;
}

.logout-modal-buttons button {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-btn {
  background: #e74c3c;
  color: white;
}

.cancel-btn {
  background: #eee;
  color: #333;
}

/* Скрываем кнопку выхода на мобильных устройствах */
@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }
}
</style>