import { defineStore } from 'pinia';
import api from '@/api';

export const useCartStore = defineStore('cart', {
  state: () => ({
    cart: [],
  }),

  getters: {
    productInCart: (state) => (id) => state.cart.find((productItem) => productItem.id === +id),

    isProductsInCart: (state) => state.cart.length > 0,

    totalProductsAddedToCart: (state) => state.cart.reduce((totalQuantity, product) => totalQuantity + product.quantity, 0),

    totalAmount: (state) => {
      // Получаем информацию о типе клиента
      const userData = JSON.parse(localStorage.getItem('userData') || '{}');
      const isWholesaleClient = userData.cod_type_client === 'trade';
      
      return state.cart.reduce((amount, product) => {
        // Выбираем цену в зависимости от типа клиента
        let price;
        if (isWholesaleClient) {
          // Для оптового клиента
          price = product.sale_trade_price && product.sale_trade_price < product.tradePrice 
            ? product.sale_trade_price 
            : product.tradePrice || 0;
        } else {
          // Для розничного клиента
          price = product.sale_retail_price && product.sale_retail_price < product.retailPrice 
            ? product.sale_retail_price 
            : product.retailPrice || 0;
        }
        
        return amount + (price * product.quantity);
      }, 0).toFixed(2);
    },
  },

  actions: {
    setProductToCart(product) {
      const existingProduct = this.productInCart(product.id);
      if (existingProduct) {
        existingProduct.quantity++;
      } else {
        // Получаем данные о типе клиента
        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        const isWholesaleClient = userData.cod_type_client === 'trade';
        
        // Убедимся, что цены существуют и имеют числовые значения
        const retailPrice = typeof product.retailPrice === 'number' 
          ? product.retailPrice 
          : parseFloat(product.retailPrice) || 0;
          
        const tradePrice = typeof product.tradePrice === 'number'
          ? product.tradePrice
          : parseFloat(product.tradePrice) || 0;
        
        this.cart.push({
          ...product,
          retailPrice: retailPrice,
          tradePrice: tradePrice,
          quantity: product.quantity > 0 ? product.quantity : 1,
        });
        
        // Немедленно обновим информацию о товаре с сервера
        this.fetchProductInfo(product.id);
      }
    },

    setProductsFromLStoCart(products) {
      if (!products) return;
      products.forEach((product) => this.setProductToCart(product));
    },

    incrementQuantity(productId) {
      const product = this.productInCart(productId);
      if (product && product.quantity < product.remainedCount) {
        product.quantity++;
      }
    },

    decrementQuantity(productId) {
      const product = this.productInCart(productId);
      if (product && product.quantity > 1) {
        product.quantity--;
      }
    },

    removeProductFromCart(productId) {
      this.cart = this.cart.filter((product) => product.id !== productId);
    },

    clearCart() {
      this.$reset();
    },

    // Обновленный метод для получения информации о товаре
    async fetchProductInfo(productId) {
      try {
        const response = await api.get(`/api/client/products/${productId}`);
        const productData = response.data;
        
        // Найдем товар в корзине и обновим его данные
        const existingProduct = this.productInCart(productId);
        if (existingProduct) {
          // Обновляем все важные поля, включая оптовые цены
          existingProduct.retailPrice = productData.retailPrice;
          existingProduct.sale_retail_price = productData.sale_retail_price;
          existingProduct.tradePrice = productData.tradePrice;
          existingProduct.sale_trade_price = productData.sale_trade_price;
          existingProduct.remainedCount = productData.remainedCount;
          existingProduct.reservedCount = productData.reservedCount;
          
          // Обновляем изображение, если оно не было определено или было некорректным
          if (!existingProduct.image || existingProduct.image === "/assets/no-image.png") {
            existingProduct.image = productData.imageSet?.length 
              ? productData.imageSet.find(img => img.isMain)?.fileInfo.link || productData.imageSet[0].fileInfo.link 
              : "/assets/no-image.png";
          }
          
          // Обновляем название товара, если оно не было определено
          if (!existingProduct.title) {
            existingProduct.title = productData.name;
          }
        }
      } catch (error) {
        console.error(`Ошибка загрузки информации о товаре ID ${productId}:`, error);
      }
    },

    // Обновление данных товаров в корзине (цены, остаток)
    async fetchCartProducts() {
      try {
        if (this.cart.length === 0) return;

        const updatedProducts = await Promise.all(
          this.cart
            .filter((item) => item.id) // Фильтруем товары без ID
            .map(async (cartItem) => {
              try {
                const response = await api.get(`/api/client/products/${cartItem.id}`);
                const updatedData = response.data;

                // Сохраняем текущее количество товара
                const currentQuantity = cartItem.quantity || 1;
                
                return {
                  ...cartItem,
                  id: updatedData.id,
                  title: updatedData.name || cartItem.title,
                  retailPrice: updatedData.retailPrice,
                  sale_retail_price: updatedData.sale_retail_price,
                  tradePrice: updatedData.tradePrice,
                  sale_trade_price: updatedData.sale_trade_price,
                  remainedCount: updatedData.remainedCount,
                  reservedCount: updatedData.reservedCount,
                  quantity: currentQuantity,
                  image: updatedData.imageSet?.length 
                    ? updatedData.imageSet.find(img => img.isMain)?.fileInfo.link || updatedData.imageSet[0].fileInfo.link 
                    : "/assets/no-image.png", 
                };
              } catch (error) {
                console.error(`Ошибка загрузки товара ID ${cartItem.id}:`, error);
                // Если API не вернуло данные, сохраняем существующую информацию
                return { 
                  ...cartItem,
                  // Убедимся, что цены имеют значения
                  retailPrice: typeof cartItem.retailPrice === 'number' ? cartItem.retailPrice : parseFloat(cartItem.retailPrice) || 0,
                  tradePrice: typeof cartItem.tradePrice === 'number' ? cartItem.tradePrice : parseFloat(cartItem.tradePrice) || 0,
                  quantity: cartItem.quantity || 1
                };
              }
            })
        );

        // Заменяем корзину обновленными товарами
        this.cart = updatedProducts;
        console.log('Обновленная корзина:', this.cart);
      } catch (error) {
        console.error('Ошибка обновления товаров в корзине:', error);
      }
    },
  },
});