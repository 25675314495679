<template>
    <div class="login-container">
      <img :src="Logo" alt="ZerdeToys" class="login-logo" />
  
      <h2 class="login-title">Вход</h2>
  
      <form @submit.prevent="handleLogin" class="login-form">
        <!-- Телефон -->
        <div class="input-group">
          <label for="phone">Телефон</label>
          <vue-tel-input
            v-model="phone"
            v-bind="phoneOptions"
            @input="validatePhone"
            class="phone-input"
          />
          <p v-if="phoneError" class="error-message">{{ phoneError }}</p>
        </div>
  
        <!-- Пароль -->
        <div class="input-group password-group">
          <label for="password">Пароль</label>
          <input
            id="password"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            placeholder="Введите пароль"
            required
          />
          <span @click="togglePasswordVisibility" class="eye-icon">
            <EyeOpenIcon v-if="showPassword" />
            <EyeClosedIcon v-else />
          </span>
        </div>
  
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  
        <button type="submit" class="login-button" :disabled="isLoading">
          {{ isLoading ? "Загрузка..." : "Войти" }}
        </button>
  
        <div class="login-links">
          <router-link to="/forgot-password">Забыл пароль?</router-link>
          <router-link to="/register">Регистрация</router-link>
        </div>
      </form>
    </div>
</template>
  
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import api, { saveToken } from "@/api";
import Logo from "@/assets/icon.png";
import { VueTelInput } from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";
import EyeOpenIcon from "@/components/icons/EyeOpenIcon.vue";
import EyeClosedIcon from "@/components/icons/EyeClosedIcon.vue";

const phone = ref("");
const phoneError = ref(null);
const password = ref("");
const isLoading = ref(false);
const errorMessage = ref(null);
const showPassword = ref(false);
const router = useRouter();

const phoneOptions = {
  mode: "international", // Всегда + код страны
  autoFormat: true,
  placeholder: "Введите номер телефона",
  preferredCountries: ["ru", "kz", "kg"], // Россия, Казахстан, Кыргызстан
  onlyCountries: ["ru", "kz", "kg"],
  validCharactersOnly: true,
  inputOptions: {
    showDialCode: true, // Показывать код страны
  },
};

// ✅ Проверка номера
const validatePhone = (phoneNumber) => {
  if (!phoneNumber) {
    phoneError.value = "❌ Некорректный номер";
    return;
  }

  // ✅ Приводим к строке, если передано число
  const phoneString = String(phoneNumber);

  // ✅ Очищаем номер от пробелов и скобок
  const cleanPhone = phoneString.replace(/\D/g, ""); // Убираем всё, кроме цифр

  //console.log("Проверяемый номер:", cleanPhone);

  // ✅ Проверяем код страны и количество цифр
  if (cleanPhone.startsWith("7") && cleanPhone.length !== 11) {
    phoneError.value = "❌ Некорректный номер. Казахстан и Россия: 11 цифр";
  } else if (cleanPhone.startsWith("996") && cleanPhone.length !== 12) {
    phoneError.value = "❌ Некорректный номер. Кыргызстан: 12 цифр";
  } else {
    phoneError.value = null; // ✅ Всё в порядке
  }
};


// ✅ Функция входа
const handleLogin = async () => {
  isLoading.value = true;
  errorMessage.value = null;

  try {
    let formattedPhone = phone.value?.toString().replace(/\D/g, ""); // Убираем лишние символы

    if (formattedPhone.startsWith("8") && formattedPhone.length === 11) {
      formattedPhone = "7" + formattedPhone.slice(1); // 8 → 7
    }

    const response = await api.post("/auth/signin", {
      username: formattedPhone,
      password: password.value,
    });

    const token = response.data?.token;
    if (token) {
      saveToken(token);
      router.push("/");
    } else {
      throw new Error("Ошибка получения токена");
    }
  } catch (error) {
    //console.error("Ошибка входа:", error);
    errorMessage.value =
      error.response?.data?.message || "❌ Неверный номер или пароль!";
  } finally {
    isLoading.value = false;
  }
};

// ✅ Переключение видимости пароля
const togglePasswordVisibility = () => {
  showPassword.value = !showPassword.value;
};
</script>
  

<style scoped>

/* 🔹 Инпут с маской */
.phone-input {
  width: 100%;
}

/* 🔹 Ошибка */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

/* 🔹 Основной контейнер */
.login-container {
  max-width: 400px;
  width: 92%;
  margin: 40px auto;
  padding: 32px;
  background: #ffffff;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  box-sizing: border-box;
}

/* 🔹 Логотип */
.login-logo {
  width: 160px;
  margin-bottom: 20px;
}

/* 🔹 Заголовок */
.login-title {
  font-size: 28px;
  color: #222222;
  font-weight: 600;
  margin-bottom: 30px;
}

/* 🔹 Форма */
.login-form {
  width: 100%;
  box-sizing: border-box;
}

/* 🔹 Группы ввода */
.input-group {
  text-align: left;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.input-group label {
  display: block;
  font-size: 14px;
  color: #333333;
  font-weight: 500;
  margin-bottom: 8px;
}

.input-group input {
  width: 100%;
  height: 48px;
  padding: 0 15px;
  font-size: 15px;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
  background: #F8F8F8;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

/* 🔹 Телефонный инпут */
:deep(.vue-tel-input) {
  width: 100% !important;
  height: 48px !important;
  border-radius: 8px !important;
  border: 1px solid #E0E0E0 !important;
  background: #F8F8F8 !important;
  box-sizing: border-box !important;
}

:deep(.vti__dropdown) {
  height: 46px !important;
  margin: 0 !important;
  padding: 0 0 0 8px !important;
  border-radius: 8px 0 0 8px !important;
  border-right: 1px solid #E0E0E0 !important;
  background: transparent !important;
}

:deep(.vti__input) {
  height: 46px !important;
  margin: 0 !important;
  padding: 0 15px !important;
  background: transparent !important;
  border-radius: 0 8px 8px 0 !important;
  font-size: 15px !important;
  color: #333333 !important;
  box-sizing: border-box !important;
}

/* 🔹 Глазок для пароля */
.password-group input {
  padding-right: 45px;
}

.eye-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 12px;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
}

.eye-icon:hover {
  opacity: 1;
}

.eye-icon svg {
  width: 20px;
  height: 20px;
}

/* 🔹 Ошибка */
.error-message {
  color: #e74c3c;
  font-size: 13px;
  margin-top: 6px;
  font-weight: 500;
}

/* 🔹 Кнопка входа */
.login-button {
  width: 100%;
  height: 48px;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  background: #F15E24;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.login-button:hover {
  background: #e04e16;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(241, 94, 36, 0.2);
}

.login-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* 🔹 Ссылки */
.login-links {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
}

.login-links a {
  font-size: 14px;
  color: #F15E24;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.login-links a:hover {
  color: #e04e16;
  text-decoration: none;
  transform: translateY(-1px);
}

/* 🔹 Адаптив */
@media (max-width: 480px) {
  .login-container {
    margin: 20px 16px;
    padding: 24px 16px;
    border-radius: 8px;
  }

  .login-title {
    font-size: 24px;
    margin-bottom: 25px;
  }

  .login-button {
    padding: 12px;
  }
}
</style>
