<template>
  <div class="header-wrapper" v-if="!isMobile">
    <TheHeader 
      :is-authenticated="isAuthenticated" 
      @logout="handleLogout"
      class="fixed-header"
    />
  </div>
  <div class="main-content" :class="{'with-fixed-header': !isMobile}">
    <TheMain />
  </div>
  <BottomNav v-if="isMobile" />
  
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import TheHeader from "./components/TheHeader.vue";
import TheMain from "./components/TheMain.vue";
import BottomNav from "./components/BottomNav.vue";
import api from "@/api";
import { useProductStore } from "@/store/useProductStore";

const router = useRouter();
const isMobile = ref(false);
const isAuthenticated = ref(false);
const productStore = useProductStore();

// Проверка авторизации
const checkAuth = async () => {
  const token = localStorage.getItem("authToken");
  if (token) {
    try {
      // Устанавливаем токен в заголовки
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      // Проверяем валидность токена
      await api.get("/api/clients/me");
      isAuthenticated.value = true;
    } catch (error) {
      // Если токен невалидный, очищаем его
      localStorage.removeItem("authToken");
      api.defaults.headers.common["Authorization"] = "";
      productStore.clearUserData();
      isAuthenticated.value = false;
    }
  } else {
    isAuthenticated.value = false;
  }
};

// Наблюдаем за изменениями в localStorage
const watchAuthToken = () => {
  window.addEventListener('storage', (e) => {
    if (e.key === 'authToken') {
      checkAuth();
    }
  });
};

// Обработчик успешной авторизации
const handleLoginSuccess = () => {
  checkAuth();
};

// Обработчик выхода из системы
const handleLogout = async () => {
  try {
    await api.post("/auth/signout");
  } catch (error) {
    console.error("Ошибка при выходе:", error);
  } finally {
    localStorage.removeItem("authToken");
    api.defaults.headers.common["Authorization"] = "";
    productStore.clearUserData();
    isAuthenticated.value = false;
    router.push("/");
  }
};

// Следим за изменениями маршрута
watch(() => router.currentRoute.value.path, () => {
  checkAuth();
});

onMounted(() => {
  checkAuth();
  watchAuthToken();
  
  isMobile.value = window.innerWidth <= 768;
  window.addEventListener("resize", () => {
    isMobile.value = window.innerWidth <= 768;
  });
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&family=Open+Sans:wght@400;600&display=swap");
* {
  margin: 0;
}
body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222222;
}
#app {
  min-width: 320px;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 10px;
  box-sizing: border-box;
}

/* Стили для фиксированного хедера */
.header-wrapper {
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(255, 255, 255, 0.1);
  width: 100%;
  max-width: 1480px;
  margin: 0 auto;
}

.fixed-header {
  width: 100%;
  height: 25px;
}

/* Отступ для основного контента, если хедер фиксированный */
.main-content.with-fixed-header {
  padding-top: 5px;
}

@media screen and (min-width: 768px) {
  #app {
      padding: 0 20px;
  }
}
@media screen and (min-width: 1280px) {
  #app {
      padding: 0;
  }
}

html, body {
height: 100%;
margin: 0;
padding: 0;
overflow-x: hidden;
}

.home-page,
.catalog {
flex: 1; /* Занимает всю доступную высоту */
display: flex;
flex-direction: column;
}

.product-list {
flex-grow: 1;
min-height: 100%; /* Минимальная высота - вся страница */
}

.bottom-nav {
position: fixed;
bottom: 0;
left: 0;
right: 0;
background-color: white;
border-top: 1px solid #ddd;
padding: 10px 0;
z-index: 1000;
}

</style>